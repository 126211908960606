@import "react-phone-input-2/lib/style.css";
.overflow-x-clip {
  overflow-x: clip;
}

.width-full {
  width: 100%;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

/* FlexBox */
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

/* Justify Content */
.justify-normal {
  justify-content: normal;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

/* Align Items */
.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

/* Grid */
.grid {
  display: grid;
}

/* Background Colors */
.bg-primary {
  background: #0034bb;
}

.bg-secondary {
  background: #000000;
}

.bg-white {
  background: #ffffff;
}

/* Text Colors */
.text-primary {
  color: #212121;
}

.text-white {
  color: #ffffff;
}

/* Font Weight */
.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.layout {
  width: 100%;
}
.layout .content {
  display: flex;
  height: 100vh;
  position: relative;
}
.layout .content .side-bar {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  background: #0034bb;
  min-width: 81px;
  position: fixed;
  top: 0;
  bottom: 0;
  padding-bottom: 20px;
}
.layout .content .side-bar ul {
  margin-top: 25px;
}
.layout .content .side-bar ul .img {
  width: 27px;
  height: 27px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  list-style: none;
}
.layout .content .side-bar ul .img img {
  max-width: 100%;
  height: 100%;
}
.layout .content .side-bar ul .li {
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  padding-top: 10px;
  list-style: none;
  opacity: 0.7;
  cursor: pointer;
  border-radius: 6px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
}
.layout .content .side-bar ul .li a {
  color: #eeeeee;
  text-decoration: none;
  padding: 5px;
}
.layout .content .side-bar ul .li:hover {
  background: #a503f1;
  border-radius: 6px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  padding-top: 10px;
  padding-top: 10px;
}
.layout .content .side-bar ul .active {
  background: #a503f1;
  border-radius: 6px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  padding-top: 10px;
  opacity: 1;
}
.layout .content .side-bar ul .btn-outlined {
  max-width: 10px;
}
.layout .content .page {
  width: 100%;
  background: #0034bb;
  margin-left: 81px;
}
.layout .content .page .page-content {
  margin-top: 18px;
  background: #ffffff;
  height: 98vh;
  overflow: scroll;
  border-top-left-radius: 30px;
  padding: 30px;
  padding-top: 28px;
}

.loader {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiMenu-root .MuiPaper-root {
  box-shadow: 0px 12px 22px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.MuiMenu-paper {
  width: 139px;
}

.layout-two {
  height: 100vh;
}
.layout-two .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding: 5px 40px;
  height: 54px;
  padding-top: 8px;
  padding-left: 42px;
}
.layout-two .header .left img {
  max-width: 105px;
}
.layout-two .header .center {
  width: 600px;
}
.layout-two .header .center .search-input .MuiInputBase-root:focus .MuiOutlinedInput-notchedOutline, .layout-two .header .center .search-input .MuiInputBase-root:focus-within .MuiOutlinedInput-notchedOutline {
  border-color: #0034bb;
}
.layout-two .header .center .search-input .MuiInputBase-root:hover {
  border-radius: 9px;
}
.layout-two .header .center .search-input .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #0034bb;
}
.layout-two .header .center .search-input .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 10px 9px 0;
  max-height: 38px;
  font-size: 14px;
  color: #212121;
}
.layout-two .header .center .search-input .MuiInputBase-root .MuiInputBase-input::placeholder {
  color: #212121;
  font-weight: 400;
  opacity: 0.5;
}
.layout-two .header .center .search-input .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-radius: 9px;
  border: 1.5px solid rgba(33, 33, 33, 0.1);
}
.layout-two .header .center .search-input .MuiInputBase-root .MuiOutlinedInput-notchedOutline:hover {
  border: 1px solid #0034bb;
}
.layout-two .header .right ul {
  display: flex;
  align-items: center;
  gap: 26px;
  list-style: none;
}
.layout-two .header .right ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: rgb(33, 33, 33);
}
.layout-two .header .right ul li .icon {
  width: 22px;
  height: 22px;
  color: rgba(33, 33, 33, 0.8);
}
.layout-two .header .right ul li .icon:hover {
  color: #0034bb;
}
.layout-two .header .right ul li .selected {
  color: #0034bb;
}
.layout-two .header .right ul li .avatar {
  width: 25px;
  height: 25px;
  font-size: 15px;
}
.layout-two .page {
  display: flex;
  align-items: center;
  background: #fdfdfd;
  gap: 30px;
  background: #fdfdfd;
}
.layout-two .page .right {
  height: calc(100vh - 54px);
  width: 100%;
  padding-right: 40px;
  overflow: auto;
}

.new-side-bar {
  position: relative;
  width: 281px;
  padding-right: 1px;
  background: #ffffff;
  border-right: none;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 33%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1.4px 10px;
  background-repeat: repeat-y;
  transition: width 0.3s ease;
  /* Add transition property for smooth width changes */
}
.new-side-bar .switch {
  position: absolute;
  z-index: 1;
  right: -16px;
  cursor: pointer;
  top: 10px;
}
.new-side-bar .switch .switch-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 24%;
  left: 24%;
}
.new-side-bar .expended-view {
  height: calc(100vh - 54px);
  overflow-y: scroll;
}
.new-side-bar .expended-view .item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 20px;
  width: 280px;
  overflow-x: scroll;
}
.new-side-bar .expended-view .item_::-webkit-scrollbar {
  width: none;
  width: 0px;
}
.new-side-bar .expended-view .item .top-head {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 40px;
  height: 55px;
  width: 280px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.new-side-bar .expended-view .item .top-head .icons {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
}
.new-side-bar .expended-view .item .top-head a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.new-side-bar .expended-view .item .top-head:hover {
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.new-side-bar .expended-view .item .top-head:hover .icons {
  color: #0034bb;
}
.new-side-bar .expended-view .item .top-head:hover a {
  color: #0034bb;
}
.new-side-bar .expended-view .item .active {
  border-right: 4px solid #0034bb;
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.new-side-bar .expended-view .item .active .icons {
  color: #0034bb;
}
.new-side-bar .expended-view .item .active a {
  color: #0034bb;
}
.new-side-bar .expended-view .item .menu {
  box-shadow: none !important;
  border: none !important;
  margin-bottom: 0px;
  min-width: 280px;
}
.new-side-bar .expended-view .item .menu ::before {
  height: 0px !important;
}
.new-side-bar .expended-view .item .menu .list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px;
}
.new-side-bar .expended-view .item .menu .list .a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  padding: 8px 40px;
  padding-left: 77px;
  cursor: pointer;
}
.new-side-bar .expended-view .item .menu .list .a:hover {
  padding: 8px 38px;
  padding-left: 77px;
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.new-side-bar .expended-view .item .menu .list .active1 {
  border-right: 4px solid #0034bb;
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root {
  padding-left: 40px;
  padding-right: 10px;
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root .MuiAccordionSummary-content .title-head {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 180px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root .MuiAccordionSummary-content .title-head .icons {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root:hover {
  background: rgba(0, 52, 187, 0.1);
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root:hover .MuiAccordionSummary-content .title-head {
  color: #0034bb;
}
.new-side-bar .expended-view .item .menu .MuiButtonBase-root:hover .MuiAccordionSummary-content .title-head .icons {
  color: #0034bb;
}
.new-side-bar .expended-view .item .menu .Mui-expanded {
  color: #0034bb;
  align-items: center;
  gap: 20px;
}
.new-side-bar .expended-view .item .menu .Mui-expanded .MuiAccordionSummary-content .title-head {
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: #0034bb;
  cursor: pointer;
}
.new-side-bar .expended-view .item .menu .Mui-expanded .MuiAccordionSummary-content .title-head .icons {
  width: 24px;
  height: 24px;
  color: #0034bb;
}
.new-side-bar .expended-view .item .menu:before {
  height: 0px !important;
}
.new-side-bar .expended-view::-webkit-scrollbar {
  width: 0px;
}
.new-side-bar.collapse {
  width: 60px;
  padding: 0px;
}
.new-side-bar.collapse .collapse-view {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 23px;
  padding-top: 27px;
  height: calc(100vh - 54px);
  overflow-y: scroll;
}
.new-side-bar.collapse .collapse-view .active {
  border-right: 4px solid #0034bb !important;
  background: rgba(0, 52, 187, 0.1) !important;
}
.new-side-bar.collapse .collapse-view .active .icons {
  margin-left: 4px;
  color: #0034bb !important;
}
.new-side-bar.collapse .collapse-view p {
  width: 100%;
  padding: 8px;
  padding-bottom: 6px;
  text-align: center;
}
.new-side-bar.collapse .collapse-view p .icons {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.new-side-bar.collapse .collapse-view p:hover {
  width: 100%;
  background: rgba(0, 52, 187, 0.1);
}
.new-side-bar.collapse .collapse-view p:hover .icons {
  color: #0034bb !important;
}
.new-side-bar.collapse .collapse-view .collapse-list .button-icon {
  border-radius: 0px;
  margin: 0px;
  width: 100%;
  height: 44px;
}
.new-side-bar.collapse .collapse-view .collapse-list .button-icon .icons {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
}
.new-side-bar.collapse .collapse-view .collapse-list .button-icon:hover {
  border-right: 4px solid #0034bb;
  background: rgba(0, 52, 187, 0.1);
}
.new-side-bar.collapse .collapse-view .collapse-list .button-icon:hover .icons {
  margin-left: 4px;
}

.collapse-list-menu {
  margin-top: -30px;
}
.collapse-list-menu .MuiPaper-root {
  min-width: 180px;
}
.collapse-list-menu .MuiPaper-root .MuiList-root {
  margin: 2px 10px;
}
.collapse-list-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root {
  padding: 10px 15px;
  font-size: 16px !important;
  font-weight: 500;
  color: #212121;
  background-color: #ffffff;
  margin-bottom: 5px;
}
.collapse-list-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover {
  border-radius: 10px;
  background: rgba(0, 52, 187, 0.05);
}
.collapse-list-menu .MuiPaper-root .MuiList-root .active {
  border-radius: 10px;
  background: rgba(0, 52, 187, 0.05);
}

.snackbar-cross {
  color: rgba(33, 33, 33, 0.8) !important;
  margin-right: -10px !important;
  margin-top: -49px;
  width: 19px;
  height: 19px;
  cursor: pointer;
}
.snackbar-cross:hover {
  background-color: rgba(33, 33, 33, 0.19);
  border-radius: 50px;
}

.search-popover {
  margin-left: -42px;
  margin-top: 7px;
}
.search-popover .MuiPaper-root {
  padding: 13px 18px 26px 18px;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  box-shadow: 4px 4px 4px 3px rgba(0, 0, 0, 0.02);
  max-height: 453px;
  overflow-y: scroll;
}
.search-popover .MuiPaper-root .default {
  display: flex;
  align-items: center;
  column-gap: 7px;
  margin-left: 12px;
  margin-top: 7px;
}
.search-popover .MuiPaper-root .default .text .query {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.8);
  line-height: 1;
  margin-bottom: 0;
}
.search-popover .MuiPaper-root .default .text .sub-text {
  font-size: 10px;
  color: rgba(33, 33, 33, 0.7);
}
.search-popover .MuiPaper-root .search-group {
  margin-top: 15px;
  margin-bottom: 0;
}
.search-popover .MuiPaper-root .search-group .group-heading {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  line-height: 1.5;
  margin-bottom: 10px;
  text-transform: capitalize;
  margin-left: 12px;
}
.search-popover .MuiPaper-root .search-group .search-results .result {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 2px;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
}
.search-popover .MuiPaper-root .search-group .search-results .result:last-child {
  margin-bottom: 0;
}
.search-popover .MuiPaper-root .search-group .search-results .result:hover {
  background: rgba(0, 52, 187, 0.03);
}
.search-popover .MuiPaper-root .search-group .search-results .result .avatar {
  height: 29px;
  width: 29px;
  font-size: 12px;
  font-weight: 500;
}
.search-popover .MuiPaper-root .search-group .search-results .result .right .heading {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.8);
  line-height: 1;
}
.search-popover .MuiPaper-root .search-group .search-results .result .right .sub {
  font-size: 12px;
  color: rgba(33, 33, 33, 0.7);
}
.search-popover .MuiPaper-root .search-group:first-child {
  margin-top: 0;
}
.search-popover .MuiPaper-root .fallback-wrapper {
  text-align: center;
}
.search-popover .MuiPaper-root .fallback-wrapper img {
  max-width: 299px;
  height: auto;
}
.search-popover .MuiPaper-root .fallback-wrapper p {
  font-weight: 500;
  color: #212121;
}

.notification-menu .MuiPaper-root {
  padding: 18px 22px 23px 22px !important;
  border-radius: 17px !important;
  max-width: 591px;
  min-width: 360px;
  width: max-content;
  box-shadow: 0px 7px 30px 7px rgba(33, 33, 33, 0.03) !important;
  height: max-content;
}
.notification-menu .MuiPaper-root .MuiList-root {
  padding: 0 !important;
}
.notification-menu .MuiPaper-root .heading {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 9px;
}
.notification-menu .MuiPaper-root .heading h4 {
  color: #212121;
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
}
.notification-menu .MuiPaper-root .heading p {
  font-size: 10px;
  font-weight: 800;
  line-height: 16px;
  background: #0034bb;
  padding: 1px 5px;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  color: #fff;
}
.notification-menu .MuiPaper-root .container {
  overflow: visible;
}
.notification-menu .MuiPaper-root .container .notification-wrapper {
  margin-bottom: 6px;
  padding: 12px 18px;
  margin-left: -5px;
  position: relative;
}
.notification-menu .MuiPaper-root .container .notification-wrapper:hover {
  border-radius: 10px;
  background: rgba(0, 52, 187, 0.03);
}
.notification-menu .MuiPaper-root .container .notification-wrapper .message {
  color: #212121;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
}
.notification-menu .MuiPaper-root .container .notification-wrapper .time {
  color: #212121;
  font-size: 12px;
  line-height: 18px;
}
.notification-menu .MuiPaper-root .container .notification-wrapper::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background: transparent;
  border-radius: 8px;
  left: 0;
}
.notification-menu .MuiPaper-root .container .unread {
  margin-left: 0;
  padding-left: 28px;
}
.notification-menu .MuiPaper-root .container .unread .message {
  font-weight: 500;
}
.notification-menu .MuiPaper-root .container .unread::before {
  content: "";
  position: absolute;
  width: 3px;
  height: calc(100% - 24px);
  background: #0034bb;
  border-radius: 8px;
  left: 12px;
  top: 12px;
  bottom: 12px;
}
.notification-menu .MuiPaper-root .container .fallback {
  text-align: center;
}
.notification-menu .MuiPaper-root .container .fallback img {
  max-height: 250px;
}
.notification-menu .MuiPaper-root .container .fallback p {
  color: #212121;
  font-weight: 500;
}
.notification-menu .MuiPaper-root .notification-cta {
  text-decoration: underline;
  font-weight: 400 !important;
  color: #212121;
  font-size: 13px !important;
  margin-bottom: 0;
}

.settings-drawer .MuiPaper-root {
  border-radius: 0;
  padding: 25px 27px 40px 40px;
  min-width: 460px;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: -7px 7px 40px 0px rgba(33, 33, 33, 0.2);
  border: none;
}
.settings-drawer .MuiPaper-root .wrapper .icon-container {
  text-align: right;
}
.settings-drawer .MuiPaper-root .wrapper h3 {
  margin-top: 10px;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
  color: #212121;
}
.settings-drawer .MuiPaper-root .wrapper .field {
  margin-bottom: 30px;
}
.settings-drawer .MuiPaper-root .wrapper .field p {
  font-weight: 500;
  margin-bottom: -3px;
}
.settings-drawer .MuiPaper-root .wrapper .field .select {
  margin-top: 4px;
  outline: none;
}
.settings-drawer .MuiPaper-root .wrapper .field .select .MuiFormControl-root .MuiInputBase-root {
  border-radius: 8px;
  padding: 10px 11px 10px 7px;
  outline: none;
}
.settings-drawer .MuiPaper-root .wrapper .field .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 0;
}
.settings-drawer .MuiPaper-root .wrapper .field .select .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
}
.settings-drawer .MuiPaper-root .wrapper .field a {
  color: #0034bb;
  font-size: 12px;
  font-weight: 400;
  margin-top: -30px;
}
.settings-drawer .MuiPaper-root .wrapper .field .sub {
  font-size: 12px;
  font-weight: 400;
  margin-top: 3px;
}
.settings-drawer .MuiPaper-root .wrapper .field .sub span {
  color: #0034bb;
  text-decoration: underline;
  cursor: pointer;
}

.MuiAutocomplete-popper .MuiPaper-root {
  margin-top: 9px;
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  box-shadow: -7px 7px 40px 0px rgba(33, 33, 33, 0.1);
}
.MuiAutocomplete-popper .MuiPaper-root .MuiAutocomplete-option {
  margin-bottom: 12px;
}

.product-menu .MuiPaper-root {
  padding: 24px 20px !important;
  border-radius: 12px !important;
  min-width: max-content;
  box-shadow: 0px 7px 30px 7px rgba(33, 33, 33, 0.03) !important;
}
.product-menu .MuiPaper-root .MuiList-root {
  padding: 0 !important;
}
.product-menu .MuiPaper-root h5 {
  font-size: 20px;
  color: #212121;
  font-weight: 600;
  line-height: 1;
}
.product-menu .MuiPaper-root .products-wrapper {
  margin-top: 12px;
}
.product-menu .MuiPaper-root .products-wrapper .product {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 400ms ease-in;
}
.product-menu .MuiPaper-root .products-wrapper .product:hover .icon-wrapper .icon {
  transform: scale(1.1);
}
.product-menu .MuiPaper-root .products-wrapper .product:last-child {
  margin-bottom: 0;
}
.product-menu .MuiPaper-root .products-wrapper .product .icon-wrapper {
  border-radius: 50%;
  background: rgba(33, 33, 33, 0.06);
  padding: 12px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.product-menu .MuiPaper-root .products-wrapper .product .selected {
  color: #0034bb;
  background: rgba(0, 52, 187, 0.11);
  cursor: default;
}
.product-menu .MuiPaper-root .products-wrapper .product .text-wrapper .name {
  font-size: 16px;
  color: #212121;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1.5;
}
.product-menu .MuiPaper-root .products-wrapper .product .text-wrapper .selected-name {
  font-weight: 600;
}
.product-menu .MuiPaper-root .products-wrapper .product .text-wrapper .desc {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.8);
  line-height: 20px;
}

.signout-menu .MuiPaper-root {
  padding: 0 !important;
  border-radius: 6px !important;
  min-width: max-content;
  box-shadow: 0px 7px 30px 7px rgba(33, 33, 33, 0.03) !important;
}
.signout-menu .MuiPaper-root .MuiList-padding {
  padding: 0 !important;
}
.signout-menu .MuiPaper-root .user-menu .user {
  display: flex;
  align-items: center;
  column-gap: 11px;
  padding: 12px 16px;
  margin-left: 0;
}
.signout-menu .MuiPaper-root .user-menu .user .right {
  text-align: left;
}
.signout-menu .MuiPaper-root .user-menu .user .right .name {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  line-height: 20px;
}
.signout-menu .MuiPaper-root .user-menu .user .right .email {
  font-size: 14px;
  color: #212121;
  line-height: 20px;
}
.signout-menu .MuiPaper-root .user-menu .divider {
  height: 2px;
  background: rgba(33, 33, 33, 0.1);
  cursor: default;
}
.signout-menu .MuiPaper-root .user-menu .btn-wrapper {
  text-align: left;
}
.signout-menu .MuiPaper-root .user-menu .btn-wrapper:hover {
  border-radius: 6px;
  background: rgba(0, 52, 187, 0.03);
}
.signout-menu .MuiPaper-root .user-menu .btn-wrapper .signout-btn {
  margin: 10px 16px !important;
  color: #212121;
  font-size: 14px;
  line-height: 20px;
  padding: 0 !important;
}
.signout-menu .MuiPaper-root .user-menu .btn-wrapper .signout-btn:hover {
  background: transparent !important;
}

.unauthorized {
  text-align: center;
}
.unauthorized .bg_circle-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  position: absolute;
  z-index: -1;
  opacity: 0.2;
  -moz-opacity: 0.07;
  right: 7.75rem;
  left: 7.75rem;
}
.unauthorized .bg_circle-wrapper > .circle {
  width: 320px;
  height: 325px;
  border-radius: 325px;
  filter: blur(200px);
  flex: 1 1 30%;
  overflow: visible;
}
.unauthorized .bg_circle-wrapper .circle-one {
  background: #0034bb;
}
.unauthorized .bg_circle-wrapper .circle-two {
  background: #0034bb;
}
.unauthorized .bg_circle-wrapper .circle-three {
  background: #027A48;
}
.unauthorized .bg_circle-wrapper .circle-four {
  background: #2bba1d;
}
.unauthorized .bg_circle-wrapper .circle-five {
  background: #ba0000;
}
.unauthorized .bg_circle-wrapper .circle-six {
  background: #fe9800;
}
.unauthorized .content {
  margin-top: 70px;
}
.unauthorized .content .image {
  width: 430px;
  margin: auto;
}
.unauthorized .content .image img {
  max-width: 100%;
}
.unauthorized .content h1 {
  font-size: 38px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  margin-top: 10px;
}
.unauthorized .content h2 {
  margin-top: 16px;
  font-size: 21px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.unauthorized .content h2 span {
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  padding: 4px 10px;
  border-radius: 8px;
  margin-right: -5px;
}
.unauthorized .content h2 span:hover {
  background: rgba(0, 52, 187, 0.1);
  padding: 4px 10px;
  border-radius: 8px;
}
.unauthorized footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 40px;
  border-top: 1px solid rgba(33, 33, 33, 0.1);
}
.unauthorized footer .footer-btn {
  border-bottom: 1px solid !important;
}

.layout-signup {
  max-width: 1440px;
  margin: auto;
}
.layout-signup .content .page {
  position: relative;
  position: relative;
  overflow: hidden;
  border-radius: 13.347px;
  margin: 20px 100px;
  min-height: 670px;
  background: #fff;
  box-shadow: 0px 1.112px 2.224px 0px rgba(28, 28, 28, 0.24), 0px 0px 0px 1.112px rgba(28, 28, 28, 0.08);
  z-index: 0;
}
.layout-signup .content .page .bg_circle-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  position: absolute;
  z-index: -1;
  opacity: 0.2;
  -moz-opacity: 0.07;
}
.layout-signup .content .page .bg_circle-wrapper > .circle {
  border-radius: 325px;
  flex: 1 1 30%;
  overflow: visible;
}
.layout-signup .content .page .bg_circle-wrapper .circle-one {
  filter: blur(50px);
  position: absolute;
  width: 550px;
  height: 700px;
  left: 0rem;
  top: 10px;
  border-radius: 100%;
  background: #bde6fc;
}
.layout-signup .content .page .bg_circle-wrapper .circle-two {
  filter: blur(120px);
  position: absolute;
  right: 0.75rem;
  left: 62.75rem;
  top: 30rem;
  width: 200px;
  height: 325px;
  background: #7f00bb;
}
.layout-signup .content .page .bg_circle-wrapper .circle-three {
  filter: blur(180px);
  position: absolute;
  top: 30rem;
  left: 0rem;
  width: 220px;
  height: 225px;
  background: #7f00bb;
}
.layout-signup .content .page .page-content {
  padding: 30px 40px;
}
.layout-signup .content .page .page-content .signup-Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.layout-signup .content .page .page-content .signup-Header .logo {
  max-width: 120px;
  cursor: pointer;
}
.layout-signup .content .page .page-content .signup-Header .buttons {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #1c1c1c;
  border-radius: 8px;
}
.layout-signup .content .page .page-content footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 20px;
  border-top: 1px solid rgba(33, 33, 33, 0.1);
  background: #f9fafc;
}
.layout-signup .content .page .page-content footer .footer-btn {
  border-radius: 0 !important;
  padding: 0px 4px !important;
  text-decoration: underline;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .layout-signup {
    max-width: 100%;
    margin: auto;
  }
  .layout-signup .content .page {
    margin: 16px;
    height: 95vh;
  }
  .layout-signup .content .page .bg_circle-wrapper {
    row-gap: 106px;
    margin-top: 52px;
    right: 2.2rem;
    left: 2.2rem;
  }
  .layout-signup .content .page .bg_circle-wrapper > .circle {
    width: 300px;
    height: 316px;
    border-radius: 316px;
    filter: blur(200px);
  }
  .layout-signup .content .page .bg_circle-wrapper .circle-one {
    filter: blur(60px);
    position: absolute;
    width: 100%;
    height: 500px;
    left: 0rem;
    right: 0px;
    top: 30rem;
    bottom: 0px;
    border-radius: 100%;
    background: #bde6fc;
  }
  .layout-signup .content .page .bg_circle-wrapper .circle-two {
    filter: blur(130px);
    position: absolute;
    right: 0rem;
    left: 0rem;
    top: 40rem;
    width: 100%;
    height: 325px;
    background: #7f00bb;
  }
  .layout-signup .content .page .bg_circle-wrapper .circle-three {
    display: none;
  }
  .layout-signup .content .page .page-content {
    padding: 20px;
  }
}
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

button {
  text-transform: unset !important;
}

.btn-outlined,
.btn-contained {
  font-size: 16px !important;
  border-radius: 8px !important;
}

.btn-contained:disabled {
  background: #0034bb !important;
  color: #ffffff !important;
  opacity: 0.5 !important;
}

.btn-text {
  font-size: 14px !important;
  min-width: unset !important;
  min-height: unset !important;
  text-transform: unset !important;
  padding: 0 !important;
  line-height: 1.5 !important;
  border-radius: 0 !important;
}
.btn-text:hover {
  background-color: transparent !important;
}

.ecn-input {
  width: 100%;
  border: none;
}
.ecn-input .MuiInputBase-root {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #212121;
}
.ecn-input .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 16px;
}
.ecn-input .MuiInputBase-root .MuiInputBase-input::placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}
.ecn-input .MuiInputBase-root:focus, .ecn-input .MuiInputBase-root:active {
  outline: none;
}
.ecn-input .MuiFormHelperText-root {
  margin-left: 2px;
}

.ecn-underline-input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  line-height: 1.5;
}
.ecn-underline-input .MuiInputLabel-root {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
}
.ecn-underline-input .MuiInput-input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  font-weight: 500;
}
.ecn-underline-input::placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
}
.ecn-underline-input:focus, .ecn-underline-input:active {
  outline: none;
}

.ecn-underline-input-error .MuiInputLabel-root {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 400;
}

.ecn-pagination .MuiPagination-ul {
  flex-wrap: nowrap;
}
.ecn-pagination .MuiPagination-ul li .Mui-selected {
  background: rgba(0, 52, 187, 0.05);
  color: #0034bb;
}
.ecn-pagination .MuiPagination-ul li:first-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ecn-pagination .MuiPagination-ul li:first-child .MuiButtonBase-root {
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 8px 14px;
}
.ecn-pagination .MuiPagination-ul li:first-child > button::after {
  margin-left: 10px;
  content: "Previous";
}
.ecn-pagination .MuiPagination-ul li:last-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ecn-pagination .MuiPagination-ul li:last-child .MuiButtonBase-root {
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 8px 0px;
  display: unset;
}
.ecn-pagination .MuiPagination-ul li:last-child > button::before {
  margin-right: 10px;
  content: "Next";
}

.ecn-breadcrumb {
  margin-top: 40px !important;
  margin-bottom: 25px !important;
}
.ecn-breadcrumb .MuiBreadcrumbs-ol .MuiBreadcrumbs-li {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.ecn-breadcrumb .MuiBreadcrumbs-ol .MuiBreadcrumbs-li:last-child {
  color: #212121;
  cursor: default;
}
.ecn-breadcrumb .MuiBreadcrumbs-ol .MuiBreadcrumbs-separator {
  margin-left: 7px;
  margin-right: 7px;
}

.ecn-chip {
  border-radius: 8px !important;
  padding: 4px 10px !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}

.ecn-checkbox {
  stroke-width: 0.3px !important;
}

.ecn-select {
  width: 100%;
  border-radius: 8px !important;
  font-weight: 500 !important;
  color: #212121 !important;
}
.ecn-select .MuiSelect-select {
  padding: 10px 16px;
}

.ecn-select-empty {
  color: rgba(0, 0, 0, 0.3) !important;
}
.ecn-select-empty .MuiSelect-select {
  font-size: 14px !important;
}

.MuiMenu-root .MuiPaper-root {
  margin-top: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.btn-contained-v2 {
  background: #0034bb !important;
  color: #ffffff !important;
  font-weight: 500;
  box-shadow: unset !important;
}
.btn-contained-v2:disabled {
  background: #0034bb !important;
  color: #ffffff !important;
  opacity: 0.5 !important;
}

.btn-outlined-v2 {
  color: #212121 !important;
  border: 1px solid rgba(33, 33, 33, 0.2) !important;
}
.btn-outlined-v2:hover {
  background: rgba(0, 52, 187, 0.05) !important;
}

.ecn-input-v2 {
  width: 100%;
  min-width: 275px;
}
.ecn-input-v2 .MuiFormHelperText-root {
  color: #ba0000 !important;
  font-size: 14px;
  font-weight: 400;
  border: none !important;
}
.ecn-input-v2 .MuiInputBase-root {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}
.ecn-input-v2 .MuiInputBase-root .Mui-disabled {
  color: rgba(33, 33, 33, 0.6) !important;
  font-size: 16px;
  font-weight: 400;
}
.ecn-input-v2 .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 16px;
}
.ecn-input-v2 .MuiInputBase-root .MuiInputBase-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  opacity: unset;
  color: rgba(33, 33, 33, 0.3);
}
.ecn-input-v2 .MuiFormHelperText-root {
  margin-left: 2px;
}

.ecn-select-v2 .MuiFormControl-root {
  width: 100%;
  min-width: 277px;
}
.ecn-select-v2 .MuiFormControl-root .placeholder {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.3);
  text-transform: none;
}
.ecn-select-v2 .MuiFormControl-root .MuiInputBase-root {
  margin-top: 0px !important;
}
.ecn-select-v2 .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 15px;
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.8);
}
.ecn-select-v2 .MuiFormControl-root .MuiInputBase-root .MuiSelect-select {
  text-transform: capitalize;
}
.ecn-select-v2 .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 16px;
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  background: transparent;
}

.ecn-datagrid {
  border-radius: 0px 0px 0px 0px !important;
  border: none !important;
  padding-inline: 30px;
  padding-bottom: 75px;
}
.ecn-datagrid .MuiDataGrid-actionsCell .show-disabled {
  min-width: 30px;
}
.ecn-datagrid .MuiDataGrid-actionsCell .hide-disabled {
  display: none;
}
.ecn-datagrid .MuiDataGrid-columnHeaderCheckbox {
  min-width: 37px !important;
  width: unset !important;
}
.ecn-datagrid .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: flex-end;
}
.ecn-datagrid .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-indeterminate {
  width: 38px;
  height: 38px;
}
.ecn-datagrid .MuiDataGrid-cellCheckbox {
  justify-content: flex-end;
  min-width: 39px !important;
  width: unset !important;
}
.ecn-datagrid .three-dot-icon {
  color: rgba(33, 33, 33, 0.4);
}
.ecn-datagrid .user-round-icon {
  color: rgba(33, 33, 33, 0.7);
}
.ecn-datagrid .Mui-selected {
  background-color: unset !important;
}
.ecn-datagrid .Mui-hovered {
  background-color: unset !important;
}
.ecn-datagrid .MuiDataGrid-row:hover {
  background-color: unset !important;
}
.ecn-datagrid .MuiChip-root {
  font-size: 14px;
  font-weight: 400;
  height: unset;
  min-height: 28px;
}
.ecn-datagrid .MuiChip-root .MuiChip-label {
  padding-left: 10px;
  padding-right: 10px;
}
.ecn-datagrid .success-chip {
  background-color: rgba(2, 122, 72, 0.05);
  color: #027A48;
}
.ecn-datagrid .pending-chip {
  background: rgba(33, 33, 33, 0.05);
  color: #212121;
}
.ecn-datagrid .MuiDataGrid-row:last-child .MuiDataGrid-cell {
  border-bottom: none !important;
}

.ecn-datagrid-updated {
  border-radius: 0px 0px 0px 0px !important;
  border: none !important;
  padding-inline: 30px;
  padding-bottom: 29px;
}
.ecn-datagrid-updated .MuiDataGrid-actionsCell .show-disabled {
  min-width: 30px;
}
.ecn-datagrid-updated .MuiDataGrid-actionsCell .hide-disabled {
  display: none;
}
.ecn-datagrid-updated .MuiDataGrid-columnHeaderCheckbox {
  min-width: 37px !important;
  width: unset !important;
}
.ecn-datagrid-updated .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: flex-end;
}
.ecn-datagrid-updated .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-indeterminate {
  width: 38px;
  height: 38px;
}
.ecn-datagrid-updated .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-indeterminate .MuiSvgIcon-root {
  width: 28px;
  height: 28px;
}
.ecn-datagrid-updated .MuiDataGrid-cellCheckbox {
  justify-content: flex-end;
  min-width: 39px !important;
  width: unset !important;
}
.ecn-datagrid-updated .three-dot-icon {
  color: rgba(33, 33, 33, 0.4);
}
.ecn-datagrid-updated .user-round-icon {
  color: rgba(33, 33, 33, 0.7);
}
.ecn-datagrid-updated .MuiDataGrid-row .MuiDataGrid-cell {
  padding-left: 20px;
}
.ecn-datagrid-updated .MuiDataGrid-row .MuiDataGrid-cellCheckbox {
  padding-left: 10px;
}
.ecn-datagrid-updated .Mui-selected {
  background-color: unset !important;
  border-radius: 10px;
}
.ecn-datagrid-updated .Mui-selected .MuiDataGrid-cell {
  background-color: rgba(0, 52, 187, 0.05);
}
.ecn-datagrid-updated .Mui-selected .MuiDataGrid-cell:first-child {
  border-radius: 10px 0px 0px 10px;
}
.ecn-datagrid-updated .Mui-selected .MuiDataGrid-cell:last-child {
  border-radius: 0px 10px 10px 0px;
}
.ecn-datagrid-updated .Mui-hovered {
  background-color: unset !important;
  border-radius: 10px;
}
.ecn-datagrid-updated .Mui-hovered .MuiDataGrid-cell {
  background-color: rgba(0, 52, 187, 0.05);
}
.ecn-datagrid-updated .Mui-hovered .MuiDataGrid-cell:first-child {
  border-radius: 10px 0px 0px 10px;
}
.ecn-datagrid-updated .Mui-hovered .MuiDataGrid-cell:last-child {
  border-radius: 0px 10px 10px 0px;
}
.ecn-datagrid-updated .MuiDataGrid-row:hover {
  background-color: unset !important;
  border-radius: 10px;
}
.ecn-datagrid-updated .MuiDataGrid-row:hover .MuiDataGrid-cell {
  background-color: rgba(0, 52, 187, 0.05);
}
.ecn-datagrid-updated .MuiDataGrid-row:hover .MuiDataGrid-cell:first-child {
  border-radius: 10px 0px 0px 10px;
}
.ecn-datagrid-updated .MuiDataGrid-row:hover .MuiDataGrid-cell:last-child {
  border-radius: 0px 10px 10px 0px;
}
.ecn-datagrid-updated .header-sort-icon {
  width: 16px;
  height: 16px;
}
.ecn-datagrid-updated .MuiChip-root {
  font-size: 14px;
  font-weight: 400;
  height: unset;
  min-height: 28px;
}
.ecn-datagrid-updated .MuiChip-root .MuiChip-label {
  padding-left: 10px;
  padding-right: 10px;
}
.ecn-datagrid-updated .success-chip {
  background-color: rgba(2, 122, 72, 0.05);
  color: #027A48;
}
.ecn-datagrid-updated .pending-chip {
  background: rgba(33, 33, 33, 0.05);
  color: #212121;
}
.ecn-datagrid-updated .MuiDataGrid-row:last-child .MuiDataGrid-cell {
  border-bottom: none !important;
}

.MuiDataGrid-menu .MuiPaper-root {
  border-radius: 6px;
  color: rgba(33, 33, 33, 0.7);
  box-shadow: 0px 7px 30px 7px rgba(0, 0, 0, 0.1);
  padding-inline: 11px;
}
.MuiDataGrid-menu .MuiPaper-root .MuiMenuItem-root {
  padding: 14px 29px 14px 31px;
  border-radius: 10px;
}
.MuiDataGrid-menu .MuiPaper-root .MuiMenuItem-root .MuiListItemIcon-root {
  min-width: 33px;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root {
  padding-top: 11px;
  padding-bottom: 11px;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover {
  background: rgba(0, 52, 187, 0.05);
  color: #0034bb;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover .MuiTypography-root {
  color: #0034bb;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover .MuiListItemIcon-root {
  color: #0034bb;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiTypography-root {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.7);
  opacity: unset;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root .Mui-selected {
  background-color: rgba(0, 52, 187, 0.05) !important;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root .Mui-selected:hover {
  background-color: rgba(0, 52, 187, 0.05) !important;
}
.MuiDataGrid-menu .MuiPaper-root .MuiList-root .Mui-selected .MuiTypography-root {
  color: #0034bb;
}

.MuiDataGrid-panelHeader .Mui-focused {
  color: #0034bb !important;
}
.MuiDataGrid-panelHeader .MuiInput-root::after {
  border-bottom: 2px solid #0034bb !important;
}

.MuiDataGrid-panelFooter .MuiButton-root {
  color: #0034bb !important;
}
.MuiDataGrid-panelFooter .Mui-focused {
  color: #0034bb !important;
}

.MuiDataGrid-filterForm .Mui-focused {
  color: #0034bb !important;
}
.MuiDataGrid-filterForm .MuiInput-root::after {
  border-bottom: 2px solid #0034bb !important;
}

.MuiDataGrid-columnHeaders {
  border-top: none !important;
  border-radius: 10px !important;
  padding-left: 10px;
}

.MuiDataGrid-columnsPanelRow .MuiSwitch-switchBase {
  color: #0034bb !important;
}
.MuiDataGrid-columnsPanelRow .MuiSwitch-track {
  background-color: #0034bb !important;
}

.ecn-chip-cross-v2 {
  border-radius: 16px !important;
  background: rgba(0, 52, 187, 0.13) !important;
  color: #212121 !important;
  font-size: 14px !important;
  font-weight: 400;
  padding: 6px 2px 6px 0px !important;
}
.ecn-chip-cross-v2 .MuiChip-label {
  padding-left: 10px;
}
.ecn-chip-cross-v2 .icon {
  color: #212121 !important;
}

.ecn-chip-delete-v2 {
  border-radius: 32px !important;
  background: rgba(0, 52, 187, 0.05) !important;
  font-size: 14px !important;
  height: 34px !important;
  font-weight: 400;
  padding: 6px !important;
  color: #212121;
}
.ecn-chip-delete-v2 .MuiChip-avatar {
  margin-left: 0 !important;
}
.ecn-chip-delete-v2 .MuiChip-label {
  padding-right: 5px;
}
.ecn-chip-delete-v2 .MuiAvatar-root {
  width: 25px !important;
  height: 25px !important;
}
.ecn-chip-delete-v2 .MuiChip-deleteIcon {
  height: 26px;
  width: 26px;
  padding-left: 10px;
}
.ecn-chip-delete-v2 .initials-avatar {
  background: rgba(0, 52, 187, 0.2);
  border-radius: 50px;
  width: 25px !important;
  height: 25px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ecn-chip-delete-v2 .initials-avatar .initials {
  color: #0034bb;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
}

.ecn-radio-v2 {
  gap: 21px;
}
.ecn-radio-v2 .Mui-checked {
  color: #0034bb !important;
}
.ecn-radio-v2 .MuiFormControlLabel-root {
  gap: 15px;
  justify-content: flex-start;
  align-items: center;
}
.ecn-radio-v2 .MuiFormControlLabel-root .MuiButtonBase-root {
  color: rgba(33, 33, 33, 0.2);
  padding-top: unset !important;
  padding-bottom: unset !important;
  padding-right: unset !important;
}
.ecn-radio-v2 .MuiFormControlLabel-root .MuiTypography-root {
  font-size: 14px;
  color: #212121;
}
.ecn-radio-v2 .MuiFormControlLabel-root .MuiTypography-root .radio-label {
  margin-bottom: unset !important;
}

.search-bar .search {
  padding: 9px 14px;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  width: 54px;
  min-height: 44px;
}
.search-bar .search:hover {
  background: rgba(0, 52, 187, 0.05);
}
.search-bar .hide-icon {
  min-height: 46px;
}
.search-bar .hide-icon .icon {
  display: none;
}
.search-bar .active {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 20vw;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 9px 18px;
  border-radius: 7px;
  min-height: 44px;
  transition: width 0.5s;
  background-color: #ffffff !important;
  cursor: auto;
}
.search-bar .active .icon {
  cursor: pointer;
  flex-shrink: 0;
}
.search-bar .active:focus-within, .search-bar .active:focus {
  border: 1px solid rgba(0, 52, 187, 0.7) !important;
  outline: 1px solid rgba(0, 52, 187, 0.7);
}
.search-bar .active:hover {
  border: 1px solid rgba(0, 52, 187, 0.7) !important;
  outline: 1px solid rgba(0, 52, 187, 0.7);
  background: unset;
}
.search-bar .active input {
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  background-color: #ffffff;
}
.search-bar .active ::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  opacity: unset;
}
.search-bar .active .search-cross {
  cursor: pointer;
  width: 24px !important;
  height: 24px !important;
  color: #000000;
}

.fallback-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.fallback-screen img {
  width: auto;
  max-height: 300px;
}
.fallback-screen .fallback-text {
  color: rgba(33, 33, 33, 0.8);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;
  max-width: 894px;
  margin-inline: auto;
  text-align: center;
  margin-top: -4px;
}
.fallback-screen .btn-contained-v2 {
  padding: 10px 86px;
  margin-bottom: 70px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 7px;
}

.MuiMenu-root .MuiMenu-paper {
  padding-inline: 11px;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root {
  padding-bottom: 11px;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .checkbox-list-item {
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 10px;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .large-list-item {
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 10px;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .small-list-item {
  border-radius: 8px;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .MuiButtonBase-root {
  margin-bottom: 3px;
  font-weight: 500;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .MuiButtonBase-root:hover {
  background: rgba(0, 52, 187, 0.05);
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .MuiButtonBase-root:hover .MuiTypography-root {
  color: #0034bb;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .MuiButtonBase-root .MuiTypography-root {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.7);
  opacity: unset;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .Mui-selected {
  background-color: rgba(0, 52, 187, 0.05) !important;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .Mui-selected:hover {
  background-color: rgba(0, 52, 187, 0.05) !important;
}
.MuiMenu-root .MuiMenu-paper .MuiList-root .Mui-selected .MuiTypography-root {
  color: #0034bb;
}

.ecn-number-input-v2 .input-icon {
  display: flex;
  font-size: 10px;
  font-weight: 400;
  justify-content: space-between;
  width: 300px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px 5px 5px 13px;
}
.ecn-number-input-v2 .input-icon input::-webkit-outer-spin-button,
.ecn-number-input-v2 .input-icon input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ecn-number-input-v2 .input-icon:focus-within, .ecn-number-input-v2 .input-icon:focus {
  border: 1px solid rgba(0, 52, 187, 0.7) !important;
  outline: 1px solid rgba(0, 52, 187, 0.7);
}
.ecn-number-input-v2 .input-icon:hover {
  border: 1px solid rgba(0, 52, 187, 0.7) !important;
  outline: 1px solid rgba(0, 52, 187, 0.7);
  background: unset;
}
.ecn-number-input-v2 .input-icon input {
  border: none;
  outline: none;
  width: 100%;
}
.ecn-number-input-v2 .input-icon input[type=number] {
  -moz-appearance: textfield;
}
.ecn-number-input-v2 .input-icon .arrows {
  display: flex;
  flex-direction: column;
}
.ecn-number-input-v2 .input-icon .arrows .icon {
  width: 13px;
  color: #212121;
  height: auto;
}
.ecn-number-input-v2 .error {
  border: 1px solid #c53838;
}
.ecn-number-input-v2 .error:focus-within, .ecn-number-input-v2 .error:focus {
  border: 1px solid #c53838 !important;
  outline: 1px solid #c53838;
}
.ecn-number-input-v2 .error:hover {
  border: 1px solid #c53838 !important;
  outline: 1px solid #c53838;
  background: unset;
}

.signup-details {
  display: block;
  align-items: center;
  margin-inline: auto;
  min-height: 89vh;
  height: 100%;
  width: 400px;
  margin-top: 3rem;
}
.signup-details .bg_circle-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  position: absolute;
  z-index: -1;
  opacity: 0.2;
  -moz-opacity: 0.07;
  right: 7.75rem;
  left: 7.75rem;
}
.signup-details .bg_circle-wrapper > .circle {
  width: 320px;
  height: 325px;
  border-radius: 325px;
  filter: blur(200px);
  flex: 1 1 30%;
  overflow: visible;
}
.signup-details .bg_circle-wrapper .circle-one {
  background: #0034bb;
}
.signup-details .bg_circle-wrapper .circle-two {
  background: #0034bb;
}
.signup-details .bg_circle-wrapper .circle-three {
  background: #027A48;
}
.signup-details .bg_circle-wrapper .circle-four {
  background: #2bba1d;
}
.signup-details .bg_circle-wrapper .circle-five {
  background: #ba0000;
}
.signup-details .bg_circle-wrapper .circle-six {
  background: #fe9800;
}
.signup-details .branding .old {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
}
.signup-details .branding .old .old-logo {
  max-width: 86px;
}
.signup-details .branding .old .sub-head {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.65);
}
.signup-details .branding .new {
  text-align: center;
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-radius: 11px;
  padding: 16px 90px;
  margin: 14px 15px;
}
.signup-details .branding .new .ecn-logo {
  display: block;
  width: 130px;
  height: auto;
  margin-bottom: 0px;
  margin-inline: auto;
}
.signup-details .branding .new .sub {
  font-size: 12px;
  color: #212121;
}
.signup-details .card {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  box-shadow: 0px 12px 40px 0px rgba(33, 33, 33, 0.05);
  padding: 32px 25px;
  width: 400px;
}
.signup-details .card .progress {
  text-align: right;
  margin-top: -11px;
}
.signup-details .card .progress p {
  font-size: 12px;
  font-weight: 500;
}
.signup-details .card .basic-card .title {
  font-size: 20px;
  font-weight: 500;
  color: #212121;
  text-align: center;
  margin-top: 16px;
}
.signup-details .card .basic-card .row {
  width: 100%;
  margin-bottom: 12px;
}
.signup-details .card .basic-card .row .MuiInputBase-root {
  border-radius: 8px !important;
}
.signup-details .card .basic-card .row p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 7px;
}
.signup-details .card .basic-card .row .input-text {
  width: 100%;
}
.signup-details .card .basic-card .button-div {
  text-align: right;
  margin-top: 25px;
}
.signup-details .card .basic-card .button-div .button {
  border-radius: 8px;
  color: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
}
.signup-details .card .basic-card .button-div .disable {
  background: rgba(0, 52, 187, 0.55);
}
.signup-details .card .company-details .title {
  font-size: 22px;
  font-weight: 500;
  color: #212121;
  text-align: center;
  margin-top: 16px;
  line-height: 28px;
}
.signup-details .card .company-details .field {
  margin-bottom: 12px;
}
.signup-details .card .company-details .field p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 7px;
}
.signup-details .card .company-details .field .MuiInputBase-root {
  border-radius: 8px !important;
}
.signup-details .card .company-details .field .input-text {
  width: 100%;
}
.signup-details .card .company-details .checkbox-container {
  display: flex;
  align-items: center;
  margin-left: -8px;
  margin-top: -10px;
}
.signup-details .card .company-details .checkbox-container p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.signup-details .card .company-details .button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.signup-details .card .company-details .button button {
  border-radius: 8px;
}
.signup-details .card .company-details .button .finished {
  background: #0034bb;
  color: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
}
.signup-details .card .company-details .button .disable {
  background: rgba(0, 52, 187, 0.55);
}
.signup-details .card .company-details .button .back {
  margin-left: -10px;
}
.signup-details footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 40px;
  border-top: 1px solid rgba(33, 33, 33, 0.1);
}
.signup-details footer .footer-btn {
  border-bottom: 1px solid !important;
}

.signup .container {
  display: flex;
  padding: 24px;
  height: 100vh;
}
.signup .container .left {
  flex: 0.44;
  width: inherit;
  max-width: 44%;
}
.signup .container .left .wrapper {
  height: 100%;
  padding-left: 15%;
}
.signup .container .left .wrapper .elevate-logo {
  height: 30px;
  width: auto;
  margin-left: -5px;
}
.signup .container .left .wrapper .content-wrapper {
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  padding-right: 15%;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div {
  display: flex;
  align-items: center;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step h2,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step h2,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step h2,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step h2 {
  font-size: 36px;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .sub-text,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .sub-text,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .sub-text,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .sub-text {
  margin: 16px 0;
  margin-bottom: 32px;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .row,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .row,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .row,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .row .field,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .row .field,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .row .field,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .row .field {
  flex: 0.5;
  margin-bottom: 24px;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .field,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .field,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .field,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .field {
  margin: 24px 0;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .field label,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .field label,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .field label,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .field label {
  font-size: 14px;
  line-height: 1.5;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .field p,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .field p,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .field p,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .field p {
  font-size: 14px;
  line-height: 1.5;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .field p span,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .field p span,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .field p span,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .field p span {
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .checkbox-container,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .checkbox-container,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .checkbox-container,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .checkbox-container {
  display: flex;
  align-items: flex-start;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .checkbox-container p,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .checkbox-container p,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .checkbox-container p,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .checkbox-container p {
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
  margin-top: 10px;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .checkbox-container p span,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .checkbox-container p span,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .checkbox-container p span,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .checkbox-container p span {
  text-decoration: underline;
  font-weight: 600;
  color: #0034bb;
  cursor: pointer;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .helper-link,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .helper-link,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .helper-link,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .helper-link {
  margin-top: 26px;
  margin-bottom: 20px;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .helper-link a,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .helper-link a,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .helper-link a,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .helper-link a {
  font-weight: 600;
  text-decoration: none;
  color: #0034bb;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .helper-link a:hover,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .helper-link a:hover,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .helper-link a:hover,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .helper-link a:hover {
  text-decoration: underline;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .ecn-input-verified .MuiButtonBase-root,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .ecn-input-verified .MuiButtonBase-root,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .ecn-input-verified .MuiButtonBase-root,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .ecn-input-verified .MuiButtonBase-root {
  color: #2bba1d;
  font-family: Poppins;
  font-size: 14px;
  gap: 4px;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .cta-btn,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .cta-btn,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .cta-btn,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .cta-btn {
  margin-top: 16px;
  padding: 8px 50px;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .btn-group,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .btn-group,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .btn-group,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .btn-group {
  display: flex;
  gap: 24px;
  margin-top: 70px;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .btn-group .btn-outlined,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .btn-group .btn-outlined,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .btn-group .btn-outlined,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .btn-group .btn-outlined {
  padding: 8px 30px;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email-step .btn-group .btn-contained,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .email_verification-step .btn-group .btn-contained,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .password_creation-step .btn-group .btn-contained,
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .btn-group .btn-contained {
  padding: 8px 60px;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step h2 {
  margin-bottom: 24px;
}
.signup .container .left .wrapper .content-wrapper .react-swipeable-view-container > div .details-step .field {
  margin-top: 0;
  margin-bottom: 10px;
}
.signup .container .right {
  flex: 0.56;
  background: #000000;
  overflow-x: hidden;
}
.signup .container .right img {
  height: 88%;
  margin-top: 5%;
  margin-left: 10%;
  border-radius: 20px;
}

.add-user .top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-user .top-header h1 {
  font-size: 40px;
  font-weight: 600;
  color: #101828;
}
.add-user .bottom-header .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.add-user .bottom-header .wrapper .left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.add-user .bottom-header .wrapper .left .search {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 20vw;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  border-radius: 8px;
}
.add-user .bottom-header .wrapper .left .search input {
  outline: none;
  border: none;
  font-size: 16px;
  width: 100%;
}
.add-user .bottom-header .wrapper .left .search ::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #667085;
}
.add-user .bottom-header .wrapper .left .menu {
  display: flex;
  align-items: center;
  padding: 7px 15px;
  gap: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #374151;
}
.add-user .bottom-header .wrapper .right {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.add-user .bottom-header .wrapper .right .check {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1px 0px;
  padding-left: 15px;
}
.add-user .bottom-header .wrapper .right .check-two {
  padding: 1px 0px;
  padding-left: 15px;
}
.add-user .bottom-header .chips-list {
  margin-top: 26px;
  display: flex;
  gap: 16px;
}
.add-user .bottom-header .chips-list .MuiChip-root {
  background-color: rgba(0, 52, 187, 0.1);
  font-size: 14px;
}
.add-user .body {
  margin-top: 20px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}
.add-user .body .table-container {
  border: 1px solid #d1d5db;
  overflow: hidden;
  border-radius: 8px;
  min-height: 50vh;
  border-radius: 8px 8px 0 0;
}
.add-user .body .table-container .head {
  background: #f3f4f6;
  width: 100%;
}
.add-user .body .table-container .head .head-cell {
  font-size: 12px;
  font-weight: 500;
  color: #6b7280;
}
.add-user .body .table-container .head .head-cell:nth-child(1) {
  width: 200px !important;
}
.add-user .body .table-container .head .head-cell:nth-child(2) {
  width: 100px !important;
}
.add-user .body .table-container .head .head-cell:nth-child(3) {
  width: 100px !important;
}
.add-user .body .table-container .head .head-cell:nth-child(4) {
  width: 150px !important;
}
.add-user .body .table-container .head .head-cell:nth-child(5) {
  width: 170px !important;
}
.add-user .body .table-container .head .head-cell:nth-child(6) {
  width: 170px !important;
}
.add-user .body .table-container .table__body-row:hover {
  background-color: rgba(0, 52, 187, 0.05);
}
.add-user .body .table-container .table__body-row .table__cell {
  font-size: 12px;
  font-weight: 500;
}
.add-user .body .table-container .table__body-row .table__cell .user {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.add-user .body .table-container .table__body-row .table__cell .user .details .name {
  color: #101828;
}
.add-user .body .table-container .table__body-row .table__cell .user .details .email {
  color: #6b7280;
}
.add-user .body .table-container .table__body-row .table__cell .MuiChip-root {
  font-size: 12px;
  font-weight: 500;
  padding: 2px;
}
.add-user .body .table-container .table__body-row .table__cell .success-chip {
  background-color: rgba(2, 122, 72, 0.1);
  color: #027A48;
}
.add-user .body .table-container .table__body-row .table__cell .success-chip .MuiChip-icon {
  color: #027A48;
  height: 24px;
}
.add-user .body .table-container .table__body-row .table__cell button {
  font-size: 12px;
  font-weight: 500;
}
.add-user .body .table-container .table__body-row .table__cell .btn_text-style {
  text-decoration: underline;
}
.add-user .body .table-container .table__body-row .table__cell .btn_bg-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 52, 187, 0.1);
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid #0034bb;
  font-size: 14px;
  font-weight: 500;
}
.add-user .body .table-container .table__body-row:last-child .table__cell {
  border-bottom: none;
}
.add-user .body .table-container .image {
  text-align: center;
  width: 100%;
  margin-top: 40px;
}
.add-user .body .table-container .image h2 {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}
.add-user .body .pagination-container {
  border: 1px solid #d1d5db;
  border-top: none;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  padding: 14px 24px;
}

.MuiDrawer-paper {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.MuiDrawer-paper .user__detail-back {
  color: #000000 !important;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-items: left;
  justify-content: left;
}
.MuiDrawer-paper .drawer__user-detail {
  min-width: 350px;
  height: 570px;
  overflow-y: scroll;
}
.MuiDrawer-paper .drawer__user-detail .user__image-details {
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
  margin-bottom: 10px;
}
.MuiDrawer-paper .drawer__user-detail .user__image-details .user__avatar {
  width: 70px;
  height: 70px;
  margin: auto;
}
.MuiDrawer-paper .drawer__user-detail .user__image-details h3 {
  font-size: 20px;
  font-weight: 700;
  color: #101828;
}
.MuiDrawer-paper .drawer__user-detail .user__image-details h5 {
  font-size: 14px;
  font-weight: 500;
  color: #101828;
}
.MuiDrawer-paper .drawer__user-detail .user__image-details p {
  font-size: 12px;
  font-weight: 500;
  color: #667085;
}
.MuiDrawer-paper .drawer__user-detail .user_other-details {
  margin-bottom: 15px;
}
.MuiDrawer-paper .drawer__user-detail .user_other-details p {
  font-size: 12px;
  font-weight: 400;
  color: #667085;
}
.MuiDrawer-paper .drawer__user-detail .user_other-details .licenses__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MuiDrawer-paper .drawer__user-detail .user_other-details .licenses__label .text {
  font-size: 12px;
  font-weight: 400;
  color: #101828;
  text-decoration: underline;
  text-underline-offset: 5px;
}
.MuiDrawer-paper .drawer__user-detail .user_other-details h3 {
  font-size: 12px;
  font-weight: 600;
  color: #101828;
}
.MuiDrawer-paper .drawer__user-detail .user_other-details h3 .sign__up-complete {
  color: #027A48;
}
.MuiDrawer-paper .drawer__user-detail .user_other-details h3 .sign__up-pending {
  color: #101828;
}
.MuiDrawer-paper .drawer__user-detail .user_other-details .licenses__chip-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MuiDrawer-paper .drawer__user-detail .user_other-details .licenses__chip-row .licenses__chip {
  border-radius: 5px;
  border: 0.5px solid #0034bb;
  background: rgba(0, 52, 187, 0.1);
  width: fit-content;
  padding: 4px 10px;
  color: #0034bb;
  font-size: 13px;
  font-weight: 400;
  margin-top: 10px;
  text-transform: capitalize;
}
.MuiDrawer-paper .drawer__user-detail .user_other-details .licenses__chip-row .text {
  text-decoration: underline;
  color: #000000;
  text-underline-offset: 5px;
}
.MuiDrawer-paper .drawer__user-detail::-webkit-scrollbar {
  width: none;
  width: 0px;
}
.MuiDrawer-paper .buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 10px;
}
.MuiDrawer-paper .buttons .btn-outlined {
  border: 1px solid rgba(16, 24, 40, 0.2);
  color: #101828;
}
.MuiDrawer-paper .buttons .btn-outlined-background {
  color: #0034bb;
  border: 1px solid #0034bb;
  background: rgba(127, 0, 187, 0.1);
}
.MuiDrawer-paper .button {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 15px;
  gap: 20px;
}
.MuiDrawer-paper .button .btn-outlined {
  border: 1px solid rgba(16, 24, 40, 0.2);
  color: #101828;
  font-weight: 400;
  font-size: 14px;
  min-width: 120px;
}
.MuiDrawer-paper .button .btn-contained {
  font-weight: 400;
  font-size: 14px;
  min-width: 120px;
}

.menu-container .MuiPaper-root {
  width: 149px;
}
.menu-container .MuiPaper-root .MuiList-root {
  padding: 16px;
}
.menu-container .MuiPaper-root .MuiList-root .MuiButtonBase-root {
  padding: 0;
  min-width: 90px;
  min-height: unset;
  border: none;
}
.menu-container .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiPaper-root {
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin-top: 0;
  width: 100%;
}
.menu-container .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiPaper-root:hover {
  box-shadow: none;
}
.menu-container .MuiPaper-root .MuiList-root .MuiButtonBase-root:before {
  display: none;
}
.menu-container .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiAccordionSummary-content {
  margin: 8px 0;
  font-size: 14px;
  color: #101828;
  font-weight: 500;
}
.menu-container .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(90deg);
}
.menu-container .MuiPaper-root .MuiList-root .MuiCollapse-root .MuiAccordionDetails-root {
  padding: 5px 0;
}
.menu-container .MuiPaper-root .MuiList-root .MuiCollapse-root .MuiAccordionDetails-root .option {
  display: flex;
  align-items: center;
  justify-self: flex-start;
  gap: 6px;
}
.menu-container .MuiPaper-root .MuiList-root .MuiCollapse-root .MuiAccordionDetails-root .option .MuiCheckbox-root {
  width: auto;
  min-width: unset;
}
.menu-container .MuiPaper-root .MuiList-root .MuiCollapse-root .MuiAccordionDetails-root .option p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(16, 24, 40, 0.7);
}

.ecn-select {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.user {
  margin-left: 10px;
  background: #ffffff;
}
.user Button:hover {
  background: rgba(0, 52, 187, 0.1);
}
.user .user-header .top-header {
  margin-top: 39px;
}
.user .user-header .top-header .top {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.user .user-header .top-header .top .icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.user .user-header .top-header .top p {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.user .user-header .top-header .top .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.user .user-header .top-header h1 {
  color: #212121;
  font-size: 22px;
  font-weight: 600;
  margin-top: -15px;
}
.user .user-header .bottom-header .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 41px;
}
.user .user-header .bottom-header .wrapper .left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.user .user-header .bottom-header .wrapper .left .active {
  box-shadow: 0px 1px #ffffff;
}
.user .user-header .bottom-header .wrapper .left .active h3 {
  color: #0034bb !important;
}
.user .user-header .bottom-header .wrapper .left .active:hover {
  box-shadow: 0px 1px #f2f5fc;
}
.user .user-header .bottom-header .wrapper .left .all-users {
  cursor: pointer;
  border-radius: 15px 15px 0px 0px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-bottom: none;
  background: #ffffff;
  display: flex;
  min-width: 251px;
  padding: 18px 51px 18px 24px;
  align-items: center;
  gap: 8px;
}
.user .user-header .bottom-header .wrapper .left .all-users:hover {
  background: rgba(0, 52, 187, 0.05);
}
.user .user-header .bottom-header .wrapper .left .all-users h3 {
  color: #212121;
  font-size: 18px;
  font-weight: 500;
}
.user .user-header .bottom-header .wrapper .left .all-users .users-count {
  color: #212121;
  font-size: 12px;
  font-weight: 400;
}
.user .user-header .bottom-header .wrapper .download-active {
  display: none !important;
}
.user .user-header .bottom-header .wrapper .bottom-right {
  display: flex;
  align-items: center;
  gap: 25px;
}
.user .user-header .bottom-header .wrapper .bottom-right .active {
  border: none !important;
  padding: 8px 10px !important;
  min-width: unset;
  transition: all 0.5s;
}
.user .user-header .bottom-header .wrapper .bottom-right .filter {
  padding: 7px 18px;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  gap: 12px;
  color: #212121;
  font-size: 16px;
  font-weight: 500;
}
.user .user-header .bottom-header .wrapper .bottom-right .filter:hover {
  background: rgba(0, 52, 187, 0.05);
}
.user .user-header .bottom-header .wrapper .bottom-right .btn-outlined {
  background: #0034bb;
  color: #ffffff;
  border-radius: 7px;
  gap: 12px;
  padding: 7px 18px;
  border: 1px solid #0034bb;
  box-shadow: none;
}
.user .user-header .bottom-header .wrapper .bottom-right .search {
  padding: 9px 18px;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user .user-header .bottom-header .wrapper .bottom-right .search:hover {
  background: rgba(0, 52, 187, 0.05);
}
.user .user-header .bottom-header .wrapper .bottom-right .hide-icon {
  min-height: 46px;
}
.user .user-header .bottom-header .wrapper .bottom-right .hide-icon .icon {
  display: none;
}
.user .user-header .bottom-header .wrapper .bottom-right .active-search {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 20vw;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 10px 18px;
  border-radius: 7px;
  min-height: 46px;
}
.user .user-header .bottom-header .wrapper .bottom-right .active-search .icon {
  cursor: pointer;
  flex-shrink: 0;
}
.user .user-header .bottom-header .wrapper .bottom-right .active-search:focus-within, .user .user-header .bottom-header .wrapper .bottom-right .active-search:focus {
  border: 1px solid #0034bb;
}
.user .user-header .bottom-header .wrapper .bottom-right .active-search input {
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
}
.user .user-header .bottom-header .wrapper .bottom-right .active-search ::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  opacity: unset;
}
.user .user-header .bottom-header .wrapper .bottom-right .active-search .search-cross {
  cursor: pointer;
  width: 24px !important;
  height: 24px !important;
  color: #000000;
}
.user .table-active .MuiDataGrid-columnHeaders {
  display: none;
}
.user .table-active .users-selected {
  margin-bottom: 81px;
  padding-top: 22px;
  padding-inline: 30px;
}
.user .table-active .users-selected .selected-wrapper {
  background-color: rgba(2, 122, 72, 0.05);
  border-radius: 9px;
  color: rgb(2, 122, 72);
  padding: 5.4px 7px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  gap: 24px;
}
.user .table-active .users-selected p {
  font-size: 14px;
  font-weight: 500;
}
.user .table {
  margin-bottom: 80px;
  border: 1px solid rgba(33, 33, 33, 0.07);
  border-radius: 0 6px 6px 6px;
}
.user .table .no-result {
  text-align: center;
  color: #212121;
  font-size: 18px;
  font-weight: 500;
  margin-top: -42px;
  margin-bottom: 50px;
}
.user .table .details {
  display: flex;
  align-items: center;
  margin-left: -8px;
  justify-content: center;
  gap: 16px;
}
.user .table .details .btn-text {
  color: #0034bb;
}
.user .table .details .name {
  color: #212121;
  font-size: 14px;
  font-weight: 500;
  width: 119px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.user .table .details .designation {
  width: 119px;
  color: #212121;
  font-size: 12px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.user .table .details .user-avatar {
  width: 40px;
}
.user .table .details .profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: rgb(242, 244, 248);
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.user .table .details .profile-pic svg {
  color: rgb(193, 199, 205);
  width: 24px;
  height: 24px;
}
.user .table .pagination-container {
  border-radius: 0 0 6px 6px;
  background: #fdfdfd;
}
.user .table .pagination-container .user-pagination {
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  overflow: hidden;
  padding: 16px 24px;
}
.user .table .pagination-container .user-pagination .MuiPagination-ul {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  flex-wrap: nowrap;
}
.user .table .pagination-container .user-pagination .MuiPagination-ul .Mui-disabled {
  display: none;
}
.user .table .pagination-container .user-pagination .MuiPagination-ul li .Mui-selected {
  border-radius: 7px;
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.user .table .pagination-container .user-pagination .MuiPagination-ul li:first-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.user .table .pagination-container .user-pagination .MuiPagination-ul li:first-child .MuiButtonBase-root {
  border: none;
  box-shadow: none;
  padding: 8px 14px;
  color: #0034bb;
  font-size: 14px;
  font-weight: 500;
}
.user .table .pagination-container .user-pagination .MuiPagination-ul li:first-child .MuiButtonBase-root svg {
  display: none;
}
.user .table .pagination-container .user-pagination .MuiPagination-ul li:first-child > button::after {
  margin-left: 10px;
  content: "Previous";
}
.user .table .pagination-container .user-pagination .MuiPagination-ul li:last-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.user .table .pagination-container .user-pagination .MuiPagination-ul li:last-child .MuiButtonBase-root {
  border: none;
  box-shadow: none;
  padding: 8px 14px;
  color: #0034bb;
  font-size: 14px;
  font-weight: 500;
}
.user .table .pagination-container .user-pagination .MuiPagination-ul li:last-child .MuiButtonBase-root svg {
  display: none;
}
.user .table .pagination-container .user-pagination .MuiPagination-ul li:last-child > button::before {
  margin-right: 10px;
  content: "Next";
}
.user .invite-user {
  background: #fdfdfd;
}
.user .invite-user .container {
  display: flex;
  justify-content: center;
  min-width: 1064px;
  min-height: 450px;
  margin-top: 40px;
  margin-bottom: 10px;
  padding: 40px 40px 180px 40px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: #ffffff;
}
.user .invite-user .container .invite-success {
  margin: auto;
  text-align: center;
}
.user .invite-user .container .invite-success img {
  max-width: 268px;
  height: auto;
}
.user .invite-user .container .invite-success h3 {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  margin-top: 25px;
  margin-bottom: 40px;
  max-width: 689px;
}
.user .invite-user .container .invite-success .btn-contained {
  background: #0034bb;
  color: #ffffff;
  min-width: 225px;
  padding: 10px 31px;
}
.user .invite-user .container .wrapper {
  display: flex;
  flex-direction: column;
  gap: 33px;
}
.user .invite-user .container .wrapper .top {
  display: flex;
  gap: 40px;
}
.user .invite-user .container .wrapper .top .left {
  border-radius: 14px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 40px;
  padding-top: 58px;
  gap: 23px;
}
.user .invite-user .container .wrapper .top .left .avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(33, 33, 33, 0.05);
  border: 1px solid rgba(33, 33, 33, 0.1);
  width: 190px;
  height: 190px;
  border-radius: 100%;
}
.user .invite-user .container .wrapper .top .left .avatar .icon {
  min-width: 70px;
  min-height: 70px;
  color: rgba(33, 33, 33, 0.6);
}
.user .invite-user .container .wrapper .top .left .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.user .invite-user .container .wrapper .top .left .btn-outlined {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  padding: 7px 10px;
  min-width: 200px;
  gap: 12px;
}
.user .invite-user .container .wrapper .top .left .change-btn {
  color: rgba(0, 52, 187, 0.8);
}
.user .invite-user .container .wrapper .top .container-right {
  display: flex;
  flex-direction: column;
  gap: 33px;
  border-radius: 14px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 40px;
  padding-top: 11px;
}
.user .invite-user .container .wrapper .top .container-right .text-fields .row {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 30px;
}
.user .invite-user .container .wrapper .top .container-right .text-fields .row .field {
  width: 50%;
}
.user .invite-user .container .wrapper .top .container-right .text-fields .row .field p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 4px;
}
.user .invite-user .container .wrapper .top .container-right .text-fields .row .field .mobile-error {
  color: #ba0000;
  font-size: 14px;
  font-weight: 400;
  margin-left: 2px;
  margin-top: 3px;
}
.user .invite-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input {
  height: auto;
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding-right: 2px;
}
.user .invite-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input:hover, .user .invite-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input:focus, .user .invite-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input:focus-within {
  border-color: rgba(0, 52, 187, 0.7);
}
.user .invite-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input input {
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
  color: #212121;
}
.user .invite-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input .form-control {
  width: 91%;
  border-radius: 8px;
  margin-left: 26px;
  border: unset;
  border-left: none;
  height: 43px;
}
.user .invite-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input .flag-dropdown {
  border-radius: 8px 0 0 8px;
  border-right: none;
  border: unset;
  background: rgba(255, 255, 255, 0.8);
}
.user .invite-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input .flag-dropdown .selected-flag {
  padding: 0 0 0 19px;
  background-color: unset;
}
.user .invite-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input .flag-dropdown .selected-flag .flag .arrow {
  border-top: unset;
  border-left: unset;
  border-right: unset;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 6L8 10L12 6" stroke="%23212121" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  top: unset;
  left: 22px;
}
.user .invite-user .container .wrapper .bottom {
  text-align: right;
}
.user .invite-user .container .wrapper .bottom .btn-contained-v2 {
  border-radius: 7px;
  border: 1px solid #0034bb;
  background: #0034bb;
  color: #ffffff;
  min-width: 225px;
  padding: 7px 31px;
  font-size: 16px !important;
  font-weight: 500;
  text-align: right;
}
.user .invite-user .container .wrapper .bottom .btn-contained-v2:disabled {
  background: #0034bb !important;
  color: #ffffff !important;
  opacity: 0.5 !important;
}

.menu-container-users {
  height: 500px;
}
.menu-container-users .MuiPaper-root {
  width: 250px;
  border-radius: 6px;
  box-shadow: 0px 7px 30px 7px rgba(0, 0, 0, 0.1);
}
.menu-container-users .MuiPaper-root .MuiList-root .Mui-checked {
  color: #0034bb;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root {
  padding: 0;
  width: 225px;
  min-height: unset;
  border: none;
  border-radius: 10px;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover {
  background: rgba(0, 52, 187, 0.05);
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover .MuiAccordionSummary-content {
  color: #0034bb;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiPaper-root {
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin-top: 0;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiPaper-root:hover {
  box-shadow: none;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root:before {
  display: none;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiAccordionSummary-content {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.7);
  font-weight: 500;
  padding-inline: 20px;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiAccordionSummary-expandIconWrapper {
  margin-right: 18px;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiButtonBase-root .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(90deg);
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiCollapse-root .MuiAccordionDetails-root {
  padding: 5px 0;
  padding-inline: 20px;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiCollapse-root .MuiAccordionDetails-root .option {
  display: flex;
  align-items: center;
  justify-self: flex-start;
  gap: 6px;
  padding: 10px 0px;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiCollapse-root .MuiAccordionDetails-root .option .MuiCheckbox-root {
  width: auto;
  min-width: unset;
}
.menu-container-users .MuiPaper-root .MuiList-root .MuiCollapse-root .MuiAccordionDetails-root .option p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(16, 24, 40, 0.7);
}

.user-menu {
  display: unset !important;
  padding: 0px !important;
  text-align: right !important;
  width: 10px !important;
  min-width: 10px !important;
  max-width: 10px !important;
  background: transparent;
  border: none;
  background: transparent !important;
  cursor: pointer;
  margin-right: 0px;
}

.user-menu-item .MuiPaper-root {
  padding: 10px 10px;
  margin-left: -20px;
  padding-bottom: 5px;
}
.user-menu-item .MuiPaper-root .MuiMenuItem-root {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.user-menu-item .MuiPaper-root .MuiMenuItem-root .icons {
  color: rgba(33, 33, 33, 0.6);
  flex-shrink: 0;
}
.user-menu-item .MuiPaper-root .MuiMenuItem-root span {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
}
.user-menu-item .MuiPaper-root .MuiMenuItem-root:hover {
  border-radius: 8px;
}
.user-menu-item .MuiPaper-root .MuiMenuItem-root:hover span {
  font-size: 14px;
  font-weight: 500;
  color: #0034bb !important;
}
.user-menu-item .MuiPaper-root .MuiMenuItem-root:hover .icons {
  color: #0034bb !important;
}
.user-menu-item .MuiPaper-root .menu-hidden {
  display: none;
}

.MuiMenuItem-root {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.upload .MuiPaper-root .heading {
  padding-top: 39px;
  padding-left: 42px;
  padding-right: 34px;
  padding-bottom: 50px;
  padding: 39px 34px 36px 42px;
  display: flex;
  justify-content: space-between;
}
.upload .MuiPaper-root .heading .icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  width: 24px;
  height: 24px;
}
.upload .MuiPaper-root .container {
  padding: 0 34px 0 42px;
}
.upload .MuiPaper-root .container h1 {
  color: #212121;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 32px;
}

@media only screen and (min-width: 1199px) and (max-width: 1318px) {
  .user .user-header .bottom-header .wrapper {
    gap: 10px;
  }
  .user .user-header .bottom-header .wrapper .left .all-users {
    min-width: 209px;
    padding: 15px 56px 15px 21px;
    gap: 7px;
  }
  .user .user-header .bottom-header .wrapper .left .all-users h3 {
    font-size: 15px;
  }
  .user .user-header .bottom-header .wrapper .left .all-users p {
    font-size: 10px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right {
    gap: 21px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .search {
    padding: 9px 15px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .search .icon {
    width: 17px;
    height: 17px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .filter {
    padding: 6px 15px;
    font-size: 13px;
    gap: 10px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .filter .icon {
    width: 17px;
    height: 17px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .btn-outlined {
    padding: 6px 15px;
    font-size: 13px;
    gap: 10px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .btn-outlined .icon {
    width: 17px;
    height: 17px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1198px) {
  .user .user-header .bottom-header .wrapper {
    gap: 10px;
  }
  .user .user-header .bottom-header .wrapper .left .all-users {
    min-width: 175px;
    padding: 15px 21px 15px 21px;
    gap: 7px;
  }
  .user .user-header .bottom-header .wrapper .left .all-users h3 {
    font-size: 15px;
  }
  .user .user-header .bottom-header .wrapper .left .all-users p {
    font-size: 10px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right {
    gap: 21px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .search {
    padding: 9px 15px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .search .icon {
    width: 17px;
    height: 17px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .filter {
    padding: 6px 15px;
    font-size: 13px;
    gap: 10px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .filter .icon {
    width: 17px;
    height: 17px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .btn-outlined {
    padding: 6px 15px;
    font-size: 13px;
    gap: 10px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .btn-outlined .icon {
    width: 17px;
    height: 17px;
  }
}
.resend-invite-success .MuiDialogContent-root {
  padding: 50px 24px;
}
.resend-invite-success .MuiDialogContent-root img {
  max-width: 250px;
  max-height: 278px;
}
.resend-invite-success .MuiDialogContent-root p {
  color: #212121;
  margin-top: 13px;
  font-size: 25px;
  font-weight: 600;
}

.groups-roles {
  margin-left: 10px;
  background: #fdfdfd;
}
.groups-roles .table {
  margin-bottom: 80px;
  border: 1px solid rgba(33, 33, 33, 0.07);
  border-radius: 15px 15px 15px 15px;
}
.groups-roles .table .no-result {
  text-align: center;
  color: #212121;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 50px;
}
.groups-roles .table .pagination-container {
  border-radius: 0 0 15px 15px;
  background: #fdfdfd;
}
.groups-roles .table .pagination-container .user-pagination {
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  overflow: hidden;
  padding: 16px 24px;
}
.groups-roles .table .pagination-container .user-pagination .MuiPagination-ul {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  flex-wrap: nowrap;
}
.groups-roles .table .pagination-container .user-pagination .MuiPagination-ul .Mui-disabled {
  display: none;
}
.groups-roles .table .pagination-container .user-pagination .MuiPagination-ul li .Mui-selected {
  border-radius: 7px;
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.groups-roles .table .pagination-container .user-pagination .MuiPagination-ul li:first-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.groups-roles .table .pagination-container .user-pagination .MuiPagination-ul li:first-child .MuiButtonBase-root {
  border: none;
  box-shadow: none;
  padding: 8px 14px;
  color: #0034bb;
  font-size: 14px;
  font-weight: 500;
}
.groups-roles .table .pagination-container .user-pagination .MuiPagination-ul li:first-child .MuiButtonBase-root svg {
  display: none;
}
.groups-roles .table .pagination-container .user-pagination .MuiPagination-ul li:first-child > button::after {
  margin-left: 10px;
  content: "Previous";
}
.groups-roles .table .pagination-container .user-pagination .MuiPagination-ul li:last-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.groups-roles .table .pagination-container .user-pagination .MuiPagination-ul li:last-child .MuiButtonBase-root {
  border: none;
  box-shadow: none;
  padding: 8px 14px;
  color: #0034bb;
  font-size: 14px;
  font-weight: 500;
}
.groups-roles .table .pagination-container .user-pagination .MuiPagination-ul li:last-child .MuiButtonBase-root svg {
  display: none;
}
.groups-roles .table .pagination-container .user-pagination .MuiPagination-ul li:last-child > button::before {
  margin-right: 10px;
  content: "Next";
}

.create-group .tabs {
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-group .tabs p {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
  padding: 3px 15px;
  cursor: pointer;
}
.create-group .tabs .icon {
  width: 16px;
  height: 16px;
  color: #bfbfbf;
  margin-inline: 9px;
}
.create-group .tabs .icon-before {
  color: #0034bb;
}
.create-group .tabs .active-before {
  color: #0034bb;
  font-weight: 600;
}
.create-group .tabs .active {
  border-radius: 18px;
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb !important;
  min-height: 24px;
  font-weight: 600;
}
.create-group .edit-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}
.create-group .edit-header h3 {
  margin-bottom: 3px !important;
}
.create-group .header h3 {
  margin-top: 37px;
  margin-bottom: 15px;
  color: #212121;
  font-size: 24px;
  font-weight: 600;
}
.create-group .header p {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 42px;
  margin-left: 4px;
}
.create-group .header .delete-btn {
  width: 166px;
  height: 41px;
  border-radius: 9px;
  border: 1px solid #ba0000;
  gap: 10px;
  color: #ba0000;
}
.create-group .header .delete-btn .icon {
  color: #ba0000;
  width: 24px;
  height: 24px;
}
.create-group .chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
  margin-top: 26px;
  text-transform: capitalize;
}
.create-group .chip-container-detail {
  margin-top: 0px;
  min-height: 15px;
}
.create-group .wiper-view .wiper-view-container {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 22px 28px 19px 28px;
  min-height: 48vh;
  background: #ffffff;
}
.create-group .wiper-view .wiper-view-container .row-detail {
  margin-bottom: 21px !important;
}
.create-group .wiper-view .wiper-view-container .row {
  margin-bottom: 16px;
}
.create-group .wiper-view .wiper-view-container .row p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 4px;
}
.create-group .wiper-view .wiper-view-container .row .ecn-input-description .MuiOutlinedInput-root {
  padding: 1px 1px;
}
.create-group .wiper-view .wiper-view-container .setting {
  color: #212121;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 17px;
}
.create-group .wiper-view .wiper-view-container .ecn-radio-v2 .MuiButtonBase-root {
  padding-bottom: 11px;
  padding-top: 11px;
  padding-right: 11px;
}
.create-group .create-group-container {
  overflow-x: hidden;
}
.create-group .create-group-container .swipable-view-wrapper {
  overflow-y: auto;
}

.create-group-dialog .MuiPaper-root .MuiDialogContent-root {
  padding: 20px 47px 3px 49px !important;
}
.create-group-dialog .MuiPaper-root .heading .icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  width: 24px;
  height: 24px;
  float: right;
  margin-top: 23px;
  margin-right: 10px;
}
.create-group-dialog .MuiPaper-root .heading .icon:hover {
  background-color: rgba(33, 33, 33, 0.19);
  border-radius: 50px;
}
.create-group-dialog .MuiPaper-root .success-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-group-dialog .MuiPaper-root .container {
  padding: 20px 47px 10px 49px;
}
.create-group-dialog .MuiPaper-root .container .success {
  margin: auto;
  text-align: center;
}
.create-group-dialog .MuiPaper-root .container .success img {
  max-width: 337px;
  height: auto;
}
.create-group-dialog .MuiPaper-root .container .success h3 {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 22px;
}
.create-group-dialog .MuiPaper-root .container .success .btn-contained-v2 {
  font-size: 16px;
  padding: 8px 31px;
  min-width: 225px;
  min-height: 44px;
  border-radius: 7px;
}
.create-group-dialog .MuiPaper-root .MuiDialogActions-root {
  padding-left: 49px;
  padding-right: 47px;
  padding-top: 30px;
}
.create-group-dialog .MuiPaper-root .MuiDialogActions-root .buttons {
  width: 100%;
}
.create-group-dialog .MuiPaper-root .MuiDialogActions-root .buttons .btn-outlined-v2,
.create-group-dialog .MuiPaper-root .MuiDialogActions-root .buttons .btn-contained-v2 {
  min-width: 190px;
  border-radius: 9px;
  padding: 5px 19px;
  font-size: 18px;
  min-height: 44px;
}
.create-group-dialog .MuiPaper-root .MuiDialogActions-root .buttons .last-step {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-group-dialog .MuiPaper-root .MuiDialogActions-root .buttons .right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 27px;
}

.ecn-datagrid-group .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-indeterminate {
  color: #0034bb;
  width: 40px !important;
  height: 40px !important;
}
.ecn-datagrid-group .MuiDataGrid-menuIcon {
  margin-right: 37px !important;
}
.ecn-datagrid-group .tag-chip {
  background: #f2f4f8;
  color: #212121;
  padding: 1px 1px 1px 2px;
  min-height: 20px;
}

.menu-item .menu-container {
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: capitalize;
}
.menu-item .menu-container .MuiAvatar-root {
  width: 33px;
  height: 33px;
}
.menu-item .menu-container .name {
  color: #212121;
  font-size: 14px;
  font-weight: 400;
}
.menu-item .menu-container .initials-avatar {
  background: rgba(0, 52, 187, 0.2);
  border-radius: 50px;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-item .menu-container .initials-avatar .initials {
  color: #0034bb;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

.group-menu-item .MuiPaper-root {
  width: 235px !important;
}
.group-menu-item .MuiPaper-root .MuiMenuItem-root {
  padding: 10px 10px 10px 15px;
  min-height: 45px;
  margin-bottom: unset !important;
}

.group-description-menu {
  margin-right: 54px;
}

.ecn-datagrid-role .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-indeterminate {
  color: #0034bb;
  width: 40px !important;
  height: 40px !important;
}
.ecn-datagrid-role .MuiDataGrid-menuIcon {
  margin-right: 4px !important;
}
.ecn-datagrid-role .MuiDataGrid-virtualScroller {
  overflow-y: hidden;
}
.ecn-datagrid-role .description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ecn-datagrid-role .description .markdown-data {
  max-width: 530px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #212121;
}
.ecn-datagrid-role .description .markdown-data p {
  font-size: 14px;
  font-weight: 400;
}
.ecn-datagrid-role .description .markdown-data ul,
.ecn-datagrid-role .description .markdown-data ol {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
}
.ecn-datagrid-role .description .view-menu {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.groups-roles-header .top-header {
  margin-top: 39px;
}
.groups-roles-header .top-header .top {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.groups-roles-header .top-header .top .icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.groups-roles-header .top-header .top p {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.groups-roles-header .top-header .top .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.groups-roles-header .top-header h1 {
  color: #212121;
  font-size: 24px;
  font-weight: 600;
}
.groups-roles-header .bottom-header .wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 7px;
  margin-bottom: 21px;
}
.groups-roles-header .bottom-header .wrapper .bottom-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
}
.groups-roles-header .bottom-header .wrapper .bottom-right .filter {
  padding: 7px 17px;
  border-radius: 7px;
  min-height: 44px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  gap: 12px;
  color: #212121;
  font-size: 16px;
  font-weight: 500;
}
.groups-roles-header .bottom-header .wrapper .bottom-right .filter:hover {
  background: rgba(0, 52, 187, 0.05);
}
.groups-roles-header .bottom-header .wrapper .bottom-right .btn-outlined {
  color: #0034bb;
  border-radius: 7px;
  gap: 12px;
  padding: 7px 18px;
  border: 1px solid #0034bb;
}
.groups-roles-header .bottom-header .wrapper .bottom-right .btn-outlined:hover {
  background: rgba(0, 52, 187, 0.05);
}
.groups-roles-header .bottom-header .wrapper .bottom-right .btn-contained-v2 {
  border-radius: 7px;
  gap: 12px;
  min-width: 178px;
  padding: 8px 5px;
  font-size: 16px;
}
.groups-roles-header .bottom-header .wrapper .bottom-right .btn-contained-v2 icon {
  color: #ffffff;
  width: 20px;
  font-size: unset;
}

.sort-filter-menu .MuiMenu-paper {
  padding-inline: 11px;
  overflow-y: hidden;
}
.sort-filter-menu .MuiMenu-paper .MuiList-root {
  padding-bottom: 14px;
  padding-top: 14px;
}
.sort-filter-menu .MuiMenu-paper .MuiList-root .MuiButtonBase-root {
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 45px;
  margin-bottom: 3px;
}
.sort-filter-menu .MuiMenu-paper .MuiList-root .MuiButtonBase-root:hover {
  background: rgba(0, 52, 187, 0.05);
}
.sort-filter-menu .MuiMenu-paper .MuiList-root .MuiButtonBase-root:hover .MuiTypography-root {
  color: #0034bb;
}
.sort-filter-menu .MuiMenu-paper .MuiList-root .MuiButtonBase-root .MuiTypography-root {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
  opacity: unset;
}
.sort-filter-menu .MuiMenu-paper .MuiList-root .Mui-selected {
  background-color: rgba(0, 52, 187, 0.05) !important;
}
.sort-filter-menu .MuiMenu-paper .MuiList-root .Mui-selected:hover {
  background-color: rgba(0, 52, 187, 0.05) !important;
}
.sort-filter-menu .MuiMenu-paper .MuiList-root .Mui-selected .MuiTypography-root {
  color: #0034bb;
  font-weight: 600;
}

.roles-detail-dialog .MuiPaper-root .MuiDialogContent-root {
  padding: 5px 47px 10px 49px !important;
}

.group-roles-details-dialog .MuiPaper-root .MuiDialogContent-root {
  padding: 20px 47px 10px 49px;
}
.group-roles-details-dialog .MuiPaper-root .heading .icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  width: 24px;
  height: 24px;
  float: right;
  margin-top: 23px;
  margin-right: 10px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .btn-outlined-v2 {
  font-weight: 600;
  gap: 8px;
  border-radius: 9px;
  padding: 6px 19px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .btn-outlined-v2 .icon {
  color: #0034bb;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .top-header .left {
  display: flex;
  gap: 23px;
  align-items: center;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .top-header .left .details-avatar .MuiAvatar-root {
  border: 3px solid #fff;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .top-header .left .details-avatar .MuiAvatar-root:first-child {
  margin-left: -35px !important;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .top-header .left .details-avatar .MuiAvatar-root:nth-child(2) {
  margin-left: -35px !important;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .top-header .left .details-avatar .MuiAvatar-root:last-child {
  margin-left: unset !important;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .top-header .left h3 {
  color: #212121;
  font-size: 18px;
  font-weight: 600;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .top-header .left p {
  color: #212121;
  font-size: 16px;
  font-weight: 400;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .top-header .btn-outlined-v2 {
  font-size: 18px;
  padding: 5px 18px;
  min-height: 44px;
  min-width: 100px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .top-header .btn-outlined-v2 .icon {
  color: #0034bb;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 41px;
  gap: 10px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .wrapper .active {
  box-shadow: 0px 1px #ffffff;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .wrapper .active h3 {
  color: #0034bb !important;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .wrapper .active:hover {
  box-shadow: 0px 1px #f2f5fc;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .wrapper .tab {
  cursor: pointer;
  border-radius: 15px 15px 0px 0px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-bottom: none;
  background: #ffffff;
  display: flex;
  justify-content: center;
  min-width: 143px;
  padding: 10px 23px;
  align-items: center;
  gap: 8px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .wrapper .tab:hover {
  background: rgba(0, 52, 187, 0.05);
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .wrapper .tab h3 {
  color: #212121;
  font-size: 18px;
  font-weight: 500;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .header .wrapper .tab p {
  color: #212121;
  font-size: 12px;
  font-weight: 400;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container {
  margin-bottom: 80px;
  border: 1px solid rgba(33, 33, 33, 0.07);
  border-radius: 0 17px 17px 17px;
  min-height: 50vh;
  padding: 35px 39px 35px 36px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .markdown-data-general {
  max-width: 631px;
  color: #212121;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .markdown-data-general p {
  font-size: 14px;
  font-weight: 400;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .markdown-data-general ul,
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .markdown-data-general ol {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .markdown-data {
  color: #212121;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .markdown-data p {
  font-size: 14px;
  font-weight: 500 !important;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .markdown-data ul,
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .markdown-data ol {
  margin-left: 20px;
  font-size: 12px;
  font-weight: 400;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .settings {
  color: #212121;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .settings h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .settings p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 11px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .membership {
  position: relative;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .membership .row-member {
  display: flex;
  gap: 10px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .membership .row-member .name {
  min-width: 40%;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .membership .row-member .name .membership-container {
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: capitalize;
  margin-top: 14px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .membership .row-member .name .membership-container .MuiAvatar-root {
  width: 33px;
  height: 33px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .membership .row-member .name .membership-container .member-name {
  color: #212121;
  font-size: 14px;
  font-weight: 400;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .membership .row-member .name .membership-container .initials-avatar {
  background: rgba(0, 52, 187, 0.2);
  border-radius: 50px;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .membership .row-member .name .membership-container .initials-avatar .initials {
  color: #0034bb;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .membership .row-member .name .assigned-title {
  font-size: 18px;
  font-weight: 500;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .membership .row-member .name .title {
  color: #212121;
  font-size: 16px;
  font-weight: 400;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .membership .row-member .email .member-email {
  margin-top: 22px;
  font-size: 14px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .membership .search-member {
  position: absolute;
  top: -5px;
  right: 0;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .row {
  display: flex;
  justify-content: space-between;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .row .name .membership-container {
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: capitalize;
  margin-top: 14px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .row .name .membership-container .MuiAvatar-root {
  width: 33px;
  height: 33px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .row .name .membership-container .member-name {
  color: #212121;
  font-size: 14px;
  font-weight: 400;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .row .name .membership-container .initials-avatar {
  background: rgba(0, 52, 187, 0.2);
  border-radius: 50px;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .row .name .membership-container .initials-avatar .initials {
  color: #0034bb;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .row .name .assigned-title {
  font-size: 18px;
  font-weight: 500;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .row .name .title {
  color: #212121;
  font-size: 16px;
  font-weight: 400;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .row .member-email {
  margin-top: 22px;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .general {
  color: #212121;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .general h3 {
  font-size: 18px;
  font-weight: 500;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .general p {
  font-size: 14px;
  font-weight: 400;
}
.group-roles-details-dialog .MuiPaper-root .group-roles-details .details-container .general .other-info {
  margin-top: 28px;
}

.inviteUser {
  height: 40vh;
}
.inviteUser .stepper {
  margin-bottom: 20px;
  margin-top: 30px;
  margin-left: -11vw;
}
.inviteUser .stepper .MuiStepper-root .MuiStep-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label {
  margin-top: 1px;
  font-size: 16px;
  font-weight: 500;
  color: #374151;
}
.inviteUser .select-product {
  width: 40%;
}
.inviteUser .select-product .MuiOutlinedInput-root {
  height: unset;
  padding: 1px 10px;
}
.inviteUser .user-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 65vh;
  margin-top: 20px;
  margin-bottom: 0px;
  position: relative;
}
.inviteUser .user-detail .top {
  max-height: 64vh;
  overflow-y: scroll;
}
.inviteUser .user-detail .top .title {
  font-size: 18px;
  font-weight: 600;
  color: #374151;
}
.inviteUser .user-detail .top .users-list {
  border-bottom: 1px solid #d1d5db;
  padding-bottom: 20px;
}
.inviteUser .user-detail .top .users-list h2 {
  font-size: 24px;
  font-weight: 600;
  color: #374151;
}
.inviteUser .user-detail .top .users-list .row {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.inviteUser .user-detail .top .users-list .row .col {
  width: 32.5%;
  height: 70px;
}
.inviteUser .user-detail .top .users-list .row .col .designation {
  display: flex;
  gap: 26px;
  align-items: center;
}
.inviteUser .user-detail .top .users-list .row .col .designation .delete-icon {
  margin-top: 42px;
  color: rgba(33, 33, 33, 0.7);
  cursor: pointer;
}
.inviteUser .user-detail .top .users-list .row .col p {
  font-size: 14px;
  font-weight: 400;
  color: #374151;
  margin-bottom: 5px;
}
.inviteUser .user-detail .top .users-list .row .col .auto-box {
  width: 100%;
}
.inviteUser .user-detail .top .users-list .row .col .auto-box .MuiOutlinedInput-root {
  height: unset;
  padding: 2px 10px;
}
.inviteUser .user-detail .top .users-list .row .col .error {
  font-size: 14px;
  font-weight: 400;
  color: rgb(186, 0, 0);
  margin-top: 5px;
}
.inviteUser .user-detail .top .users-list .button {
  text-align: right;
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: right;
}
.inviteUser .user-detail .top .users-list .button .btn-outlined {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  width: 200px;
  padding: 8px 14px;
}
.inviteUser .user-detail .top .users-list .button .btn-outlined:hover {
  background: none;
}
.inviteUser .user-detail .top .import {
  margin-top: 30px;
  text-align: center;
}
.inviteUser .user-detail .top .import h2 {
  font-size: 18px;
  font-weight: 600;
  color: #374151;
}
.inviteUser .user-detail .top .import p {
  font-size: 16px;
  font-weight: 400;
  color: #6b7280;
  width: 50%;
  margin: 15px auto;
}
.inviteUser .user-detail .top .import .input-name {
  border: 1px solid #d1d5db;
  width: 17%;
  margin: auto;
  border-radius: 8px;
  padding: 20px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}
.inviteUser .user-detail .top .import .input-name .input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inviteUser .user-detail .top .import .input-name .input input[type=file] {
  display: none;
}
.inviteUser .user-detail .top .import .input-name .input .custom-file-upload {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #374151;
}
.inviteUser .user-detail .top .import .input-name .input .replace {
  width: 100%;
  margin: 8px 0px;
  color: #0034bb;
  text-align: center;
  border: 1px solid #0034bb;
  border-radius: 8px;
  padding: 7px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}
.inviteUser .user-detail .top .import .input-name h3 {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: auto;
  margin-top: 5px;
  color: #374151;
  font-weight: 600;
}
.inviteUser .user-detail .bottom {
  position: absolute;
  right: 10px;
  bottom: 20px;
}
.inviteUser .user-detail .bottom .btn-contained {
  min-width: 150px;
}
.inviteUser .user-detail .bottom .btn-contained:hover {
  background: #004fd8;
}
.inviteUser .role {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 57vh;
}
.inviteUser .role .MuiPaper-root {
  border-left: none !important;
  border-right: none !important;
}
.inviteUser .role .MuiPaper-root .MuiTableCell-root {
  border-right: 0px !important;
}
.inviteUser .role .MuiPaper-root .MuiTableRow-root {
  height: 40px !important;
}
.inviteUser .role .MuiPaper-root .MuiTableCell-root {
  padding: 11px !important;
}
.inviteUser .role .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}
.inviteUser .role .bottom .left {
  display: flex;
  align-items: center;
  gap: 80px;
}
.inviteUser .role .bottom .btn-outlined,
.inviteUser .role .bottom .btn-contained {
  min-width: 150px;
}
.inviteUser .role .bottom .btn-contained:hover {
  background: #004fd8;
}
.inviteUser .preview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 62vh;
}
.inviteUser .preview .top .review-title {
  font-size: 18px;
  font-weight: 600;
  color: #374151;
  margin-bottom: 10px;
  margin-top: 15px;
}
.inviteUser .preview .top .para {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 20px;
}
.inviteUser .preview .top .preview-table .table-heading {
  border-top: 1px solid rgba(33, 33, 33, 0.1);
  background: rgba(33, 33, 33, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 19px;
}
.inviteUser .preview .top .preview-table .table-heading .table-heading-left {
  display: flex;
  align-items: center;
  width: 50%;
}
.inviteUser .preview .top .preview-table .table-heading .table-heading-left p {
  font-size: 14px;
  font-weight: 500;
  color: #121619;
}
.inviteUser .preview .top .preview-table .table-heading .table-heading-right {
  width: 50%;
}
.inviteUser .preview .top .preview-table .table-heading .table-heading-right p {
  font-size: 14px;
  font-weight: 500;
  color: #121619;
}
.inviteUser .preview .top .list-user .user {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 8px 0px;
}
.inviteUser .preview .top .list-user .user .user-left {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 0px;
}
.inviteUser .preview .top .list-user .user .user-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
.inviteUser .preview .top .list-user .user .user-right h1 {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.inviteUser .preview .top .list-user .user .user-right .icons {
  padding-right: 10px;
}
.inviteUser .preview .top .list-user .user .user-right .icons .edit {
  margin-right: 10px;
  cursor: pointer;
}
.inviteUser .preview .top .list-user .user .user-right .icons .delete {
  cursor: pointer;
}
.inviteUser .preview .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}
.inviteUser .preview .bottom .left {
  display: flex;
  align-items: center;
  gap: 80px;
}
.inviteUser .preview .bottom .btn-outlined,
.inviteUser .preview .bottom .btn-contained {
  min-width: 150px;
}

.maping-table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 40vh;
  padding-bottom: 30px;
}
.maping-table .MuiInputBase-root {
  border-radius: 9px !important;
}
.maping-table .container {
  padding: 30px;
}
.maping-table .container .title {
  font-size: 22px;
  font-weight: 600;
}
.maping-table .container .req {
  font-size: 16px;
  font-weight: 400;
}
.maping-table .container .field {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.maping-table .container .detected {
  font-size: 14px;
  font-weight: 600;
  margin: 20px 10px;
  margin-bottom: 10px;
  margin-left: 0px;
}
.maping-table .container .error {
  background: rgba(186, 0, 0, 0.08);
  border: 0.5px solid rgba(186, 0, 0, 0.7);
  border-radius: 9px;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.maping-table .container .error .missing {
  font-size: 16px;
  font-weight: 600;
}
.maping-table .container .error .missing span {
  color: #ba0000;
  margin-right: 5px;
}
.maping-table .container .error .replace {
  font-size: 16px;
  font-weight: 600;
  color: #004fd8;
  cursor: pointer;
}
.maping-table .error {
  font-size: 14px;
  font-weight: 400;
  color: rgb(186, 0, 0);
  margin-top: 5px;
}
.maping-table .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0px 30px;
}
.maping-table .bottom .left {
  display: flex;
  align-items: center;
  gap: 80px;
}
.maping-table .bottom .btn-outlined,
.maping-table .bottom .btn-contained {
  min-width: 150px;
}

.signIn .container {
  display: flex;
  padding: 20px;
  height: 100vh;
}
.signIn .container .wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 100px;
  padding-right: 80px;
}
.signIn .container .wrapper .main_logo {
  height: auto;
  max-width: 240px;
}
.signIn .container .wrapper .login-detail {
  margin: auto;
}
.signIn .container .wrapper .login-detail h1 {
  font-size: 44px;
  font-weight: 700;
  color: #000000;
}
.signIn .container .wrapper .login-detail .sub-title {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin: 10px 0px;
  margin-bottom: 20px;
}
.signIn .container .wrapper .login-detail .input-text {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}
.signIn .container .wrapper .login-detail .input-text p {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 10px;
}
.signIn .container .wrapper .login-detail .input-text a {
  width: fit-content;
  margin-right: 0;
  margin-left: auto;
  text-decoration: none;
  color: #0034bb;
  font-weight: 600;
  font-size: 14px;
}
.signIn .container .wrapper .login-detail .input-text a:hover {
  text-decoration: underline;
}
.signIn .container .wrapper .login-detail .input-text .outline-input {
  border-radius: 8px;
  margin-bottom: 5px;
  height: 40px;
  width: 100%;
}
.signIn .container .wrapper .login-detail .input-text .outline-input .MuiInputBase-input {
  padding: 10px 16px;
}
.signIn .container .wrapper .login-detail .input-text .outline-input .MuiInputBase-input::placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 500;
}
.signIn .container .wrapper .login-detail .btn-contained {
  min-width: 180px;
}
.signIn .container .wrapper .login-detail .helper-link {
  margin-top: 26px;
}
.signIn .container .wrapper .login-detail .helper-link a {
  font-weight: 600;
  text-decoration: none;
  color: #0034bb;
}
.signIn .container .wrapper .login-detail .helper-link a:hover {
  text-decoration: underline;
}
.signIn .container .right {
  background: #000000;
  overflow-x: hidden;
  width: 50%;
}
.signIn .container .right img {
  height: 88%;
  margin-top: 5%;
  margin-left: 10%;
  border-radius: 20px;
}

.licenses {
  padding: 12px;
}
.licenses .complete-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  border-radius: 8px;
  background: rgba(127, 0, 187, 0.2);
  margin: 30px 0px;
  margin-top: 10px;
}
.licenses .complete-details p {
  font-size: 16px;
  font-weight: 500;
  color: #0034bb;
}
.licenses .complete-details .btn-contained {
  padding: 10px 30px;
}
.licenses .payment-due {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  border-radius: 8px;
  background: rgba(255, 0, 0, 0.2);
  margin: 30px 0px;
  margin-top: 10px;
}
.licenses .payment-due p {
  font-size: 30px;
  font-weight: 600;
  color: #ba0000;
}
.licenses .payment-due .btn-contained {
  min-width: 250px;
  background: #000000;
  color: #ffffff;
}
.licenses .title-home {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.licenses .title-home .title {
  font-size: 40px;
  font-weight: 600;
  color: #101828;
}
.licenses .title-home .buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}
.licenses .title-home .buttons .btn-outlined {
  padding: 8px 50px;
}
.licenses .title-home .buttons .btn-black-outlined {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.licenses .grid {
  display: flex;
  align-items: center;
  gap: 2%;
  flex-wrap: wrap;
}
.licenses .grid .card {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 48%;
  min-height: 270px;
  display: flex;
  padding-right: 0px;
  padding: 10px 0px;
  padding-left: 15px;
}
.licenses .grid .card .left {
  width: 60%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.licenses .grid .card .left h2 {
  font-size: 24px;
  font-weight: 700;
}
.licenses .grid .card .left .all-licenses {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0px;
  padding-bottom: 20px;
}
.licenses .grid .card .left .all-licenses .no-licenses {
  display: flex;
  align-items: center;
  gap: 10px;
}
.licenses .grid .card .left .all-licenses .no-licenses p {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 52, 187, 0.7);
}
.licenses .grid .card .left .buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}
.licenses .grid .card .left .buttons button {
  outline: none;
  border: none;
  background: #212121;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 24px;
  min-width: 170px;
  border-radius: 8px;
  cursor: pointer;
}
.licenses .grid .card .left .buttons .assigning {
  background: transparent;
  border: 1px solid #212121;
  color: #212121;
}
.licenses .grid .card .right {
  width: 40%;
  height: 210px;
}
.licenses .grid .card .right img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.license-history .title {
  font-size: 35px;
  font-weight: 600;
  color: #101828;
  display: flex;
  align-items: center;
  gap: 10px;
}
.license-history .title span {
  display: flex;
  align-items: center;
  color: #9ca3af;
  cursor: pointer;
}
.license-history .body {
  margin-top: 20px;
  position: relative;
}
.license-history .body .table-container {
  border: 1px solid #eaecf0;
  height: 66vh;
  border-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 0px;
}
.license-history .body .table-container .head {
  background: #f9fafb;
  width: 100%;
}
.license-history .body .table-container .head .head-cell {
  font-size: 12px;
  font-weight: 500;
  color: #667085;
}
.license-history .body .table-container .head .head-cell:nth-child(1) {
  width: 200px !important;
}
.license-history .body .table-container .head .head-cell:nth-child(2) {
  width: 150px !important;
}
.license-history .body .table-container .head .head-cell:nth-child(3) {
  width: 170px !important;
}
.license-history .body .table-container .head .head-cell:nth-child(4) {
  width: 140px !important;
}
.license-history .body .table-container .head .head-cell:nth-child(5) {
  width: 120px !important;
}
.license-history .body .table-container .row {
  height: 70px;
}
.license-history .body .table-container .row-cell:first-child {
  font-size: 14px;
  font-weight: 400;
  color: #101828;
}
.license-history .body .table-container .row-cell:last-child {
  font-size: 14px;
  font-weight: 400;
}
.license-history .body .table-container .row-cell {
  font-size: 12px;
  font-weight: 500;
  color: #667085;
}
.license-history .body .table-container .image {
  text-align: center;
  width: 100%;
  margin-top: 40px;
}
.license-history .body .table-container .image h2 {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}
.license-history .body .bottom {
  border: 1px solid #eaecf0;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 20px;
}
.license-history .body .bottom .MuiPagination-root ul li .Mui-selected {
  background: rgba(127, 86, 217, 0.1);
  color: #0034bb;
}
.license-history .body .bottom .MuiPagination-root ul li:first-child {
  position: absolute;
  left: 30px;
  text-align: center;
}
.license-history .body .bottom .MuiPagination-root ul li:first-child .MuiSvgIcon-root {
  display: none;
}
.license-history .body .bottom .MuiPagination-root ul li:first-child .MuiButtonBase-root {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding-right: 0px;
  padding-left: 10px;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 18px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.license-history .body .bottom .MuiPagination-root ul li:first-child .MuiButtonBase-root::after {
  background: url("https://api.iconify.design/mdi/arrow-up-thin.svg?width=32&height=32&rotate=270deg") no-repeat center center/contain;
  background-repeat: no-repeat;
  background-position: -5px;
  content: "Previous";
  cursor: pointer;
  width: 90px;
}
.license-history .body .bottom .MuiPagination-root ul li:last-child {
  position: absolute;
  right: 30px;
}
.license-history .body .bottom .MuiPagination-root ul li:last-child .MuiSvgIcon-root {
  display: none;
}
.license-history .body .bottom .MuiPagination-root ul li:last-child .MuiButtonBase-root {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 18px;
  padding-right: 10px;
  padding-left: 0px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.license-history .body .bottom .MuiPagination-root ul li:last-child .MuiButtonBase-root::after {
  background: url("https://api.iconify.design/mdi/arrow-up-thin.svg?width=40&height=40&rotate=270deg&flip=horizontal%2Cvertical") no-repeat center center/contain;
  background-repeat: no-repeat;
  background-position: 55px;
  content: " Next";
  cursor: pointer;
  width: 75px;
}
.license-history .loading {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-top: 40vh;
}

.setting .title {
  font-size: 30px;
  font-weight: 500;
  color: #101828;
  margin-bottom: 20px;
}
.setting .body {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}
.setting .body .left {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px 10px;
  border-radius: 6px;
  width: 25%;
}
.setting .body .left .left-side-bar ul {
  list-style: none;
}
.setting .body .left .left-side-bar ul .li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 17px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  margin-bottom: 15px;
  padding: 9px 10px;
  height: 48px;
  cursor: pointer;
}
.setting .body .left .left-side-bar ul .li:last-child {
  margin-bottom: 0px;
}
.setting .body .left .left-side-bar ul .li:hover {
  border-radius: 6px;
  background: rgba(209, 213, 219, 0.5);
}
.setting .body .left .left-side-bar ul .li .active {
  border-radius: 6px;
  background: rgba(127, 0, 187, 0.14);
  color: #7f00bb;
}
.setting .body .left .left-side-bar ul .active {
  border-radius: 6px;
  background: rgba(127, 0, 187, 0.14);
  color: #7f00bb;
}
.setting .body .right {
  width: 75%;
}
.setting .body .right .setting-notification,
.setting .body .right .security {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  min-height: 500px;
  padding: 20px 25px;
}
.setting .body .right .setting-notification h1,
.setting .body .right .security h1 {
  font-size: 25px;
  font-weight: 600;
  color: #101828;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}
.setting .body .right .setting-notification .list,
.setting .body .right .security .list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}
.setting .body .right .setting-notification .list:last-child,
.setting .body .right .security .list:last-child {
  border-bottom: none;
}
.setting .body .right .setting-notification .list h4,
.setting .body .right .security .list h4 {
  font-size: 20px;
  font-weight: 500;
  color: #101828;
}
.setting .body .right .reset-password {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  min-height: 500px;
  padding: 20px 25px;
}
.setting .body .right .reset-password h1 {
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: 600;
  color: #101828;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
  cursor: pointer;
}
.setting .body .right .reset-password h1 span {
  display: flex;
  align-items: center;
  color: #9ca3af;
  margin-right: 5px;
}
.setting .body .right .reset-password .field {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}
.setting .body .right .reset-password .field label {
  font-size: 16px;
  font-weight: 600;
  color: #555555;
  margin-bottom: 5px;
}
.setting .body .right .reset-password .field .MuiOutlinedInput-root {
  border-radius: 8px !important;
}
.setting .body .right .reset-password .btn-contained {
  margin-top: 30px;
  min-width: 200px;
}
.setting .body .right .verify-email {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  min-height: 500px;
  padding: 20px 25px;
}
.setting .body .right .verify-email h1 {
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: 600;
  color: #101828;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
  cursor: pointer;
}
.setting .body .right .verify-email .otp .title {
  font-size: 20px;
  font-weight: 500;
  color: #101828;
  margin: 20px 0px;
}
.setting .body .right .verify-email .otp .label {
  font-size: 16px;
  font-weight: 600;
  color: #555555;
  margin-bottom: 10px;
}
.setting .body .right .verify-email .otp .resend {
  font-size: 14px;
  font-weight: 500;
  color: #101828;
  margin-top: 7px;
}
.setting .body .right .verify-email .btn-contained {
  margin-top: 20vh;
  min-width: 200px;
}
.setting .body .right .my-activity {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  min-height: 500px;
  padding: 20px 25px;
}
.setting .body .right .my-activity .title {
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: 600;
  color: #101828;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}
.setting .body .right .my-activity .my-activity-container-list {
  height: 78vh;
  overflow-y: scroll;
}
.setting .body .right .my-activity .my-activity-container-list .my-activity-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 15px;
  margin-top: 15px;
  cursor: pointer;
}
.setting .body .right .my-activity .my-activity-container-list .my-activity-list:last-child {
  border-bottom: none;
}
.setting .body .right .my-activity .my-activity-container-list .my-activity-list .my-activity-container p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(16, 24, 40, 0.5);
}
.setting .body .right .my-activity .my-activity-container-list .my-activity-list .my-activity-container h1 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(16, 24, 40);
}
.setting .body .right .my-activity .my-activity-container-list .selected-activity {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  margin-top: 20px;
}
.setting .body .right .my-activity .my-activity-container-list .selected-activity .top {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}
.setting .body .right .my-activity .my-activity-container-list .selected-activity .top p {
  font-size: 14px;
  font-weight: 600;
  color: rgba(16, 24, 40, 0.5);
}
.setting .body .right .my-activity .my-activity-container-list .selected-activity .top h3 {
  font-size: 20px;
  font-weight: 600;
  color: rgb(16, 24, 40);
  margin: 10px 0px;
}
.setting .body .right .my-activity .my-activity-container-list .selected-activity .top .browser-name {
  display: flex;
  align-items: center;
  gap: 10px;
}
.setting .body .right .my-activity .my-activity-container-list .selected-activity .top .browser-name p {
  font-weight: 400;
}
.setting .body .right .my-activity .my-activity-container-list .selected-activity .top .browser-name h4 {
  font-size: 14px;
  font-weight: 600;
  color: #101828;
}
.setting .body .right .my-activity .my-activity-container-list .selected-activity .bottom .ip {
  margin-top: 20px;
}
.setting .body .right .my-activity .my-activity-container-list .selected-activity .bottom .ip p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(16, 24, 40, 0.5);
}
.setting .body .right .my-activity .my-activity-container-list .selected-activity .bottom .ip h4 {
  font-size: 14px;
  font-weight: 600;
  color: #101828;
}
.setting .body .right .my-activity .my-activity-container-list .selected-activity .bottom .ip h5 {
  font-size: 14px;
  font-weight: 600;
  color: rgba(16, 24, 40, 0.6);
}
.setting .body .right .help {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  min-height: 500px;
  padding: 20px 25px;
}
.setting .body .right .help h1 {
  font-size: 25px;
  font-weight: 600;
  color: #101828;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}
.setting .body .right .help .list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  cursor: pointer;
}
.setting .body .right .help .list h4 {
  font-size: 20px;
  font-weight: 500;
  color: #101828;
}
.setting .body .right .help .text-fields .row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}
.setting .body .right .help .text-fields .row .field {
  width: 50%;
}
.setting .body .right .help .text-fields .row .field p {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin: 5px 0px;
}
.setting .body .right .help .text-fields .row .field .MuiOutlinedInput-root {
  border-radius: 8px;
}
.setting .body .right .help .text-fields .row .area-field {
  width: 100%;
}
.setting .body .right .help .text-fields .row .area-field p {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin: 5px 0px;
}
.setting .body .right .help .text-fields .row .area-field .MuiOutlinedInput-root {
  border-radius: 8px;
}
.setting .body .right .help .text-fields .btn-contained {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  margin: auto;
  margin-top: 40px;
}
.setting .body .right .billing-details {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  min-height: 500px;
  padding: 20px 25px;
  height: 90vh;
  overflow-y: scroll;
}
.setting .body .right .billing-details .tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.setting .body .right .billing-details .tabs .MuiTabs-scroller .MuiTabs-flexContainer {
  gap: 60px;
}
.setting .body .right .billing-details .tabs .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root {
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  color: #9ca3af;
  cursor: pointer;
}
.setting .body .right .billing-details .tabs .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
  color: #101828;
}
.setting .body .right .billing-details .wiper-view {
  width: 60vw;
}
.setting .body .right .billing-details .wiper-view .fall-back {
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 50vw;
  margin: auto;
  margin-top: 50px;
  border-radius: 14px;
  padding: 40px;
}
.setting .body .right .billing-details .wiper-view .fall-back img {
  max-width: 450px;
}
.setting .body .right .billing-details .wiper-view .fall-back .fall-back-message {
  font-size: 30px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  margin-top: 20px;
}
.setting .body .right .billing-details .wiper-view .invoice-table {
  width: 100%;
  overflow: hidden;
}
.setting .body .right .billing-details .wiper-view .invoice-table .table-container {
  border: 1px solid #eaecf0;
  height: 60vh;
  border-radius: 8px;
  margin-top: 50px;
}
.setting .body .right .billing-details .wiper-view .invoice-table .table-container .head {
  background: #f9fafb;
}
.setting .body .right .billing-details .wiper-view .invoice-table .table-container .head .head-cell {
  font-size: 12px;
  font-weight: 500;
  color: #667085;
}
.setting .body .right .billing-details .wiper-view .invoice-table .table-container .head .head-cell:nth-child(1) {
  width: 160px !important;
}
.setting .body .right .billing-details .wiper-view .invoice-table .table-container .head .head-cell:nth-child(2) {
  width: 100px !important;
}
.setting .body .right .billing-details .wiper-view .invoice-table .table-container .head .head-cell:nth-child(3) {
  width: 140px !important;
}
.setting .body .right .billing-details .wiper-view .invoice-table .table-container .head .head-cell:nth-child(4) {
  width: 60px !important;
}
.setting .body .right .billing-details .wiper-view .invoice-table .table-container .head .head-cell:nth-child(5) {
  width: 140px !important;
}
.setting .body .right .billing-details .wiper-view .invoice-table .table-container .head .head-cell:nth-child(6) {
  width: 130px !important;
}
.setting .body .right .billing-details .wiper-view .invoice-table .table-container .row {
  height: 70px;
}
.setting .body .right .billing-details .wiper-view .invoice-table .table-container .row-cell:first-child {
  font-size: 14px;
  font-weight: 400;
  color: #101828;
}
.setting .body .right .billing-details .wiper-view .invoice-table .table-container .row-cell {
  font-size: 12px;
  font-weight: 500;
  color: #667085;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .payment-card {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  width: 300px;
  min-height: 250px;
  padding: 15px;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .payment-card .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 45px;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .payment-card .top h1 {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  text-transform: uppercase;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .payment-card .card-number {
  font-weight: 500;
  font-size: 25px;
  color: #000000;
  margin: 20px 0px;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .payment-card .card-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .payment-card .card-holder .name p {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .payment-card .card-holder .name h2 {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .payment-card .card-holder .expiry p {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .payment-card .card-holder .expiry h2 {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .payment-card .default {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  padding-top: 10px;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .payment-card .default p {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .add-payment {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  width: 300px;
  min-height: 250px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  cursor: pointer;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .add-payment .center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .add-payment .center .icon {
  width: 40px;
  height: 40px;
  color: #0034bb;
}
.setting .body .right .billing-details .wiper-view .payment-method .payment-grid .add-payment .center p {
  color: #0034bb;
  font-size: 16px;
  font-weight: 500;
}
.setting .body .right .billing-details .wiper-view .basic-billing-details {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}
.setting .body .right .billing-details .wiper-view .basic-billing-details h3 {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #313131;
  gap: 5px;
  margin: 20px 0px;
}
.setting .body .right .billing-details .wiper-view .basic-billing-details .basic-billing-details-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.setting .body .right .billing-details .wiper-view .basic-billing-details .basic-billing-details-top Button {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
}
.setting .body .right .billing-details .wiper-view .basic-billing-details .basic-billing-details-top h3 {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #313131;
  gap: 5px;
  margin: 20px 0px;
}
.setting .body .right .billing-details .wiper-view .basic-billing-details .billing-row {
  margin-bottom: 10px;
  text-align: left;
}
.setting .body .right .billing-details .wiper-view .basic-billing-details .billing-row p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}
.setting .body .right .billing-details .wiper-view .basic-billing-details .billing-row .text-box {
  outline: none;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 400;
  width: 100%;
}
.setting .body .right .billing-details .wiper-view .basic-billing-details .billing-row ::placeholder {
  color: #9ca3af;
  font-size: 14px;
}
.setting .body .right .billing-details .wiper-view .basic-billing-details .bottom-button {
  width: 100%;
  padding: 30px 0px;
}
.setting .body .right .billing-details .wiper-view .basic-billing-details .bottom-button .bottom-button-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.setting .body .right .billing-details .wiper-view .basic-billing-details .bottom-button .bottom-button-right .btn-contained {
  width: 200px;
}
.setting .body .right .billing-details .wiper-view .basic-billing-details .bottom-button .bottom-button-right .btn-outlined {
  width: 150px;
}

.password-request h1 {
  font-size: 30px;
  font-weight: 500;
  color: #101828;
}
.password-request .request-grid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 30px;
}
.password-request .request-grid .no-request {
  width: 100%;
  text-align: center;
}
.password-request .request-grid .no-request .no-request-container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 60vw;
  margin: auto;
  border-radius: 14px;
  padding: 30px;
  margin-top: 50px;
}
.password-request .request-grid .no-request .no-request-container img {
  max-width: 450px;
}
.password-request .request-grid .no-request .no-request-container .message {
  font-size: 30px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  margin-top: 10px;
}
.password-request .request-grid .request-card {
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 300px;
}
.password-request .request-grid .request-card .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.password-request .request-grid .request-card .top p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(16, 24, 40, 0.6);
}
.password-request .request-grid .request-card .user {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.password-request .request-grid .request-card .user h4 {
  font-size: 16px;
  font-weight: 700;
  color: #101828;
}
.password-request .request-grid .request-card .user p {
  font-size: 12px;
  font-weight: 500;
  color: #667085;
  margin-top: -3px;
}
.password-request .request-grid .request-card .message {
  margin: 10px 0px;
  font-size: 16px;
  font-weight: 500;
  color: #101828;
}
.password-request .request-grid .request-card .buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}
.password-request .request-grid .request-card .buttons button {
  outline: none;
  border-radius: 8px;
  border: 1px solid rgba(16, 24, 40, 0.2);
  background: transparent;
  padding: 10px 35px;
  cursor: pointer;
}
.password-request .request-grid .request-card .buttons .approve {
  background: #0034bb;
  color: #ffffff;
}
.password-request .request-grid .request-card .buttons .declined {
  border-radius: 8px;
  border: 1px solid rgba(16, 24, 40, 0.2);
  background: rgba(16, 24, 40, 0.05);
  width: 100%;
  cursor: auto;
}
.password-request .request-grid .request-card .buttons .accepted {
  border-radius: 8px;
  border: 1px solid #7f00bb;
  background: rgba(127, 0, 187, 0.05);
  width: 100%;
  cursor: auto;
}
.password-request .request-grid .request-card .buttons .expired {
  border-radius: 8px;
  border: 1px solid #ba0000;
  cursor: auto;
  width: 100%;
}

.forgot-password .container {
  display: flex;
  padding: 24px;
  height: 100vh;
}
.forgot-password .container .left {
  flex: 0.44;
  width: inherit;
  max-width: 44%;
}
.forgot-password .container .left .wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 15%;
  height: 100%;
}
.forgot-password .container .left .wrapper .elevate-logo {
  height: auto;
  max-width: 140px;
}
.forgot-password .container .left .wrapper .content-wrapper {
  padding-right: 10%;
  margin: auto;
  width: 100%;
}
.forgot-password .container .left .wrapper .content-wrapper .back {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 5px;
  font-size: 24px;
  font-weight: 500;
  color: #212121;
  cursor: pointer;
}
.forgot-password .container .left .wrapper .content-wrapper h2 {
  font-size: 36px;
}
.forgot-password .container .left .wrapper .content-wrapper .sub-text {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin: 10px 0px;
  margin-bottom: 20px;
}
.forgot-password .container .left .wrapper .content-wrapper .field {
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;
}
.forgot-password .container .left .wrapper .content-wrapper .field p {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 10px;
}
.forgot-password .container .left .wrapper .content-wrapper .btn-contained {
  padding: 8px 60px;
}
.forgot-password .container .left .wrapper .content-wrapper .helper-link {
  color: #212121;
  font-weight: 500;
  text-decoration: none;
  font-size: 16px;
}
.forgot-password .container .right {
  flex: 0.56;
  background: #000000;
  overflow-x: hidden;
}
.forgot-password .container .right img {
  height: 88%;
  margin-top: 5%;
  margin-left: 10%;
  border-radius: 20px;
}

.reset-password .container {
  display: flex;
  padding: 24px;
  height: 100vh;
}
.reset-password .container .left {
  flex: 0.44;
  width: inherit;
  max-width: 44%;
}
.reset-password .container .left .wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 15%;
  height: 100%;
}
.reset-password .container .left .wrapper .elevate-logo {
  height: auto;
  max-width: 140px;
}
.reset-password .container .left .wrapper .content-wrapper {
  padding-right: 10%;
  margin: auto;
  width: 100%;
}
.reset-password .container .left .wrapper .content-wrapper h2 {
  font-size: 36px;
  margin-bottom: 27px;
}
.reset-password .container .left .wrapper .content-wrapper .field {
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;
}
.reset-password .container .left .wrapper .content-wrapper .field p {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 10px;
}
.reset-password .container .left .wrapper .content-wrapper .btn-contained {
  padding: 8px 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
}
.reset-password .container .left .wrapper .content-wrapper .helper-link {
  margin-top: 26px;
  display: flex;
  align-items: center;
}
.reset-password .container .left .wrapper .content-wrapper .helper-link span {
  font-weight: 600;
  text-decoration: none;
  color: #0034bb;
  margin-left: 6px;
}
.reset-password .container .right {
  flex: 0.56;
  background: #000000;
  overflow-x: hidden;
}
.reset-password .container .right img {
  height: 88%;
  margin-top: 5%;
  margin-left: 10%;
  border-radius: 20px;
}

.license-buy {
  padding: 0 40px 50px 40px;
  height: 100vh;
}
.license-buy .plan .title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 10;
  left: 40px;
  top: 0px;
  background: #ffffff;
  padding: 50px 0;
}
.license-buy .plan .title-container .title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 30px;
  font-weight: 500;
  color: #101828;
  cursor: pointer;
}
.license-buy .plan .title-container img {
  max-width: 107px;
}
.license-buy .plan .body {
  display: flex;
  align-items: flex-start;
}
.license-buy .plan .body .left-body {
  display: flex;
  flex-direction: column;
  gap: 50px;
  position: sticky;
  left: 40px;
  top: 150px;
  width: 26%;
}
.license-buy .plan .body .left-body .left-menu {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}
.license-buy .plan .body .left-body .left-menu .menu-icon {
  display: flex;
  margin-top: 8px;
  align-items: center;
  justify-items: center;
  min-width: 50px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.license-buy .plan .body .left-body .left-menu .menu-icon .icon {
  margin: auto;
  padding: auto;
  width: 30px;
  height: 30px;
  color: black;
}
.license-buy .plan .body .left-body .left-menu .menu-text h2 {
  font-size: 20px;
  font-weight: 600;
  color: #101828;
}
.license-buy .plan .body .left-body .left-menu .menu-text p {
  font-size: 14px;
  font-weight: 400;
  color: #101828;
}
.license-buy .plan .body .left-body .active .menu-icon {
  border: 1px solid #0034bb;
}
.license-buy .plan .body .left-body .active .menu-icon .icon {
  color: #0034bb;
}
.license-buy .plan .body .left-body .active .menu-text h2 {
  color: #0034bb;
}
.license-buy .plan .body .left-body .line {
  position: absolute;
  height: 73px;
  width: 1px;
  background: rgba(0, 0, 0, 0.2);
  top: 58px;
  left: 24px;
}
.license-buy .plan .body .left-body .line-bottom {
  position: absolute;
  height: 73px;
  width: 1px;
  background: rgba(0, 0, 0, 0.2);
  bottom: 63px;
  left: 24px;
}
.license-buy .plan .body .right-body {
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-left: 30px;
  margin-bottom: 50px;
  padding: 25px 30px;
  width: 74%;
  border-radius: 8px;
}
.license-buy .plan .body .right-body .choose-plan .container .start {
  margin-top: 25px;
  border: 1px solid #0034bb;
  border-radius: 12px;
  padding: 20px 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.license-buy .plan .body .right-body .choose-plan .container .start h3 {
  font-size: 38px;
  font-weight: 700;
  color: #212121;
}
.license-buy .plan .body .right-body .choose-plan .container .start .right p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.4);
  text-decoration: line-through;
  margin-left: 8px;
  line-height: 1;
}
.license-buy .plan .body .right-body .choose-plan .container .start .right h2 {
  font-size: 44px;
  font-weight: 700;
  color: #0034bb;
  line-height: 1;
}
.license-buy .plan .body .right-body .choose-plan .container .start .right h2 .user {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
}
.license-buy .plan .body .right-body .choose-plan .end {
  padding-top: 20px;
}
.license-buy .plan .body .right-body .choose-plan .end .text {
  margin-bottom: 40px;
}
.license-buy .plan .body .right-body .choose-plan .end .text .success-msg {
  color: #027A48;
  font-weight: 500;
  line-height: 1.5;
}
.license-buy .plan .body .right-body .choose-plan .end .text .error-alert {
  font-size: 14px;
  font-weight: 400;
  color: #ba0000;
  margin-top: 5px;
}
.license-buy .plan .body .right-body .choose-plan .end .text .ecn-input-custom-two {
  margin-top: 30px;
}
.license-buy .plan .body .right-body .choose-plan .end .text .ecn-input-custom-two .MuiFormLabel-root {
  padding-left: 6px;
}
.license-buy .plan .body .right-body .choose-plan .end .billing-details {
  margin-top: 60px;
}
.license-buy .plan .body .right-body .choose-plan .end .billing-details .head {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
}
.license-buy .plan .body .right-body .choose-plan .end .billing-details .details-container {
  border: 2px dashed rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 22px 30px;
  margin-top: 16px;
  margin-bottom: 20px;
}
.license-buy .plan .body .right-body .choose-plan .end .billing-details .details-container .row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.license-buy .plan .body .right-body .choose-plan .end .billing-details .details-container .row:last-child {
  margin-bottom: 0;
}
.license-buy .plan .body .right-body .choose-plan .end .billing-details .details-container .row.total {
  font-weight: 600;
}
.license-buy .plan .body .right-body .choose-plan .end .billing-details .details-container .row .amount {
  font-weight: 600;
}
.license-buy .plan .body .right-body .choose-plan .end .billing-details .details-container .row .amount.discount {
  color: #027A48;
}
.license-buy .plan .body .right-body .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.license-buy .plan .body .right-body .bottom .btn-contained,
.license-buy .plan .body .right-body .bottom .btn-outlined {
  width: 139px;
  padding: 12px 44px;
  height: 44px;
  font-size: 14px !important;
}
.license-buy .plan .body .right-body .btn-outlined-cancel {
  border: 1px solid rgba(33, 33, 33, 0.2);
}
.license-buy .plan .body .right-body .billing {
  margin: auto;
}
.license-buy .plan .body .right-body .billing h3 {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 32px;
}
.license-buy .plan .body .right-body .billing .billing-address {
  margin-top: 17px;
}
.license-buy .plan .body .right-body .billing .billing-row {
  margin-bottom: 32px;
  text-align: left;
}

.success-popup .successImg {
  max-width: 150px;
}
.success-popup h3 {
  font-size: 25px;
  font-weight: 700;
  color: #212121;
  margin: 10px;
}
.success-popup p {
  font-size: 18px;
  font-weight: 400px;
  color: #212121;
  margin: 5px;
  margin-bottom: 20px;
}

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.error-page .card {
  width: 65%;
  margin-inline: auto;
  border-radius: 14px;
  text-align: center;
  padding: 28px 45px 19px 45px;
}
.error-page .card h2 {
  margin: 19px 0;
  font-size: 35px;
  font-weight: 600;
  line-height: normal;
  color: #212121;
}
.error-page .card p {
  font-size: 22px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
}
.error-page .card .btn-home {
  margin-top: 19px;
  font-size: 16px;
  font-weight: 500;
  width: 360px;
}

.user-details {
  margin-top: 36px;
  margin-left: 8px;
}
.user-details Button:hover {
  background: rgba(0, 52, 187, 0.1);
}
.user-details .top-header {
  margin-top: -10px;
}
.user-details .top-header .breadcrumb {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}
.user-details .top-header .breadcrumb .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.user-details .top-header .breadcrumb p {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
}
.user-details .top-header .breadcrumb p:last-child {
  color: #212121;
}
.user-details .top-header h2 {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 40px;
  margin-top: 5px;
}
.user-details .user-details-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-details .user-details-top .avatar {
  width: 75px;
  height: 75px;
}
.user-details .user-details-top .user-details-top-left {
  display: flex;
  align-items: center;
  gap: 15px;
}
.user-details .user-details-top .user-details-top-left .name h2 {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
}
.user-details .user-details-top .user-details-top-left .name p {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
}
.user-details .user-details-top .user-details-top-right {
  display: flex;
  align-items: center;
  gap: 23px;
}
.user-details .user-details-top .user-details-top-right .edit {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  border-radius: 8px;
  text-align: center;
  height: 45px;
  width: 97px;
}
.user-details .user-details-top .user-details-top-right .menu-icon {
  border-radius: 9px;
  width: 15px;
  min-width: 60px;
  color: #0034bb;
  padding: 0px !important;
}
.user-details .personal-details {
  border-radius: 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 30px 45px;
  background: #ffffff;
  margin-top: 30px;
}
.user-details .personal-details .title h2 {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding-bottom: 23px;
}
.user-details .personal-details .details {
  margin-top: 25px;
  display: flex;
}
.user-details .personal-details .details .email {
  width: 30%;
}
.user-details .personal-details .details .email p {
  font-size: 14px;
  font-size: 400;
  color: #212121;
  margin-bottom: 5px;
}
.user-details .personal-details .details .email h1 {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
}
.user-details .personal-details .details .email:first-child {
  width: 39%;
}
.user-details .group-role {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 40px;
}
.user-details .group-role .group-card {
  width: 50%;
  background: #ffffff;
  border-radius: 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 25px 40px;
  padding-bottom: 30px;
  height: fit-content;
}
.user-details .group-role .group-card .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding-bottom: 18px;
}
.user-details .group-role .group-card .top p {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
}
.user-details .group-role .group-card .top .button {
  font-size: 14px;
  font-weight: 500;
  color: #0034bb;
  padding: 4px 15px;
  border-radius: 5px;
}
.user-details .group-role .group-card .fallback {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 20%;
}
.user-details .group-role .group-card .fallback p {
  font-size: 14px;
  font-weight: 500;
  color: gray;
}
.user-details .group-role .group-card .group-list {
  padding-left: 10px;
}
.user-details .group-role .group-card .group-list .avatar-name {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 30px;
}
.user-details .group-role .group-card .group-list .avatar-name .avatars .avatar {
  font-size: 15px;
  font-weight: 600;
  color: #0034bb;
  background: rgba(0, 52, 187, 0.1);
  text-transform: capitalize;
}
.user-details .group-role .group-card .group-list .avatar-name .name h1 {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  text-transform: capitalize;
}
.user-details .group-role .group-card .group-list .avatar-name .name p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.user-details .group-role .license-card {
  width: 50%;
  background: #ffffff;
  border-radius: 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 25px 40px;
  padding-bottom: 30px;
  height: fit-content;
}
.user-details .group-role .license-card .top {
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding-bottom: 12px;
  padding: 6px 0px 0px 0px;
  padding-bottom: 25px;
}
.user-details .group-role .license-card .top p {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
}
.user-details .group-role .license-card .bottom {
  margin-top: 28px;
}
.user-details .group-role .license-card .bottom .row {
  display: flex;
  justify-content: space-between;
}
.user-details .group-role .license-card .bottom .row .license p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.user-details .group-role .license-card .bottom .row .license h3 {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  margin-top: 8px;
}
.user-details .edit-user {
  background: #fdfdfd;
  margin-top: -10px;
}
.user-details .edit-user .top .back {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.8);
  cursor: pointer;
  margin-bottom: 10px;
}
.user-details .edit-user .top .title {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
}
.user-details .edit-user .container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 35px 40px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: #ffffff;
}
.user-details .edit-user .container .wrapper {
  display: flex;
  flex-direction: column;
  gap: 33px;
}
.user-details .edit-user .container .wrapper .top {
  display: flex;
  gap: 40px;
}
.user-details .edit-user .container .wrapper .top .left {
  border-radius: 14px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 40px;
  padding-top: 58px;
  gap: 23px;
}
.user-details .edit-user .container .wrapper .top .left .avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(33, 33, 33, 0.05);
  border: 1px solid rgba(33, 33, 33, 0.1);
  width: 190px;
  height: 190px;
  border-radius: 100%;
}
.user-details .edit-user .container .wrapper .top .left .avatar .icon {
  min-width: 70px;
  min-height: 70px;
  color: rgba(33, 33, 33, 0.6);
}
.user-details .edit-user .container .wrapper .top .left .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.user-details .edit-user .container .wrapper .top .left .btn-outlined {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  padding: 7px 10px;
  min-width: 200px;
  gap: 12px;
}
.user-details .edit-user .container .wrapper .top .left .change-btn {
  color: rgba(0, 52, 187, 0.8);
}
.user-details .edit-user .container .wrapper .top .container-right {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 14px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 40px;
  padding-top: 11px;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 30px;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field {
  width: 50%;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .select .MuiFormControl-root {
  width: 100%;
  min-width: 277px;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .select .MuiFormControl-root .placeholder {
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.3);
  text-transform: none;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .select .MuiFormControl-root .MuiInputBase-root {
  margin-top: 0px !important;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .select .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 15px;
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.8);
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .select .MuiFormControl-root .MuiInputBase-root .MuiSelect-select {
  text-transform: capitalize;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .select .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 16px;
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  background: transparent;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 4px;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input {
  font-size: 16px;
  font-weight: 500;
  height: auto;
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 0px 3px;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input:hover, .user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input:focus, .user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input:focus-within {
  border-color: rgba(0, 52, 187, 0.7);
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input input {
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
  color: #212121;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input .form-control {
  width: 91%;
  border-radius: 8px;
  margin-left: 26px;
  border: unset;
  border-left: none;
  height: 43px;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input .flag-dropdown {
  border-radius: 8px 0 0 8px;
  border-right: none;
  border: unset;
  background: rgba(255, 255, 255, 0.8);
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input .flag-dropdown .selected-flag {
  padding: 0 0 0 19px;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .mobile-input .flag-dropdown .selected-flag .flag .arrow {
  border-top: unset;
  border-left: unset;
  border-right: unset;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 6L8 10L12 6" stroke="%23212121" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  top: unset;
  left: 22px;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .input {
  width: 100%;
  min-width: 275px;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .input .Mui-focused {
  border: 1px solid rgba(0, 52, 187, 0.7) !important;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .input .MuiInputBase-root {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  border: 1px solid rgba(33, 33, 33, 0.2);
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .input .MuiInputBase-root:hover {
  border: 1px solid rgba(0, 52, 187, 0.7) !important;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .input .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 16px;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .input .MuiInputBase-root .MuiInputBase-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  opacity: unset;
  color: rgba(33, 33, 33, 0.3);
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .input .MuiFormHelperText-root {
  margin-left: 2px;
}
.user-details .edit-user .container .wrapper .top .container-right .text-fields .row .field .email .MuiInputBase-root:hover {
  border: 1px solid rgba(33, 33, 33, 0.2) !important;
}
.user-details .edit-user .container .wrapper .bottom {
  text-align: right;
}
.user-details .edit-user .container .wrapper .bottom .btn-contained {
  border-radius: 7px;
  border: 1px solid #0034bb;
  background: #0034bb;
  color: #ffffff;
  min-width: 130px;
  padding: 7px 31px;
  font-size: 16px !important;
  font-weight: 500;
  text-align: right;
}
.user-details .edit-user .container .wrapper .bottom .btn-contained:disabled {
  background: #0034bb !important;
}

.drawer-password .MuiDrawer-paper {
  border-radius: 0px !important;
  min-width: 60%;
  padding: 15px 40px;
  justify-content: unset;
}
.drawer-password .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-right: 5px;
}
.drawer-password .top .icons {
  color: #212121;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: -3px;
}
.drawer-password .drawer-title {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin: 20px 0px;
}
.drawer-password .body {
  border: 1px solid rgba(33, 33, 33, 0.1);
  min-height: 400px;
  padding: 25px 30px;
  border-radius: 7px;
  background: #ffffff;
}
.drawer-password .body .users {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
}
.drawer-password .body .users .avatar {
  width: 50px;
  height: 50px;
}
.drawer-password .body .users .name h2 {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
}
.drawer-password .body .users .name p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.drawer-password .body .reason {
  padding-left: 8px;
}
.drawer-password .body .reason .radio {
  margin-bottom: 7px;
}
.drawer-password .body .reason .check-box {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 2px;
  margin: 10px 0px;
  margin-top: 4px;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}
.drawer-password .body .reason .check-box .check {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.8);
}
.drawer-password .body .reason .fadeout {
  opacity: 1;
  max-height: 100px;
}
.drawer-password .body .reason .input-box {
  margin-top: 13px;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}
.drawer-password .body .reason .input-box .input-label {
  margin-bottom: 15px;
}
.drawer-password .body .reason .input-box .input-label p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 4px;
}
.drawer-password .body .reason .input-box .input-label .error {
  font-size: 14px;
  font-weight: 500;
  color: #ba0000;
}
.drawer-password .body .reason .input-box .input-label h3 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
  margin-top: 4px;
}
.drawer-password .body .reason .input-box .input-label h3 span {
  font-weight: 700;
}
.drawer-password .body .reason .input-fadeout {
  opacity: 1;
  max-height: 300px;
}
.drawer-password .body .bottom {
  padding-left: 10px;
  margin-top: 50px;
}
.drawer-password .body .bottom .button {
  border: 1px solid #0034bb;
  border-radius: 9px;
  background: transparent;
  min-width: 250px;
  text-align: center;
  padding: 7px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  color: #0034bb;
}
.drawer-password .body .success-message {
  text-align: center;
}
.drawer-password .body .success-message img {
  max-width: 300px;
  margin: auto;
}
.drawer-password .body .success-message p {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  margin-top: -10px;
}

.group-dialog .MuiPaper-root {
  padding: 30px 32px;
  border-radius: 8px;
  width: 520px;
  height: 390px;
}
.group-dialog .MuiPaper-root .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}
.group-dialog .MuiPaper-root .top h3 {
  font-size: 18px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
}
.group-dialog .MuiPaper-root .top p {
  font-size: 14px;
  font-weight: 500;
  color: #0034bb;
}
.group-dialog .MuiPaper-root .group-body {
  height: 500px;
  overflow-y: scroll;
}
.group-dialog .MuiPaper-root .group-body .group-item {
  margin-top: 20px;
  display: flex;
  padding: 12px 10px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  gap: 0px;
  padding-top: 10px;
}
.group-dialog .MuiPaper-root .group-body .group-item:last-child {
  border-bottom: none;
}
.group-dialog .MuiPaper-root .group-body .group-item .avatar-name {
  display: flex;
  align-items: center;
  gap: 19px;
}
.group-dialog .MuiPaper-root .group-body .group-item .avatar-name .avatars .avatar {
  font-size: 15px;
  font-weight: 600;
  color: #0034bb;
  background: rgba(0, 52, 187, 0.1);
  text-transform: capitalize;
}
.group-dialog .MuiPaper-root .group-body .group-item .avatar-name .name h1 {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  text-transform: capitalize;
}
.group-dialog .MuiPaper-root .group-body .group-item .avatar-name .name p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.group-dialog .MuiPaper-root .bottom {
  display: flex;
  align-items: center;
  gap: 40px;
  padding-top: 20px;
}
.group-dialog .MuiPaper-root .bottom .cancel {
  width: 50%;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 16px;
  font-weight: 600;
  color: #212121;
}
.group-dialog .MuiPaper-root .bottom .cancel:hover {
  background: transparent;
}
.group-dialog .MuiPaper-root .bottom .add {
  width: 50%;
  border-radius: 9px;
  border: 1px solid #0034bb;
  font-size: 16px;
  font-weight: 600;
  color: #0034bb;
}
.group-dialog .MuiPaper-root .bottom .add:hover {
  background: transparent;
}

.MuiMenu-root .MuiPaper-root .list-item {
  border-radius: 10px;
  margin-bottom: 10px !important;
  padding: 1px 10px;
}
.MuiMenu-root .MuiPaper-root .country-list-item {
  padding: 10px 10px;
}

.edit-menu .MuiPaper-root {
  padding: 10px 10px;
  margin-left: -20px;
  padding-bottom: 5px;
}
.edit-menu .MuiPaper-root .MuiMenuItem-root {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.edit-menu .MuiPaper-root .MuiMenuItem-root .icons {
  color: rgba(33, 33, 33, 0.6);
}
.edit-menu .MuiPaper-root .MuiMenuItem-root span {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
}
.edit-menu .MuiPaper-root .MuiMenuItem-root:hover {
  border-radius: 8px;
}
.edit-menu .MuiPaper-root .MuiMenuItem-root:hover span {
  font-size: 14px;
  font-weight: 500;
  color: #0034bb !important;
}
.edit-menu .MuiPaper-root .MuiMenuItem-root:hover .icons {
  color: #0034bb !important;
}

::-ms-reveal {
  display: none;
}

.MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.marketplace {
  padding-bottom: 100px;
}
.marketplace .market-top {
  margin-top: 25px;
  padding-bottom: 17px;
}
.marketplace .market-top .navigation {
  display: flex;
  align-items: center;
  gap: 10px;
}
.marketplace .market-top .navigation .icons {
  color: rgba(33, 33, 33, 0.6);
  width: 19px;
  height: 19px;
}
.marketplace .market-top .navigation .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.marketplace .market-top .navigation p {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
}
.marketplace .market-top h1 {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin: 15px 0px;
}
.marketplace .market-top .sub-heading {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.marketplace .market-top .link {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
  text-decoration: underline;
  margin: 5px 0px;
}
.marketplace .list-body {
  border-radius: 15px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 20px 40px;
  padding-bottom: 30px;
}
.marketplace .list-body h1 {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 13px;
}
.marketplace .list-body .chips {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}
.marketplace .list-body .chips .p {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 16px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 6px 18px;
  cursor: pointer;
}
.marketplace .list-body .chips .active {
  border: none;
  background: #0034bb;
  color: #fff;
  padding-right: 10px;
}
.marketplace .list-body .grids {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 35px;
}
.marketplace .list-body .grids .card {
  width: 47.5%;
  border: 1px solid rgba(33, 33, 33, 0.1);
  background: #fff;
  border-radius: 8px;
  padding: 30px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  cursor: pointer;
}
.marketplace .list-body .grids .card .card-details h2 {
  font-size: 24px;
  font-weight: 700;
  color: #212121;
  margin-bottom: 15px;
}
.marketplace .list-body .grids .card .card-details .chip {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.marketplace .list-body .grids .card .card-details .chip p {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 73px;
  background: rgba(255, 194, 70, 0.3);
  width: fit-content;
  padding: 3px 15px;
}
.marketplace .list-body .grids .card .card-details .details p {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
}
.marketplace .list-body .grids .card .bottom .price {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 20px;
}
.marketplace .list-body .grids .card .bottom .btn-group {
  display: flex;
  gap: 12px;
}
.marketplace .list-body .grids .card .bottom button {
  background: rgb(0, 52, 187);
  border-radius: 8px;
  width: 100%;
  text-align: center;
  color: #ffffff;
  height: 44px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: none;
}
.marketplace .list-body .grids .card .bottom .assign-btn {
  background: transparent;
  color: #0034bb;
  border-width: 1.5px;
  border-color: #0034bb;
}
.marketplace .product-details {
  margin-top: -18px;
}
.marketplace .product-details .card-details {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 30px 25px;
  padding-right: 35px;
}
.marketplace .product-details .card-details h1 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 6px;
  color: #212121;
  margin-bottom: 8px;
}
.marketplace .product-details .card-details .chips {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.marketplace .product-details .card-details .chips p {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 73px;
  background: rgba(255, 194, 70, 0.3);
  width: fit-content;
  padding: 3px 15px;
}
.marketplace .product-details .card-details .description {
  margin-top: 20px;
}
.marketplace .product-details .card-details .description h2 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 15px;
}
.marketplace .product-details .card-details .description p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0);
}
.marketplace .product-details .card-details .description ul {
  margin-left: 25px;
  margin-top: 20px;
}
.marketplace .product-details .card-details .description ul .feature-list {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
}
.marketplace .product-details .card-details .description ul .feature-list li {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.marketplace .product-details .card-details .description ul .feature-list p {
  font-size: 14px;
  font-weight: 400;
  color: #0034bb;
  cursor: pointer;
}
.marketplace .product-details .card-details .description .next-button {
  text-align: right;
}
.marketplace .product-details .card-details .description .next-button button {
  background-color: #0034bb;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  min-width: 160px;
  border-radius: 8px;
}
.marketplace .product-details .testimonials {
  height: 30px;
  overflow: hidden;
  padding: 0px 25px;
  transition: 0.2s all;
  padding-bottom: 0px;
  cursor: pointer;
}
.marketplace .product-details .testimonials .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  cursor: pointer;
}
.marketplace .product-details .testimonials .top h2 {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
}
.marketplace .product-details .testimonials .top .icon {
  width: 23px;
  height: 23px;
  cursor: pointer;
}
.marketplace .product-details .testimonials p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-top: 5px;
}
.marketplace .product-details .testimonials .container {
  padding-right: 30px;
  height: auto;
  max-height: 100%;
  margin-top: 20px;
  position: relative;
}
.marketplace .product-details .testimonials .container .swiper-slide {
  padding-bottom: 50px;
}
.marketplace .product-details .testimonials .container .swiper-slide .card {
  border-radius: 15px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 15px;
  min-height: 120px;
}
.marketplace .product-details .testimonials .container .swiper-slide .card .testimonial-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.marketplace .product-details .testimonials .container .swiper-slide .card .testimonial-head .testimonial-left {
  display: flex;
  align-items: center;
}
.marketplace .product-details .testimonials .container .swiper-slide .card .testimonial-head .testimonial-left .testimonial-user {
  display: flex;
  align-items: center;
  gap: 10px;
}
.marketplace .product-details .testimonials .container .swiper-slide .card .testimonial-head .testimonial-left .testimonial-user .avatar {
  width: 35px;
  height: 35px;
}
.marketplace .product-details .testimonials .container .swiper-slide .card .testimonial-head .testimonial-left .testimonial-user .testimonial-user-details h5 {
  font-size: 12px;
  font-weight: 700;
  color: #212121;
}
.marketplace .product-details .testimonials .container .swiper-slide .card .testimonial-head .testimonial-left .testimonial-user .testimonial-user-details p {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
}
.marketplace .product-details .testimonials .container .swiper-slide .card .testimonial-head .testimonial-right .firm_logo {
  max-width: 60px;
  max-height: 30px;
}
.marketplace .product-details .testimonials .container .swiper-slide .card .description {
  font-size: 12px;
  font-weight: 400;
  color: #212121;
  margin-top: 15px;
}
.marketplace .product-details .testimonials .container .swiper-button-prev {
  position: absolute;
  width: 110px !important;
  top: auto;
  bottom: 0px;
  left: 46%;
}
.marketplace .product-details .testimonials .container .swiper-button-prev::after {
  background: url("https://api.iconify.design/material-symbols/arrow-back-rounded.svg?width=18&height=18&rotate=0deg") no-repeat;
  background-repeat: no-repeat;
  background-position: 8px;
  font-size: 14px;
  content: "";
  cursor: pointer;
  padding: 10px;
  padding-left: 30px;
  padding-right: 10px;
  width: 160px !important;
  font-family: unset !important;
}
.marketplace .product-details .testimonials .container .swiper-button-next {
  position: absolute;
  width: 110px !important;
  top: auto;
  bottom: 0px;
  left: 45%;
}
.marketplace .product-details .testimonials .container .swiper-button-next::after {
  background: url("https://api.iconify.design/material-symbols/arrow-back-rounded.svg?width=18&height=18&rotate=0deg&flip=horizontal%2Cvertical") no-repeat;
  background-repeat: no-repeat;
  background-position: 62px;
  font-size: 14px;
  content: "";
  cursor: pointer;
  padding: 10px;
  padding-left: 30px;
  padding-right: 10px;
  width: 160px !important;
  font-family: unset !important;
}
.marketplace .product-details .testimonials-expended-view {
  height: fit-content;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 30px 25px;
  margin-top: 20px;
  padding-bottom: 15px;
}
.marketplace .product-details .testimonials-expended-view .top {
  margin-top: 0px;
}
.marketplace .product-details .testimonials-height .container .swiper-slide {
  padding-bottom: 5px !important;
}
.marketplace .product-details .features {
  height: 30px;
  overflow: hidden;
  padding: 0px 25px;
  transition: 0.5s all;
  margin-top: 20px;
}
.marketplace .product-details .features .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -25px;
  cursor: pointer;
}
.marketplace .product-details .features .top p {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
}
.marketplace .product-details .features .MuiPaper-root {
  border: none !important;
  padding: 30px 0px;
}
.marketplace .product-details .features .MuiPaper-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
  background-color: #ffffff;
  color: rgba(33, 33, 33, 0.7);
  font-size: 21px;
  font-weight: 700;
}
.marketplace .product-details .features .MuiPaper-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root .cell-container {
  padding: 20px;
  border-radius: 14px 14px 0 0;
  margin-bottom: -18px;
  height: 101%;
  width: 100%;
  border-bottom: none;
}
.marketplace .product-details .features .MuiPaper-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root .cell-container img {
  max-width: 105px;
}
.marketplace .product-details .features .MuiPaper-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
  border-bottom: none;
}
.marketplace .product-details .features .MuiPaper-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root .main-col {
  text-align: center;
  border-bottom: none;
  width: 220px;
}
.marketplace .product-details .features .MuiPaper-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .cell-container {
  padding: 30px;
  border-top: none;
}
.marketplace .product-details .features .MuiPaper-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .second-column {
  padding: 0px !important;
}
.marketplace .product-details .features .MuiPaper-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .second-column .second-content {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #0034bb;
  border-right: 1px solid #0034bb;
  min-height: 65px;
}
.marketplace .product-details .features .MuiPaper-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .second-column .second-content p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.marketplace .product-details .features .MuiPaper-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .last-child {
  border-bottom: 1px solid #0034bb !important;
}
.marketplace .product-details .features .MuiPaper-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .first-column {
  padding-left: 30px;
}
.marketplace .product-details .features .MuiPaper-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .first-column p {
  font-size: 14px;
  font-weight: 400;
  width: 140px;
  color: #212121;
}
.marketplace .product-details .features .MuiPaper-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .third-column {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  width: 140px;
  color: #212121;
}
.marketplace .product-details .features .table-second-header {
  padding: 0px;
}
.marketplace .product-details .features .table-second-header .cont {
  border-left: 1px solid #0034bb;
  border-right: 1px solid #0034bb;
  border-top: 1px solid #0034bb;
  border-radius: 16px 16px 0px 0px;
  margin-bottom: -70px;
  padding-bottom: 120px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  text-align: center;
}
.marketplace .product-details .features .table-second-header .cont .line {
  height: 7px;
  background: #0034bb;
  border-radius: 12px;
  margin-bottom: 47px;
}
.marketplace .product-details .features .table-third-header {
  padding: 0px;
}
.marketplace .product-details .features .table-third-header .cont {
  border-radius: 16px 16px 0px 0px;
  margin-bottom: -70px;
  padding-bottom: 120px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 45px;
}
.marketplace .product-details .features .table-third-header .cont .line {
  height: 7px;
  background: transparent;
  border-radius: 12px;
  margin-bottom: 47px;
}
.marketplace .product-details .features .MuiTableRow-root:nth-child(odd) {
  background: #f6f9ff;
  border-bottom: none !important;
}
.marketplace .product-details .features .MuiTableCell-root {
  border-bottom: none !important;
}
.marketplace .product-details .expended-view {
  background: #fff;
  height: fit-content;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  padding: 30px 25px;
  margin-bottom: 20px;
}
.marketplace .number-of-license {
  border-radius: 8px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 30px 25px;
}
.marketplace .number-of-license h1 {
  font-size: 24px;
  font-weight: 700;
  color: #212121;
  margin-bottom: 10px;
}
.marketplace .number-of-license .chip {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.marketplace .number-of-license .chip p {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 73px;
  background: rgba(255, 194, 70, 0.3);
  width: fit-content;
  padding: 3px 15px;
}
.marketplace .number-of-license .select {
  margin-top: 25px;
  width: 100%;
}
.marketplace .number-of-license .select p {
  font-size: 20px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 6px;
}
.marketplace .number-of-license .select .input-button {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.marketplace .number-of-license .select .input-button button {
  display: flex;
  align-items: center;
  background: transparent;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  padding: 4px 20px;
  padding-left: 17px;
}
.marketplace .number-of-license .payment-type {
  margin-top: 30px;
}
.marketplace .number-of-license .payment-type h2 {
  font-size: 20px;
  font-weight: 500;
  color: #212121;
}
.marketplace .number-of-license .payment-type .payment-data .card-wrapper {
  margin-top: 20px;
}
.marketplace .number-of-license .payment-type .payment-data .card-wrapper .card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
}
.marketplace .number-of-license .payment-type .payment-data .card-wrapper .card .card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.marketplace .number-of-license .payment-type .payment-data .card-wrapper .card .card-top .radio-name {
  display: flex;
  align-items: center;
}
.marketplace .number-of-license .payment-type .payment-data .card-wrapper .card .card-top .radio-name p {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
}
.marketplace .number-of-license .payment-type .payment-data .card-wrapper .card .card-top p {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.marketplace .number-of-license .payment-type .payment-data .card-wrapper .card .card-top p span {
  font-size: 16px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  margin-right: 20px;
  text-decoration: line-through;
}
.marketplace .number-of-license .payment-type .payment-data .card-wrapper .card .card-top .discount {
  font-size: 11px;
  font-weight: 400;
  color: #027a48;
  background: rgba(2, 122, 72, 0.1);
  width: fit-content;
  padding: 4px 15px;
  border: 1px solid #027a48;
  border-radius: 6px;
  margin-left: auto;
  margin-top: 12px;
}
.marketplace .number-of-license .payment-type .payment-data .card-wrapper .card .card-details {
  padding-left: 40px;
}
.marketplace .number-of-license .payment-type .payment-data .card-wrapper .card .card-details .head {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  margin-bottom: 5px;
}
.marketplace .number-of-license .payment-type .payment-data .card-wrapper .card .card-details .head .icon {
  color: rgb(2, 122, 72);
}
.marketplace .number-of-license .payment-type .payment-data .card-wrapper .active {
  border: 1px solid #0034bb;
}
.marketplace .number-of-license .payment-type .button {
  text-align: right;
  margin-top: 30px;
}
.marketplace .number-of-license .payment-type .button .checkout {
  width: 210px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  box-shadow: none;
  border-radius: 8px;
  padding: 6px 20px;
}
.marketplace .number-of-license .payment-type .button .checkout:hover {
  box-shadow: none;
}

.my-products {
  margin-left: 10px;
  background: #fdfdfd;
}
.my-products .my-products-header .top-header {
  margin-top: 39px;
}
.my-products .my-products-header .top-header h1 {
  color: #212121;
  font-size: 24px;
  font-weight: 600;
}
.my-products .my-products-header .top-header .sub-heading {
  font-size: 16px;
  font-weight: 400;
}
.my-products .my-products-header .bottom-header .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}
.my-products .my-products-header .bottom-header .wrapper .left {
  display: flex;
  align-items: center;
  gap: 8px;
}
.my-products .my-products-header .bottom-header .wrapper .left .active {
  box-shadow: 0px 1px #ffffff;
}
.my-products .my-products-header .bottom-header .wrapper .left .active h3 {
  color: #0034bb !important;
}
.my-products .my-products-header .bottom-header .wrapper .left .active:hover {
  box-shadow: 0px 1px #f2f5fc;
}
.my-products .my-products-header .bottom-header .wrapper .left .products-btn {
  cursor: pointer;
  border-radius: 15px 15px 0px 0px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-bottom: none;
  background: #ffffff;
  display: flex;
  justify-content: center;
  min-height: 64px;
  padding: 16px 50px;
  align-items: center;
  gap: 8px;
}
.my-products .my-products-header .bottom-header .wrapper .left .products-btn:hover {
  background: rgba(0, 52, 187, 0.05);
}
.my-products .my-products-header .bottom-header .wrapper .left .products-btn h3 {
  color: #212121;
  font-size: 18px;
  font-weight: 500;
}
.my-products .my-products-header .bottom-header .wrapper .bottom-right {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 13px;
}
.my-products .my-products-header .bottom-header .wrapper .bottom-right .filter {
  padding: 7px 17px;
  border-radius: 7px;
  min-width: 107px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  gap: 12px;
  color: #212121;
  min-height: 44px;
  font-size: 16px;
  font-weight: 500;
}
.my-products .my-products-header .bottom-header .wrapper .bottom-right .filter:hover {
  background: rgba(0, 52, 187, 0.05);
}
.my-products .table {
  margin-bottom: 80px;
  border: 1px solid rgba(33, 33, 33, 0.07);
  border-radius: 0px 15px 15px 15px;
}
.my-products .table .no-result {
  text-align: center;
  color: #212121;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 50px;
}
.my-products .table .pagination-container {
  border-radius: 0 0 15px 15px;
  background: #fdfdfd;
}
.my-products .table .pagination-container .user-pagination {
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  overflow: hidden;
  padding: 16px 24px;
}
.my-products .table .pagination-container .user-pagination .MuiPagination-ul {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  flex-wrap: nowrap;
}
.my-products .table .pagination-container .user-pagination .MuiPagination-ul .Mui-disabled {
  display: none;
}
.my-products .table .pagination-container .user-pagination .MuiPagination-ul li .Mui-selected {
  border-radius: 7px;
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.my-products .table .pagination-container .user-pagination .MuiPagination-ul li:first-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.my-products .table .pagination-container .user-pagination .MuiPagination-ul li:first-child .MuiButtonBase-root {
  border: none;
  box-shadow: none;
  padding: 8px 14px;
  color: #0034bb;
  font-size: 14px;
  font-weight: 500;
}
.my-products .table .pagination-container .user-pagination .MuiPagination-ul li:first-child .MuiButtonBase-root svg {
  display: none;
}
.my-products .table .pagination-container .user-pagination .MuiPagination-ul li:first-child > button::after {
  margin-left: 10px;
  content: "Previous";
}
.my-products .table .pagination-container .user-pagination .MuiPagination-ul li:last-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.my-products .table .pagination-container .user-pagination .MuiPagination-ul li:last-child .MuiButtonBase-root {
  border: none;
  box-shadow: none;
  padding: 8px 14px;
  color: #0034bb;
  font-size: 14px;
  font-weight: 500;
}
.my-products .table .pagination-container .user-pagination .MuiPagination-ul li:last-child .MuiButtonBase-root svg {
  display: none;
}
.my-products .table .pagination-container .user-pagination .MuiPagination-ul li:last-child > button::before {
  margin-right: 10px;
  content: "Next";
}

.ecn-datagrid-product .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-indeterminate {
  color: #0034bb;
  width: 40px !important;
  height: 40px !important;
}
.ecn-datagrid-product .MuiDataGrid-menuIcon {
  margin-right: 37px !important;
}
.ecn-datagrid-product .recruit-cell {
  cursor: pointer;
}

.product-menu-item .MuiPaper-root {
  width: 235px !important;
}
.product-menu-item .MuiPaper-root .MuiMenuItem-root {
  padding: 10px 10px 10px 15px;
  min-height: 45px;
  margin-bottom: unset !important;
}
.product-menu-item .MuiPaper-root .menu-hidden {
  display: none;
}

.menu-hidden {
  display: none !important;
}

.product-dialog .heading .icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  width: 24px;
  height: 24px;
  float: right;
  margin-top: 23px;
  margin-right: 10px;
}
.product-dialog .heading .icon:hover {
  background-color: rgba(33, 33, 33, 0.19);
  border-radius: 50px;
}
.product-dialog .MuiDialogContent-root {
  padding-left: 45px;
  padding-right: 46px;
}
.product-dialog .MuiDialogContent-root .license {
  max-width: 609px;
}
.product-dialog .MuiDialogContent-root .license .header {
  color: #212121;
}
.product-dialog .MuiDialogContent-root .license .header h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
}
.product-dialog .MuiDialogContent-root .license .header p {
  font-size: 18px;
  font-weight: 400;
}
.product-dialog .MuiDialogContent-root .license .ecn-radio-v2 {
  margin-top: 22px;
  gap: 24px;
}
.product-dialog .MuiDialogContent-root .license .ecn-radio-v2 .billing-interval {
  font-size: 16px;
  font-weight: 600;
}
.product-dialog .MuiDialogContent-root .license .helper-text {
  color: #ba0000 !important;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}
.product-dialog .MuiDialogContent-root .license .quantity {
  margin-top: 21px;
  color: #212121;
  padding-right: 61px;
}
.product-dialog .MuiDialogContent-root .license .quantity h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.product-dialog .MuiDialogContent-root .license .quantity .row {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.product-dialog .MuiDialogContent-root .license .quantity .row .field {
  width: 50%;
}
.product-dialog .MuiDialogContent-root .license .quantity .row p {
  font-size: 14px;
  font-weight: 400;
}
.product-dialog .MuiDialogContent-root .license .description {
  margin-top: 33px;
  font-size: 12px;
  font-weight: 400;
}
.product-dialog .MuiDialogContent-root .license .description a {
  color: #0034bb;
  text-decoration: underline;
  cursor: pointer;
}
.product-dialog .MuiDialogActions-root {
  padding-left: 49px;
  padding-right: 47px;
  padding-top: 30px;
  width: 100%;
}
.product-dialog .MuiDialogActions-root .btn-outlined-v2,
.product-dialog .MuiDialogActions-root .btn-contained-v2 {
  min-width: 110px;
  border-radius: 9px;
  padding: 5px 19px;
  font-size: 18px;
  min-height: 46px;
}

.cancel-product-dialog .heading .icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  width: 24px;
  height: 24px;
  float: right;
  margin-top: 23px;
  margin-right: 10px;
}
.cancel-product-dialog .heading .icon:hover {
  background-color: rgba(33, 33, 33, 0.19);
  border-radius: 50px;
}
.cancel-product-dialog .MuiDialogContent-root {
  padding-left: 45px;
  padding-right: 70px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .header {
  color: #212121;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .header h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .header p {
  font-size: 1p6x;
  font-weight: 400;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .header .list-reason {
  margin-top: 24px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .ecn-radio-v2 {
  margin-top: 22px;
  gap: 24px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .ecn-radio-v2 .billing-interval {
  font-size: 16px;
  font-weight: 600;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .text-header {
  max-width: 561px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .text-header .top-text {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .text-header .bottom-text {
  font-size: 14px;
  font-weight: 400;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .reason-box {
  margin-top: 20px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .reason-box p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 19px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .reason-box .ecn-input-description .MuiOutlinedInput-root {
  padding: 1px 1px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .consultation-container {
  margin-top: 19px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .consultation-container p {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .consultation-container .consultation {
  font-size: 15px;
  font-weight: 500;
  color: #0034bb;
  text-decoration: underline;
  cursor: pointer;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .options .ecn-radio-v2 {
  gap: 17px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .options .ecn-radio-v2 .MuiFormControlLabel-root {
  gap: 8px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .options .ecn-input-description .MuiOutlinedInput-root {
  padding: 1px 1px;
  margin-top: 15px;
  margin-left: 18px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .mobile-picker {
  width: 100%;
  min-width: 277px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .mobile-picker .MuiInputBase-root {
  margin-top: 0px !important;
  color: #2a2f3b;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .mobile-picker .MuiInputBase-root .MuiSelect-select {
  text-transform: capitalize;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .mobile-picker .MuiInputBase-root .MuiInputBase-input {
  padding: 16px;
  color: #212121;
  background: transparent;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .cancellation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .cancellation h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 23px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .cancellation img {
  max-width: 303px;
  height: auto;
  margin-bottom: 40px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .cancellation p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 24px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .cancellation .img-success {
  margin-bottom: 34px;
}
.cancel-product-dialog .MuiDialogContent-root .cancel-product .view-wrapper .cancellation .confirm-success {
  max-width: 394px;
  height: auto;
  margin-bottom: 42px;
}
.cancel-product-dialog .btn-contained-v2 {
  border-radius: 9px;
  padding: 5px 5px;
  font-size: 16px;
}
.cancel-product-dialog .btn-outlined-v2 {
  border-radius: 9px;
  padding: 5px 5px;
  font-size: 16px;
  font-weight: 500;
  border: 1.7px solid #0034bb !important;
  color: #0034bb !important;
}
.cancel-product-dialog .btn-outlined-v2:hover {
  background: rgba(0, 52, 187, 0.05) !important;
}
.cancel-product-dialog .cancel-btns {
  gap: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel-product-dialog .cancel-btns .btn-contained-v2,
.cancel-product-dialog .cancel-btns .btn-outlined-v2 {
  min-width: 155px;
  min-height: 44px;
  padding: 5px 28px;
}
.cancel-product-dialog .next-btn {
  min-width: 113px;
  min-height: 48px;
  font-size: 18px;
}
.cancel-product-dialog .btn-outlined-next {
  min-width: 155px;
  min-height: 44px;
}
.cancel-product-dialog .subscription-btn {
  min-width: 167px;
  min-height: 44px;
}
.cancel-product-dialog .booking-btn {
  min-width: 160px;
  min-height: 44px;
}
.cancel-product-dialog .marketplace-btn {
  min-width: 193px;
  min-height: 44px;
}
.cancel-product-dialog .MuiDialogActions-root {
  padding-left: 49px;
  padding-right: 70px;
  padding-top: 30px;
}
.cancel-product-dialog .MuiDialogActions-root .buttons {
  width: 100%;
  text-align: end;
}
.cancel-product-dialog .MuiDialogActions-root .buttons .right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 27px;
}

@media only screen and (min-width: 1199px) and (max-width: 1318px) {
  .user .user-header .bottom-header .wrapper {
    gap: 10px;
  }
  .user .user-header .bottom-header .wrapper .left .all-users {
    min-width: 209px;
    padding: 15px 56px 15px 21px;
    gap: 7px;
  }
  .user .user-header .bottom-header .wrapper .left .all-users h3 {
    font-size: 15px;
  }
  .user .user-header .bottom-header .wrapper .left .all-users p {
    font-size: 10px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right {
    gap: 21px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .search {
    padding: 9px 15px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .search .icon {
    width: 17px;
    height: 17px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .filter {
    padding: 6px 15px;
    font-size: 13px;
    gap: 10px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .filter .icon {
    width: 17px;
    height: 17px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .btn-outlined {
    padding: 6px 15px;
    font-size: 13px;
    gap: 10px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .btn-outlined .icon {
    width: 17px;
    height: 17px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1198px) {
  .user .user-header .bottom-header .wrapper {
    gap: 10px;
  }
  .user .user-header .bottom-header .wrapper .left .all-users {
    min-width: 175px;
    padding: 15px 21px 15px 21px;
    gap: 7px;
  }
  .user .user-header .bottom-header .wrapper .left .all-users h3 {
    font-size: 15px;
  }
  .user .user-header .bottom-header .wrapper .left .all-users p {
    font-size: 10px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right {
    gap: 21px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .search {
    padding: 9px 15px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .search .icon {
    width: 17px;
    height: 17px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .filter {
    padding: 6px 15px;
    font-size: 13px;
    gap: 10px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .filter .icon {
    width: 17px;
    height: 17px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .btn-outlined {
    padding: 6px 15px;
    font-size: 13px;
    gap: 10px;
  }
  .user .user-header .bottom-header .wrapper .bottom-right .btn-outlined .icon {
    width: 17px;
    height: 17px;
  }
}
.MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .Mui-selected {
  background-color: #0034bb;
}
.MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersDay-root:hover {
  background-color: rgba(0, 52, 187, 0.05);
}

.reactivate-product-dialog .heading .icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  width: 24px;
  height: 24px;
  float: right;
  margin-top: 23px;
  margin-right: 10px;
}
.reactivate-product-dialog .heading .icon:hover {
  background-color: rgba(33, 33, 33, 0.19);
  border-radius: 50px;
}
.reactivate-product-dialog .MuiDialogContent-root {
  padding-left: 45px;
  padding-right: 75px;
}
.reactivate-product-dialog .MuiDialogContent-root .reactivate p {
  font-size: 20px;
  font-weight: 400;
}
.reactivate-product-dialog .MuiDialogContent-root .reactivate .reactivate-btn {
  border-radius: 9px;
  min-width: 137px;
  padding: 5px 5px;
  min-height: 48px;
  font-size: 18px;
  font-weight: 500;
  margin-top: 22px;
}
.reactivate-product-dialog .MuiDialogContent-root .reactivate .header {
  color: #212121;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 31px;
}
.reactivate-product-dialog .MuiDialogContent-root .reactivate .header h3 {
  font-size: 24px;
  font-weight: 600;
}
.reactivate-product-dialog .MuiDialogContent-root .reactivate .header .cancelled-right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.reactivate-product-dialog .MuiDialogContent-root .reactivate .header .cancelled-right .icon {
  color: #ba0000;
  width: 17px;
  height: 17px;
}
.reactivate-product-dialog .MuiDialogContent-root .reactivate .description {
  margin-top: 33px;
  font-size: 12px;
  font-weight: 400;
}
.reactivate-product-dialog .MuiDialogContent-root .reactivate .description a {
  color: #0034bb;
  text-decoration: underline;
  cursor: pointer;
}
.reactivate-product-dialog .MuiDialogActions-root {
  padding-left: 49px;
  padding-right: 47px;
  padding-top: 30px;
  width: 100%;
}
.reactivate-product-dialog .MuiDialogActions-root .btn-outlined-v2,
.reactivate-product-dialog .MuiDialogActions-root .btn-contained-v2 {
  min-width: 110px;
  border-radius: 9px;
  padding: 5px 19px;
  font-size: 18px;
  min-height: 46px;
}

.drawer-resume .MuiDrawer-paper {
  border-radius: 0px !important;
  min-width: 65%;
  padding: 15px 40px;
  justify-content: unset;
  padding-bottom: 0px !important;
}
.drawer-resume .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  margin-left: -10px;
  margin-right: -8px;
  margin-bottom: 5px;
}
.drawer-resume .top .icons .icon {
  color: #212121;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.drawer-resume .top .icons:hover {
  background: rgba(0, 52, 187, 0.05);
}
.drawer-resume .title-download {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
}
.drawer-resume .title-download .drawer-title {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.drawer-resume .title-download .download {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  gap: 2px;
  color: rgb(33, 33, 33);
}
.drawer-resume .title-download .download:hover {
  background: rgba(0, 52, 187, 0.05);
}
.drawer-resume .pdf-container {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 12px 12px 0px 0px;
  border-bottom: none;
}
.drawer-resume .pdf-container ::-webkit-scrollbar {
  width: 0px;
  width: none;
}
.drawer-resume .pdf-container .zoom {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  gap: 13px;
  z-index: 2;
  padding: 9px 15px;
  border-radius: 5px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  box-shadow: 0px 4px 11.3px 0px rgba(33, 33, 33, 0.05);
  top: 25px;
  right: 32px;
  min-width: 142px;
  min-height: 44px;
}
.drawer-resume .pdf-container .zoom p {
  font-size: 14px;
  font-weight: 500;
}
.drawer-resume .pdf-container .zoom .icons {
  width: 23px;
  height: 23px;
  cursor: pointer;
}
.drawer-resume .pdf-container .container {
  height: 76vh;
  display: flex;
  justify-content: center;
  justify-items: center;
}
.drawer-resume .pdf-container .container .inner-container {
  width: 100%;
  overflow: scroll;
  display: flex;
  justify-content: center;
  justify-items: center;
  padding: 50px 0px;
  padding-top: 10px;
}
.drawer-resume .pdf-container .container .inner-container ::-webkit-scrollbar {
  width: 0px;
  width: none;
}
.drawer-resume .pdf-container .container .page {
  position: absolute;
  bottom: 0px;
  width: 300px;
  height: 80px;
  text-align: center;
  border-bottom: none;
  background: transparent;
}
.drawer-resume .pdf-container .container .page .page-number {
  border-radius: 5px;
  background: transparent;
  padding: 8px 25px;
}
.drawer-resume .pdf-container .container .page .page-number .icons {
  width: 0px;
  height: 0px;
}
.drawer-resume .pdf-container .container .page .page-number h1 {
  font-size: 0px;
}
.drawer-resume .pdf-container .container .page .page-number:hover {
  background: rgba(33, 33, 33, 0.7);
  display: flex;
  align-items: center;
  gap: 20px;
}
.drawer-resume .pdf-container .container .page .page-number:hover .icons {
  width: 23px;
  height: 23px;
  color: #ffffff;
  cursor: pointer;
}
.drawer-resume .pdf-container .container .page .page-number:hover h1 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}
.drawer-resume .pdf-container .container .page .page-number:hover h1 span {
  color: rgba(255, 255, 255, 0.6);
}
.drawer-resume .pdf-container .container .page:hover .page-number {
  width: 210px;
  cursor: pointer;
  margin: auto;
  background: rgba(33, 33, 33, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.drawer-resume .pdf-container .container .page:hover .page-number .icons {
  width: 23px;
  height: 23px;
  color: #ffffff;
  cursor: pointer;
}
.drawer-resume .pdf-container .container .page:hover .page-number h1 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}
.drawer-resume .pdf-container .container .page:hover .page-number h1 span {
  color: rgba(255, 255, 255, 0.6);
}
.drawer-resume .pdf-container .hide-scroll {
  overflow: hidden;
}

.bill-payments .payment-failed {
  border-radius: 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 12px 19px;
  justify-content: space-between;
  min-height: 51px;
  margin-top: 11px;
  background: rgba(234, 180, 100, 0.21);
}
.bill-payments .payment-failed .left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.bill-payments .payment-failed p {
  font-weight: 400;
}
.bill-payments .payment-failed .icon {
  color: #f1ae49;
  min-width: 28px;
  min-height: 26px;
}
.bill-payments .payment-failed a {
  font-weight: 700;
  text-decoration: underline;
}
.bill-payments .payment-method .table {
  padding: 24px 30px 29px 17px;
}
.bill-payments .payment-method .table .payment-card {
  border-radius: 16px;
  min-width: 284px;
}
.bill-payments .payment-method .table .payment-card .top {
  background-color: #c4daee;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 16px 16px 0px 0px;
  padding: 7px 11px 9px 22px;
  overflow: hidden;
}
.bill-payments .payment-method .table .payment-card .top .menu-container {
  align-self: flex-end;
  margin-right: 15px;
  z-index: 1;
}
.bill-payments .payment-method .table .payment-card .top .image-logo {
  width: 43.92px;
  height: 27.45px;
  margin-top: -9px;
  margin-bottom: 16px;
}
.bill-payments .payment-method .table .payment-card .top h3 {
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  z-index: 5;
}
.bill-payments .payment-method .table .payment-card .top .bg-img {
  position: absolute;
  top: 0;
  left: 7px;
}
.bill-payments .payment-method .table .payment-card .bottom {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 29px 15px 22px;
  border: 1px solid rgba(33, 33, 33, 0.11);
  border-radius: 0px 0px 16px 16px;
}
.bill-payments .payment-method .table .payment-card .bottom .left {
  margin-top: 8px;
}
.bill-payments .payment-method .table .payment-card .bottom .left span {
  color: #191b1f;
  font-size: 11px;
  font-weight: 600;
  opacity: 0.8;
}
.bill-payments .payment-method .table .payment-card .bottom .left h3 {
  font-size: 11px;
  font-weight: 700;
}
.bill-payments .payment-method .table .payment-card .bottom img {
  width: 58px;
  height: 36px;
}
.bill-payments .payment-method .table .top-section .section-heading {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.bill-payments .payment-method .table .top-section .section-heading .card-title {
  color: #000;
  font-size: 22px;
  font-weight: 600;
  margin-left: 4px;
  margin-top: -17px;
}
.bill-payments .payment-method .table .top-section .section-heading .btn-contained-v2 {
  min-width: 231px;
  border-radius: 9px;
  padding: 6px 10px;
  font-size: 18px;
  min-height: 48px;
  margin-top: 3px;
}
.bill-payments .payment-method .table .top-section .section-container {
  border-radius: 15px;
  max-width: 314px;
  min-height: 220px;
  border: 0.5px solid rgba(33, 33, 33, 0.2);
  background: #FFF;
  padding: 6px 18px 7px 11px;
}
.bill-payments .payment-method .table .top-section .section-container .wrapper h4 {
  color: #212121;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
}
.bill-payments .payment-method .table .top-section .section-container .wrapper .card-expired {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #872b17;
  font-size: 10px;
  font-weight: 400;
  margin-top: 10px;
  margin-inline: auto;
  gap: 5px;
  border-radius: 15px;
  background: rgba(135, 43, 23, 0.16);
  padding: 4px 6px 4px 4px;
  max-width: 202px;
}
.bill-payments .payment-method .table .top-section .section-container .wrapper .card-expired .icon {
  width: 13px;
  height: 13px;
}
.bill-payments .payment-method .table .top-section .section-container .wrapper .card-expired span a {
  text-decoration: underline;
  cursor: pointer;
}
.bill-payments .payment-method .table .bottom-section .card-title {
  color: #000;
  font-size: 22px;
  font-weight: 600;
  margin-top: 36px;
}
.bill-payments .payment-method .table .bottom-section .payment-grid {
  margin-top: 14px;
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
}
.bill-payments .payment-method .table .bottom-section .payment-grid .card-expired {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #872b17;
  font-size: 10px;
  font-weight: 400;
  margin-top: 10px;
  margin-inline: auto;
  gap: 5px;
  border-radius: 15px;
  background: rgba(135, 43, 23, 0.16);
  padding: 4px 6px 4px 4px;
  max-width: 202px;
}
.bill-payments .payment-method .table .bottom-section .payment-grid .card-expired .icon {
  width: 13px;
  height: 13px;
}
.bill-payments .payment-method .table .bottom-section .payment-grid .card-expired span a {
  text-decoration: underline;
  cursor: pointer;
}

.bill-license .my-products-header .bottom-header .wrapper {
  justify-content: flex-end;
}
.bill-license .table {
  border-radius: 15px 15px 15px 15px;
}

.ecn-datagrid-license .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-indeterminate {
  color: #0034bb;
  width: 40px !important;
  height: 40px !important;
}
.ecn-datagrid-license .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
  cursor: pointer;
}
.ecn-datagrid-license .MuiDataGrid-menuIcon {
  margin-right: 37px !important;
}
.ecn-datagrid-license .available-license {
  align-items: center;
  gap: 12px;
}
.ecn-datagrid-license .available-license .linear-progress {
  min-width: 160px;
  height: 10px;
}

.billing-license-account-dialog .MuiDialogContent-root {
  padding-left: 45px;
  padding-right: 70px;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-top {
  margin-bottom: 29px;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-top .dialog-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-top .dialog-head h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 19px;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-top .dialog-head .close-icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  width: 24px;
  height: 24px;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-top .dialog-head .close-icon:hover {
  background-color: rgba(33, 33, 33, 0.19);
  border-radius: 50px;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-top h4 {
  font-size: 20px;
  font-weight: 700;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-top h4 span {
  font-size: 32px;
  font-weight: 700;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-top .progress {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-top .progress p {
  font-size: 18px;
  font-weight: 400;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-top .progress .MuiLinearProgress-root {
  width: 89%;
  height: 15px;
  background-color: rgba(0, 52, 187, 0.14);
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-top .assigned {
  font-size: 16px;
  font-weight: 400;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-bottom {
  height: auto;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-bottom .title {
  font-size: 18px;
  font-weight: 500;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-bottom .chip-title {
  font-weight: 500;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-bottom .chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 18px;
  margin-bottom: 41px;
  text-transform: capitalize;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-bottom .chip-container .more-btn {
  border-radius: 30px;
  font-weight: 400;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-bottom .dropdown .assign-select {
  margin-bottom: 26px;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-bottom .dropdown .assign-select label {
  font-size: 14px;
  line-height: 171%;
  margin-bottom: 2px;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-bottom .dropdown .note {
  display: flex;
  gap: 10px;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-bottom .dropdown .note .info-icon {
  height: 18px;
  width: 18px;
  margin-top: 2px;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-bottom .dropdown .note .text .main,
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-bottom .dropdown .note .text .sub-point {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.9);
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-bottom .dropdown .note .text .main {
  margin-bottom: 16px;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .license-account-bottom .dropdown .note .text .sub-point span {
  font-weight: 600;
}
.billing-license-account-dialog .MuiDialogContent-root .license-account .btn-group .btn-contained-v2 {
  font-size: 18px;
  min-height: 44px;
  border-radius: 9px;
  width: 100%;
}
.billing-license-account-dialog .MuiDialogActions-root {
  padding-left: 49px;
  padding-right: 47px;
  padding-top: 30px;
  width: 100%;
}
.billing-license-account-dialog .MuiDialogActions-root .btn-outlined-v2,
.billing-license-account-dialog .MuiDialogActions-root .btn-contained-v2 {
  border-radius: 9px;
  padding: 5px 19px;
  font-size: 18px;
  min-width: 154px;
  min-height: 44px;
}

.bill-account .bill-table {
  color: #212121;
  padding: 37px 36px 37px 39px;
}
.bill-account .bill-table .bill-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bill-account .bill-table .bill-header h3 {
  font-size: 20px;
  font-weight: 600;
  margin-top: -6px;
}
.bill-account .bill-table .bill-header .btn-outlined-v2 {
  gap: 8px;
  border-radius: 9px;
  padding: 6px 19px;
  font-size: 18px;
  min-width: 100px;
  min-height: 44px;
  font-weight: 600;
}
.bill-account .bill-table .bill-header .btn-outlined-v2 .icon {
  color: #0034bb;
}
.bill-account .bill-table .container .row {
  margin-bottom: 22px;
}
.bill-account .bill-table .container .row .field {
  font-size: 18px;
  font-weight: 500;
}
.bill-account .bill-table .container .row .value {
  font-size: 16px;
  font-weight: 400;
}
.bill-account .bill-table .container .row .value span {
  text-decoration: underline;
  color: #00f;
  cursor: pointer;
}
.bill-account .bill-table .container .row .chip-container {
  margin-top: 22px;
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
}

.billing-account-dialog .license-account .license-account-top {
  margin-bottom: 17px !important;
}
.billing-account-dialog .license-account .license-account-top h3 {
  margin-bottom: 9px !important;
}
.billing-account-dialog .license-account .license-account-bottom .chip-container {
  margin-top: 9px !important;
  margin-bottom: 15px !important;
}

.edit-account-dialog .heading .icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  width: 24px;
  height: 24px;
  float: right;
  margin-top: 23px;
  margin-right: 10px;
}
.edit-account-dialog .heading .icon:hover {
  background-color: rgba(33, 33, 33, 0.19);
  border-radius: 50px;
}
.edit-account-dialog .edit-billing-account {
  min-height: 100%;
}
.edit-account-dialog .edit-billing-account .header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 31px;
}
.edit-account-dialog .edit-billing-account .success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 30px 27px 30px 28px;
  height: 75vh;
  background: #ffffff;
}
.edit-account-dialog .edit-billing-account .success img {
  height: 255px;
  width: 348px;
}
.edit-account-dialog .edit-billing-account .success h3 {
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 30px;
}
.edit-account-dialog .edit-billing-account .success .btn-contained-v2 {
  border-radius: 7px;
  min-width: 225px;
  min-height: 44px;
  font-size: 16px;
}
.edit-account-dialog .edit-billing-account .container {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 30px 27px 30px 28px;
  min-height: 48vh;
  background: #ffffff;
}
.edit-account-dialog .edit-billing-account .container .row {
  gap: 20px;
  margin-top: 17px;
  flex-direction: row;
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
}
.edit-account-dialog .edit-billing-account .container .row .field {
  width: 50%;
}
.edit-account-dialog .edit-billing-account .container .row .field-full {
  width: 100%;
}
.edit-account-dialog .edit-billing-account .container .row .ecn-input-legal .MuiOutlinedInput-root {
  padding: 1px 1px;
}
.edit-account-dialog .edit-billing-account .container .row p {
  font-size: 14px;
  font-weight: 500;
}
.edit-account-dialog .MuiDialogActions-root {
  padding-left: 49px;
  padding-right: 47px;
  padding-top: 30px;
  width: 100%;
}
.edit-account-dialog .MuiDialogActions-root .btn-outlined-v2,
.edit-account-dialog .MuiDialogActions-root .btn-contained-v2 {
  min-width: 190px;
  border-radius: 9px;
  padding: 5px 19px;
  font-size: 18px;
  min-height: 48px;
}

.product-detail {
  margin-left: 10px;
  background: #fdfdfd;
}
.product-detail .product-detail-header .top-header {
  margin-top: 39px;
}
.product-detail .product-detail-header .top-header h1 {
  color: #212121;
  font-size: 24px;
  font-weight: 600;
}
.product-detail .product-detail-header .top-header .sub-heading {
  font-size: 16px;
  font-weight: 400;
}
.product-detail .product-detail-header .body .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 49px;
  margin-top: 34px;
}
.product-detail .product-detail-header .body .container a {
  color: #00f;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}
.product-detail .product-detail-header .body .container h3 {
  font-size: 18px;
  font-weight: 600;
}
.product-detail .product-detail-header .body .container p {
  font-size: 14px;
  font-weight: 400;
}
.product-detail .product-detail-header .body .container .first-column {
  display: flex;
  flex-direction: column;
}
.product-detail .product-detail-header .body .container .first-column .licenses-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 75px;
}
.product-detail .product-detail-header .body .container .first-column .licenses-container h3 {
  margin-bottom: 11px;
}
.product-detail .product-detail-header .body .container .first-column .licenses-container .MuiLinearProgress-root {
  width: 89%;
  height: 15px;
  background-color: rgba(0, 52, 187, 0.14);
  margin-bottom: 14px;
}
.product-detail .product-detail-header .body .container .first-column .payment-settings-container h3 {
  margin-bottom: 14px;
}
.product-detail .product-detail-header .body .container .first-column .payment-settings-container h4 {
  font-size: 14px;
  font-weight: 600;
}
.product-detail .product-detail-header .body .container .first-column .payment-settings-container .payment-setting-body {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.product-detail .product-detail-header .body .container .first-column .payment-settings-container .payment-setting-body .edit-recurring {
  margin-bottom: 21px;
  margin-top: 33px;
}
.product-detail .product-detail-header .body .container .first-column .payment-settings-container .payment-setting-body .edit-payment {
  margin-bottom: 21px;
  margin-top: 33px;
}
.product-detail .product-detail-header .body .container .second-column .subscription-status {
  margin-bottom: 77px;
}
.product-detail .product-detail-header .body .container .second-column .subscription-status .active {
  display: flex;
  align-items: center;
  gap: 8px;
}
.product-detail .product-detail-header .body .container .second-column .subscription-status .active .icon {
  width: 15px;
  height: 15px;
  color: rgba(43, 186, 29, 0.69);
}
.product-detail .product-detail-header .body .container .second-column .subscription-status .active .cancel-icon {
  color: #ba0000;
}
.product-detail .product-detail-header .body .container .second-column .purchase-information {
  margin-bottom: 80px;
}
.product-detail .product-detail-header .body .container .second-column .purchase-information .initial-purchase {
  font-weight: 600;
}
.product-detail .product-detail-header .body .container .third-column h3 {
  margin-bottom: 10px;
}
.product-detail .product-detail-header .body .container .third-column .billing-address {
  margin-bottom: 44px;
}
.product-detail .product-detail-header .body .container .third-column .product-details {
  margin-bottom: 72px;
}

.checkout {
  margin-inline: 44px;
  width: calc(100% - 88px);
  padding-bottom: 50px;
}
.checkout .header-breadcrumbs {
  margin-top: 40px;
  margin-bottom: 25px;
}
.checkout .header-breadcrumbs .top {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.checkout .header-breadcrumbs .top .icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.checkout .header-breadcrumbs .top p {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.checkout .header-breadcrumbs .top p:last-child {
  color: #212121;
}
.checkout .header-breadcrumbs .top .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.checkout .body {
  display: flex;
  column-gap: 25px;
  justify-content: space-between;
  align-items: flex-start;
}
.checkout .body .bill-details {
  flex: 0.69;
}
.checkout .body .bill-details-card {
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 25px;
}
.checkout .body .bill-details-card .heading {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
}
.checkout .body .bill-details-card .add-btn {
  padding: 11px 18px 11px 5px;
}
.checkout .body .bill-details .purchased {
  margin-bottom: 18px;
}
.checkout .body .bill-details .purchased .purchase-data .address {
  display: flex;
  gap: 95px;
  margin-top: 26px;
}
.checkout .body .bill-details .purchased .purchase-data .address .left {
  height: fit-content;
  max-width: 200px;
}
.checkout .body .bill-details .purchased .purchase-data .address .right {
  height: fit-content;
}
.checkout .body .bill-details .purchased .purchase-data .address .head {
  color: #212121;
  font-weight: 600;
  margin-bottom: 6px;
}
.checkout .body .bill-details .purchased .purchase-data .address .sub {
  color: #212121;
}
.checkout .body .bill-details .purchased .purchase-data .edit-btn {
  padding: 11px 4px;
  margin-top: 18px;
}
.checkout .body .bill-details .payment .payment-data {
  margin-top: 26px;
}
.checkout .body .bill-details .payment .payment-data .card-wrapper .card {
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding: 16px 12px;
  display: flex;
  gap: 16px;
  align-items: flex-start;
  margin-bottom: 18px;
}
.checkout .body .bill-details .payment .payment-data .card-wrapper .card .radio-btn {
  padding: 0;
  color: rgba(33, 33, 33, 0.2);
}
.checkout .body .bill-details .payment .payment-data .card-wrapper .card .radio-btn.Mui-checked {
  color: #0034bb;
}
.checkout .body .bill-details .payment .payment-data .card-wrapper .card .card-details .head {
  font-size: 14px;
  color: #212121;
  font-weight: 600;
}
.checkout .body .bill-details .payment .payment-data .card-wrapper .card .card-details .sub {
  color: #212121;
  margin-top: 1px;
}
.checkout .body .bill-details .payment .payment-data .card-wrapper .selected-card {
  border-radius: 8px;
  border: 1px solid #0034bb;
}
.checkout .body .bill-details .divider {
  margin: 25px 0;
  height: 1px;
  background-color: rgba(33, 33, 33, 0.2);
}
.checkout .body .bill-details .coupon .heading {
  font-size: 14px;
  color: #212121;
  line-height: 24px;
}
.checkout .body .bill-details .coupon .row .coupon-input {
  max-width: 280px;
}
.checkout .body .bill-details .coupon .row .coupon-input .MuiInputBase-root {
  font-size: 14px;
}
.checkout .body .bill-details .coupon .row .coupon-input .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 14px;
  line-height: 24px;
}
.checkout .body .bill-details .coupon .row .coupon-input .MuiInputBase-root::placeholder {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.3);
}
.checkout .body .bill-details .coupon .row .coupon-btn {
  margin-left: 14px;
  padding: 8px 18px;
  border-radius: 7px;
  width: 133px;
  max-height: 40px;
}
.checkout .body .bill-details .coupon .success {
  font-size: 14px;
  font-weight: 500;
  color: #027A48;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-top: 4px;
}
.checkout .body .bill-details .coupon .error {
  font-size: 14px;
  font-weight: 500;
  color: #ba0000;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-top: 4px;
}
.checkout .body .product-details {
  flex: 0.31;
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 8px;
  padding: 25px;
}
.checkout .body .product-details .heading {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 14px;
}
.checkout .body .product-details .quantity {
  background: rgba(0, 52, 187, 0.05);
  border-radius: 8px;
  padding: 16px;
}
.checkout .body .product-details .quantity .row {
  display: flex;
  justify-content: space-between;
}
.checkout .body .product-details .quantity .row .left .plan {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  max-width: 191px;
}
.checkout .body .product-details .quantity .row .left .edit-note {
  color: rgba(33, 33, 33, 0.7);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  max-width: 191px;
}
.checkout .body .product-details .quantity .row .edit-btn {
  margin-right: 8px;
}
.checkout .body .product-details .quantity .row .num-input {
  display: flex;
  align-items: center;
  column-gap: 6px;
}
.checkout .body .product-details .quantity .row .num-input .num-icon {
  cursor: pointer;
}
.checkout .body .product-details .quantity .row .num-input .license-input {
  max-width: 45px;
}
.checkout .body .product-details .quantity .row .num-input .license-input .MuiInputBase-root .MuiInputBase-input {
  text-align: center;
  font-size: 14px;
}
.checkout .body .product-details .quantity .num {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  margin-top: 6px;
}
.checkout .body .product-details .quantity .btn-wrapper {
  text-align: right;
}
.checkout .body .product-details .quantity .btn-wrapper .MuiLoadingButton-loading {
  margin-right: 20px;
}
.checkout .body .product-details .quantity .btn-wrapper .MuiLoadingButton-loadingIndicator {
  right: -20px;
}
.checkout .body .product-details .summary {
  margin-top: 25px;
}
.checkout .body .product-details .summary .sum {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 14px;
}
.checkout .body .product-details .summary .sub {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #212121;
  margin-bottom: 14px;
}
.checkout .body .product-details .summary .sub .discount {
  color: #027a48;
}
.checkout .body .product-details .summary .dis {
  margin-bottom: 6px;
}
.checkout .body .product-details .summary .note {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.6);
  font-weight: 500;
  margin-bottom: 14px;
}
.checkout .body .product-details .summary .divider {
  background: rgba(33, 33, 33, 0.2);
  height: 1px;
  margin-bottom: 14px;
}
.checkout .body .product-details .summary .total {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  color: #212121;
}
.checkout .body .product-details .pay-btn {
  margin-top: 45px;
  width: 100%;
  padding: 10px 18px;
  box-shadow: none;
}
.checkout .body .product-details .pay-btn:disabled {
  background: rgba(0, 52, 187, 0.6) !important;
}

.checkout-error {
  width: 100%;
  text-align: center;
}
.checkout-error img {
  width: 250px;
  height: 250px;
  margin-top: 130px;
}
.checkout-error p {
  color: #212121;
  font-weight: 500;
}
.checkout-error .home-btn {
  margin-top: 23px;
  padding: 10px 18px;
  width: 340px;
  color: #212121;
  font-weight: 500;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
}

.billing-mobile-input {
  height: auto;
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding-right: 2px;
}
.billing-mobile-input:hover, .billing-mobile-input:focus, .billing-mobile-input:focus-within {
  border-color: rgba(0, 52, 187, 0.7);
}
.billing-mobile-input input {
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
  color: #212121;
}
.billing-mobile-input .form-control {
  font-size: 16px;
  font-weight: 500;
  width: 91%;
  border-radius: 8px;
  margin-left: 26px;
  border: unset;
  border-left: none;
  height: 43px;
}
.billing-mobile-input .flag-dropdown {
  border-radius: 8px 0 0 8px;
  border-right: none;
  border: unset;
  background: rgba(255, 255, 255, 0.8);
}
.billing-mobile-input .flag-dropdown.open {
  border-radius: 8px;
}
.billing-mobile-input .flag-dropdown.open:hover, .billing-mobile-input .flag-dropdown.open:focus, .billing-mobile-input .flag-dropdown.open:focus-within {
  border-color: rgba(0, 52, 187, 0.7);
}
.billing-mobile-input .flag-dropdown.open .selected-flag {
  border-radius: 8px;
}
.billing-mobile-input .flag-dropdown.open .country-list {
  border-radius: 8px;
  text-align: left;
  padding: 11px 10px;
}
.billing-mobile-input .flag-dropdown.open .country-list .country {
  padding: 12px 20px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.billing-mobile-input .flag-dropdown.open .country-list .country:last-child {
  margin-bottom: 0;
}
.billing-mobile-input .flag-dropdown.open .country-list .highlight {
  background: rgba(0, 52, 187, 0.05);
}
.billing-mobile-input .flag-dropdown .selected-flag {
  padding: 0 0 0 19px;
  background-color: unset;
  margin-right: 26px;
}
.billing-mobile-input .flag-dropdown .selected-flag .flag .arrow {
  border: none;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 6L8 10L12 6" stroke="%23212121" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  top: unset;
  left: 22px;
}

.notifications-page {
  margin-left: 3px;
}
.notifications-page .heading {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 0;
  margin-top: 75px;
}
.notifications-page .heading h4 {
  color: #212121;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}
.notifications-page .heading p {
  font-size: 10px;
  font-weight: 800;
  line-height: 16px;
  background: #0034bb;
  padding: 1px 5px;
  border-radius: 50%;
  color: #fff;
  height: 19px;
  width: 19px;
  text-align: center;
}
.notifications-page .button-group {
  text-align: right;
  margin-bottom: 31px;
}
.notifications-page .button-group .filter-btn {
  border-radius: 7px !important;
  border: 1px solid rgba(33, 33, 33, 0.2) !important;
  padding: 7px 20px !important;
  color: #212121;
}
.notifications-page .button-group .sort-btn {
  margin-right: 10px;
}
.notifications-page .container {
  padding: 38px 25px;
  border-radius: 15px;
  border: 0.5px solid rgba(33, 33, 33, 0.1);
  margin-bottom: 50px;
}
.notifications-page .container .notification-wrapper {
  margin-bottom: 8px;
  margin-left: -3px;
  padding: 12px;
  position: relative;
}
.notifications-page .container .notification-wrapper:hover {
  border-radius: 10px;
  background: rgba(0, 52, 187, 0.03);
}
.notifications-page .container .notification-wrapper:last-child {
  margin-bottom: 0;
}
.notifications-page .container .notification-wrapper .message {
  color: #212121;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
}
.notifications-page .container .notification-wrapper .time {
  color: #212121;
  font-size: 12px;
  line-height: 18px;
}
.notifications-page .container .notification-wrapper::before {
  content: "";
  position: absolute;
  width: 3px;
  height: calc(100% - 24px);
  background: transparent;
  border-radius: 8px;
  left: 0;
}
.notifications-page .container .unread {
  margin-left: 0;
  padding-left: 28px;
}
.notifications-page .container .unread .message {
  font-weight: 500;
}
.notifications-page .container .unread::before {
  content: "";
  position: absolute;
  width: 3px;
  height: calc(100% - 24px);
  background: #0034bb;
  border-radius: 8px;
  left: 12px;
  top: 12px;
  bottom: 12px;
}
.notifications-page .container .fallback {
  text-align: center;
}
.notifications-page .container .fallback img {
  max-height: 460px;
}
.notifications-page .container .fallback p {
  color: #212121;
  font-size: 19px;
  font-weight: 500;
}

.notification-filter-menu .MuiPaper-root {
  border-radius: 6px;
  box-shadow: 0px 7px 30px 7px rgba(0, 0, 0, 0.1);
  min-width: 230px;
}
.notification-filter-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root {
  border-radius: 10px;
  padding: 12px 20px;
  text-transform: capitalize;
  margin-bottom: 0;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #212121;
}
.notification-filter-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover {
  background: rgba(0, 52, 187, 0.05);
}
.notification-filter-menu .MuiPaper-root .MuiList-root .selected {
  background: rgba(0, 52, 187, 0.05);
  color: #0034bb;
  font-weight: 600 !important;
}

.global-search .header-breadcrumbs {
  margin-top: 40px;
  margin-bottom: 25px;
}
.global-search .header-breadcrumbs .top {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.global-search .header-breadcrumbs .top .icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.global-search .header-breadcrumbs .top p {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.global-search .header-breadcrumbs .top p:last-child {
  color: #212121;
}
.global-search .header-breadcrumbs .top .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.global-search .main-heading {
  font-size: 24px;
  color: #212121;
  font-weight: 600;
  line-height: 1;
}
.global-search .result-count {
  color: #212121;
}
.global-search .results-wrapper {
  max-width: 80%;
}
.global-search .results-wrapper .search-group {
  margin-top: 25px;
  margin-bottom: 0;
}
.global-search .results-wrapper .search-group .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(33, 33, 33, 0.15);
}
.global-search .results-wrapper .search-group .head .group-heading {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  line-height: 1.5;
  margin-bottom: 10px;
  text-transform: capitalize;
  padding-top: 10px;
}
.global-search .results-wrapper .search-group .head .group-result-count {
  color: #0034bb;
  font-size: 13px;
  line-height: normal;
  text-decoration-line: underline;
}
.global-search .results-wrapper .search-group .search-results .result {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 2px;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
}
.global-search .results-wrapper .search-group .search-results .result:last-child {
  margin-bottom: 0;
}
.global-search .results-wrapper .search-group .search-results .result:hover {
  background: rgba(0, 52, 187, 0.03);
}
.global-search .results-wrapper .search-group .search-results .result .avatar {
  height: 32px;
  width: 32px;
  font-size: 13.2px;
  font-weight: 500;
}
.global-search .results-wrapper .search-group .search-results .result .right {
  height: fit-content;
}
.global-search .results-wrapper .search-group .search-results .result .right .heading {
  font-size: 16px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.8);
  line-height: 1;
}
.global-search .results-wrapper .search-group .search-results .result .right .sub {
  font-size: 12px;
  color: rgba(33, 33, 33, 0.7);
}

.global-search-fallback {
  text-align: center;
  margin-top: 100px;
}
.global-search-fallback img {
  max-width: 350px;
  height: auto;
}
.global-search-fallback p {
  color: #212121;
  font-weight: 500;
}

.dashboard .header-breadcrumbs {
  margin-top: 40px;
  margin-bottom: 25px;
}
.dashboard .header-breadcrumbs .top {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.dashboard .header-breadcrumbs .top .icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.dashboard .header-breadcrumbs .top p {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.dashboard .header-breadcrumbs .top p:last-child {
  color: #212121;
}
.dashboard .header-breadcrumbs .top .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.dashboard .body .hero {
  display: flex;
  column-gap: 25px;
}
.dashboard .body .hero .card {
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 35px 25px 42px 25px;
  border-radius: 14px;
}
.dashboard .body .hero .card h2 {
  font-size: 32px;
  color: #212121;
  font-weight: 600;
  line-height: 131%;
  margin-bottom: 8px;
}
.dashboard .body .hero .card p {
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
}
.dashboard .body .hero .marketing {
  height: 250px;
  width: 250px;
}
.dashboard .body .hero .marketing .placeholder img {
  height: 250px;
  width: 250px;
  border-radius: 14px;
}
.dashboard .body .row {
  display: flex;
  column-gap: 25px;
  margin-top: 25px;
}
.dashboard .body .row .users {
  flex: 0.52;
  border-radius: 14px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 22px 24px;
}
.dashboard .body .row .users .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard .body .row .users .head h3 {
  color: #212121;
  font-size: 24px;
  font-weight: 600;
  line-height: 133%;
  letter-spacing: -0.48px;
}
.dashboard .body .row .users .head .hero-btn {
  font-size: 14px;
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  border-radius: 10px;
}
.dashboard .body .row .users .users-wrapper {
  margin-top: 20px;
}
.dashboard .body .row .users .users-wrapper .user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding: 10px 0;
  margin-top: 2px;
  margin: 0;
}
.dashboard .body .row .users .users-wrapper .user .data {
  display: flex;
  align-items: center;
  column-gap: 20px;
  cursor: pointer;
}
.dashboard .body .row .users .users-wrapper .user .data .avatar {
  height: 42px;
  width: 42px;
  background: #0034bb;
}
.dashboard .body .row .users .users-wrapper .user .data .text .name {
  font-weight: 600;
  line-height: 175%;
  color: #212121;
}
.dashboard .body .row .users .users-wrapper .user .data .text .des {
  font-size: 12px;
  color: rgba(33, 33, 33, 0.6);
  line-height: 166%;
  letter-spacing: -0.24px;
  margin-top: -4px;
}
.dashboard .body .row .users .users-wrapper .user .license .count {
  font-size: 14px;
  color: #212121;
  font-weight: 500;
}
.dashboard .body .row .users .users-wrapper .user-fallback {
  text-align: center;
  height: 180px;
  padding-top: 50px;
}
.dashboard .body .row .users .users-wrapper .user-fallback .text {
  font-size: 13px;
  color: rgba(33, 33, 33, 0.6);
}
.dashboard .body .row .users .users-wrapper .user-fallback .add-btn {
  font-size: 13px;
  text-decoration: underline;
  border-radius: 10px;
  color: #212121;
}
.dashboard .body .row .groups {
  flex: 0.52;
  border-radius: 14px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 22px 24px;
}
.dashboard .body .row .groups .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard .body .row .groups .head h3 {
  color: #212121;
  font-size: 24px;
  font-weight: 600;
  line-height: 133%;
  letter-spacing: -0.48px;
}
.dashboard .body .row .groups .head .hero-btn {
  font-size: 14px;
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  border-radius: 10px;
}
.dashboard .body .row .groups .groups-wrapper {
  margin-top: 20px;
}
.dashboard .body .row .groups .groups-wrapper .group {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-top: 2px;
  padding: 12px 0;
  cursor: pointer;
}
.dashboard .body .row .groups .groups-wrapper .group .avatar {
  height: 42px;
  width: 42px;
  background: #0034bb;
}
.dashboard .body .row .groups .groups-wrapper .group .text .name {
  font-weight: 600;
  line-height: 175%;
  color: #212121;
}
.dashboard .body .row .groups .groups-wrapper .group .text .desc {
  font-size: 12px;
  color: rgba(33, 33, 33, 0.6);
  line-height: 166%;
  letter-spacing: -0.24px;
  margin-top: -4px;
}
.dashboard .body .row .groups .groups-wrapper .group-fallback {
  text-align: center;
  height: 180px;
  padding-top: 50px;
}
.dashboard .body .row .groups .groups-wrapper .group-fallback .text {
  font-size: 13px;
  color: rgba(33, 33, 33, 0.6);
}
.dashboard .body .row .groups .groups-wrapper .group-fallback .add-btn {
  font-size: 13px;
  text-decoration: underline;
  border-radius: 10px;
  color: #212121;
}
.dashboard .body .activities {
  border-radius: 14px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 22px 24px;
  margin-top: 25px;
  margin-bottom: 60px;
}
.dashboard .body .activities .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard .body .activities .head h3 {
  color: #212121;
  font-size: 24px;
  font-weight: 600;
  line-height: 133%;
  letter-spacing: -0.48px;
}
.dashboard .body .activities .head .hero-btn {
  font-size: 14px;
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  border-radius: 10px;
}
.dashboard .body .activities .activities-wrapper {
  margin-top: 20px;
  margin-bottom: 7px;
}
.dashboard .body .activities .activities-wrapper .activities-list .MuiStep-vertical .MuiStepLabel-vertical .MuiStepLabel-iconContainer {
  padding-right: 0;
  width: 72px;
}
.dashboard .body .activities .activities-wrapper .activities-list .MuiStep-vertical .MuiStepLabel-vertical .MuiStepLabel-labelContainer {
  margin-left: 64.5px !important;
}
.dashboard .body .activities .activities-wrapper .activities-list .MuiStep-vertical .MuiStepLabel-vertical .MuiStepLabel-labelContainer .MuiStepLabel-label .step-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard .body .activities .activities-wrapper .activities-list .MuiStep-vertical .MuiStepLabel-vertical .MuiStepLabel-labelContainer .MuiStepLabel-label .step-content .base {
  display: flex;
  column-gap: 18px;
}
.dashboard .body .activities .activities-wrapper .activities-list .MuiStep-vertical .MuiStepLabel-vertical .MuiStepLabel-labelContainer .MuiStepLabel-label .step-content .base .details .head {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  line-height: 0.9;
}
.dashboard .body .activities .activities-wrapper .activities-list .MuiStep-vertical .MuiStepLabel-vertical .MuiStepLabel-labelContainer .MuiStepLabel-label .step-content .base .details .sub {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  line-height: 0.9;
  margin-top: 14px;
}
.dashboard .body .activities .activities-wrapper .activities-list .MuiStep-vertical .MuiStepLabel-vertical .MuiStepLabel-labelContainer .MuiStepLabel-label .step-content .tag .chip {
  color: #212121;
  font-size: 11px;
  font-weight: 700;
  font-style: italic;
  line-height: 145%;
  background: #f6f7f9;
  padding: 7px 0px 7px 12px;
}
.dashboard .body .activities .activities-wrapper .activities-list .MuiStepConnector-vertical {
  margin-left: 30px;
}
.dashboard .body .activities .activities-wrapper .activities-list .MuiStepConnector-vertical .MuiStepConnector-lineVertical {
  border-left-width: 1.5px;
  border-left-style: dashed;
  height: 28px;
}
.dashboard .body .activities .activities-wrapper .no-activities {
  text-align: center;
  margin: 15px 0;
  font-weight: 500;
  color: #212121;
}

.new-dashboard .btn-primary {
  background: #0034bb;
  padding: 12px 40px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  margin-top: 16px;
  border: none;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
}
.new-dashboard .btn-primary:hover {
  cursor: pointer;
}
.new-dashboard .dashboard-empty-data-state {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 13px;
  align-items: center;
  text-align: center;
}
.new-dashboard .dashboard-empty-data-state > span {
  color: rgba(33, 33, 33, 0.6);
  font-size: 16px;
  font-weight: 400;
}
.new-dashboard .body {
  padding-left: 1px;
}
.new-dashboard .body .dashboard-hero {
  margin-top: 34px;
  display: flex;
  column-gap: 25px;
  position: relative;
}
.new-dashboard .body .dashboard-hero .card {
  outline: 1px solid rgba(33, 33, 33, 0.2);
  padding: 30px 25px;
  border-radius: 14px;
  width: 66%;
  flex-grow: 1;
}
.new-dashboard .body .dashboard-hero .card span {
  font-weight: 600;
  font-size: 24px;
}
.new-dashboard .body .dashboard-hero .card .admin-hero-heading {
  font-size: 32px;
  color: #212121;
  font-weight: 600;
  line-height: 138%;
  margin-bottom: 8px;
}
.new-dashboard .body .dashboard-hero .card .onefinnet-description {
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
}
.new-dashboard .body .dashboard-hero .products {
  height: 100%;
}
.new-dashboard .body .dashboard-hero .products .products-title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 16px;
}
.new-dashboard .body .dashboard-hero .products .talent-product {
  width: 287px;
  height: 261px;
  border-radius: 8px;
  background: linear-gradient(180deg, #074cff 0%, #0034bb 100%);
  overflow: clip;
  position: relative;
}
.new-dashboard .body .dashboard-hero .products .talent-product .title {
  padding-top: 16px;
  line-height: 0.98;
  text-align: center;
}
.new-dashboard .body .dashboard-hero .products .talent-product .title .text > span {
  display: block;
}
.new-dashboard .body .dashboard-hero .products .talent-product .title .text {
  color: #ffffff;
  margin-top: 8px;
}
.new-dashboard .body .dashboard-hero .products .talent-product .title .text .company-name {
  font-size: 14px;
  font-weight: 400;
}
.new-dashboard .body .dashboard-hero .products .talent-product .title .text .product-name {
  font-size: 28px;
  font-weight: 500;
}
.new-dashboard .body .dashboard-hero .products .talent-product .arrow-icon {
  position: absolute;
  opacity: 0;
  right: 18px;
  top: 16px;
  width: 33px;
  height: 33px;
  transition: all;
  transform: rotateZ(90deg);
  transition-duration: 300ms;
  color: #ffffff;
}
.new-dashboard .body .dashboard-hero .products .talent-product:hover .arrow-icon {
  opacity: 1;
}
.new-dashboard .body .dashboard-hero .products .talent-product .profile-images {
  display: flex;
  justify-content: center;
  align-items: end;
  z-index: 0;
}
.new-dashboard .body .dashboard-hero .products .talent-product .profile-images > img {
  transition: all;
  transition-duration: 300ms;
}
.new-dashboard .body .dashboard-hero .products .talent-product .img-left {
  opacity: 0.4;
  z-index: 1;
}
.new-dashboard .body .dashboard-hero .products .talent-product .img-mid {
  opacity: 0.7;
  z-index: 2;
}
.new-dashboard .body .dashboard-hero .products .talent-product .img-right {
  opacity: 0.4;
  z-index: 1;
}
.new-dashboard .body .dashboard-hero .products .talent-product:hover {
  cursor: pointer;
}
.new-dashboard .body .dashboard-hero .products .talent-product:hover .img-left {
  opacity: 1;
  scale: 1.2;
  transform: rotateZ(-10deg);
}
.new-dashboard .body .dashboard-hero .products .talent-product:hover .img-mid {
  opacity: 1;
  scale: 1.2;
}
.new-dashboard .body .dashboard-hero .products .talent-product:hover .img-right {
  opacity: 1;
  scale: 1.2;
  transform: rotateZ(10deg);
}
.new-dashboard .body .data {
  margin: 24px 0px;
  display: flex;
  gap: 24px;
}
.new-dashboard .body .data > div {
  outline: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 14px;
  padding: 23px 20px;
}
.new-dashboard .body .data .users {
  width: 45%;
  height: 540.8px;
  position: relative;
}
.new-dashboard .body .data .users h2 {
  font-size: 24px;
  font-weight: 600;
  display: inline;
  margin-top: -20px;
}
.new-dashboard .body .data .users .tabs-list {
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 4px;
}
.new-dashboard .body .data .users .tabs-list .tabs-trigger {
  padding: 13px 20px;
  background: #ffffff;
  color: #212121;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-top-right-radius: 11px;
  border-top-left-radius: 11px;
}
.new-dashboard .body .data .users .tabs-list .tabs-trigger:hover {
  cursor: pointer;
  background: rgba(0, 52, 187, 0.05);
}
.new-dashboard .body .data .users .tabs-list .tabs-trigger[data-state=active] {
  color: #0034bb;
  border-bottom-color: #ffffff;
}
.new-dashboard .body .data .users .tabs-body {
  border-style: solid;
  border-color: rgba(33, 33, 33, 0.1);
  border-width: 1px 0 0 0;
  margin-top: -1px;
  height: calc(100% - 49px);
}
.new-dashboard .body .data .users .tabs-body .tabs-body-container {
  margin-top: 16px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.new-dashboard .body .data .users .tabs-body .tabs-body-container .tabs-content {
  height: 100%;
}
.new-dashboard .body .data .users .tabs-body .add-more-btn {
  background: #0034bb;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border: 0px;
  padding: 10px 14px;
  border-radius: 9px;
  width: 46px;
  height: 38px;
  display: flex;
  justify-content: left;
  align-items: center;
  position: absolute;
  bottom: 30px;
  right: 30px;
  transition: width 300ms ease, padding 300ms ease;
  overflow: hidden;
}
.new-dashboard .body .data .users .tabs-body .add-more-btn > span {
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 300ms, opacity 300ms ease;
}
.new-dashboard .body .data .users .tabs-body .add-more-btn:hover {
  width: 123.7px;
  cursor: pointer;
  padding-right: 20px;
}
.new-dashboard .body .data .users .tabs-body .add-more-btn:hover > span {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 300ms ease;
}
.new-dashboard .body .data .users .tabs-body .users-container {
  font-size: 14px;
}
.new-dashboard .body .data .users .tabs-body .users-container .user-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin: 20px 0px;
}
.new-dashboard .body .data .users .tabs-body .users-container .user-row .user-icon-container {
  width: 40px;
  height: 40px;
  background: rgb(242, 244, 248);
  border-radius: 100px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.new-dashboard .body .data .users .tabs-body .users-container .user-row .user-icon-container > svg {
  width: 24px;
  height: 24px;
}
.new-dashboard .body .data .users .tabs-body .users-container .user-row .user-col:nth-child(1) {
  display: flex;
  gap: 8px;
}
.new-dashboard .body .data .users .tabs-body .users-container .user-row .user-col:nth-child(2) {
  display: flex;
  justify-content: center;
}
.new-dashboard .body .data .users .tabs-body .users-container .user-row .user-col:nth-child(2) > span {
  width: 80px;
  text-align: left;
}
.new-dashboard .body .data .users .tabs-body .users-container .user-row .user-col:nth-child(3) {
  display: flex;
  justify-content: center;
}
.new-dashboard .body .data .users .tabs-body .users-container .user-row .user-col:nth-child(3) > span {
  text-align: right;
}
.new-dashboard .body .data .users .tabs-body .users-container .name {
  font-weight: 500;
  color: #212121;
  text-decoration: none;
}
.new-dashboard .body .data .users .tabs-body .users-container .name:hover {
  cursor: pointer;
}
.new-dashboard .body .data .users .tabs-body .users-container .designation {
  font-weight: 400;
  font-size: 12px;
}
.new-dashboard .body .data .users .tabs-body .users-container td {
  padding: 8px 0px;
}
.new-dashboard .body .data .users .tabs-body .outline-btn {
  padding: 9px 19px;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  background: #ffffff;
  font-size: 14px;
  font-weight: 500;
}
.new-dashboard .body .data .credits {
  width: 55%;
}
.new-dashboard .body .data .credits a {
  color: #0034bb;
  font-weight: 400;
}
.new-dashboard .body .data .credits > h2 {
  font-size: 24px;
  font-weight: 600;
}
.new-dashboard .body .data .credits .divider {
  width: 100%;
  background: rgba(33, 33, 33, 0.1);
  height: 1px;
  margin: 21px 0px;
  margin-top: 12px;
}
.new-dashboard .body .data .credits .credits-info {
  width: 100%;
  border-radius: 13px;
  background: rgba(0, 52, 187, 0.05);
  padding: 22px 50px;
  display: flex;
  justify-content: space-between;
}
.new-dashboard .body .data .credits .credits-info .icon {
  height: 18px;
  width: 18px;
  color: #0034bb;
}
.new-dashboard .body .data .credits .credits-info .available-number {
  font-size: 44px;
  color: #000000;
}
.new-dashboard .body .data .credits .credits-info .available-number > span {
  font-size: 22px;
  color: rgba(33, 33, 33, 0.6);
}
.new-dashboard .body .data .credits .credits-info .data-title {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
}
.new-dashboard .body .data .credits .credits-info .plan-credit {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.new-dashboard .body .data .credits .credits-info .plan-credit > div {
  margin-bottom: 4px;
}
.new-dashboard .body .data .credits .recent-activity {
  margin: 20px 0px;
}
.new-dashboard .body .data .credits .recent-activity h3 {
  font-size: 20px;
  font-weight: 600;
}
.new-dashboard .body .data .credits .recent-activity .show-more {
  color: #0034bb;
  text-decoration: underline;
}
.new-dashboard .body .data .credits .recent-activity .show-more:hover {
  cursor: pointer;
}
.new-dashboard .body .data .credits .list-body {
  margin-top: 23px;
}
.new-dashboard .body .data .credits .list-body .card {
  margin-bottom: 12px;
  padding-bottom: 12px;
  background-image: repeating-linear-gradient(to right, rgba(33, 33, 33, 0.15), rgba(33, 33, 33, 0.15) 5px, transparent 20px, transparent 8px, rgba(33, 33, 33, 0.15) 30px);
  background-size: 100% 1.4px;
  background-position: 0 100%;
  background-repeat: repeat-x;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.new-dashboard .body .data .credits .list-body .card .container {
  display: flex;
  align-items: center;
  column-gap: 21px;
}
.new-dashboard .body .data .credits .list-body .card .container .activity-data .row {
  display: flex;
  column-gap: 18px;
}
.new-dashboard .body .data .credits .list-body .card .container .activity-data .row .heading {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
}
.new-dashboard .body .data .credits .list-body .card .container .activity-data .row .time {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.new-dashboard .body .data .credits .list-body .card .container .activity-data .sub {
  font-size: 14px;
  color: #212121;
}
.new-dashboard .body .data .credits .list-body .card .activity-chip {
  font-size: 11px;
  font-style: italic;
  font-weight: 500;
  color: #212121;
  background: #f5f7f9;
}
.new-dashboard .body .data .credits .list-body .card:last-child {
  border: none;
}

.credits .header-breadcrumbs {
  margin-top: 40px;
  margin-bottom: 25px;
}
.credits .header-breadcrumbs .top {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.credits .header-breadcrumbs .top .icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.credits .header-breadcrumbs .top p {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.credits .header-breadcrumbs .top p:last-child {
  color: #212121;
}
.credits .header-breadcrumbs .top .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.credits .page-heading {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin-top: 17px;
  margin-bottom: 20px;
}
.credits .plan-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.credits .plan-head .plan-details h4 {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
}
.credits .plan-head .plan-details p {
  color: #212121;
}
.credits .plan-head .btn-group .outline-btn {
  padding: 9px 19px;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  font-size: 14px;
  font-weight: 500;
  margin-right: 14px;
}
.credits .plan-head .btn-group .fill-btn {
  padding: 9px 19px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
}
.credits .plan-body {
  padding: 32px 45px;
  border-radius: 13px;
  background: rgba(0, 52, 187, 0.05);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 10px;
}
.credits .plan-body .available .row,
.credits .plan-body .credits .row,
.credits .plan-body .pay .row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.credits .plan-body .available .heading,
.credits .plan-body .credits .heading,
.credits .plan-body .pay .heading {
  font-weight: 600;
  color: #212121;
  display: flex;
  align-items: center;
  gap: 4px;
}
.credits .plan-body .available .heading .head-icon,
.credits .plan-body .credits .heading .head-icon,
.credits .plan-body .pay .heading .head-icon {
  height: 18px;
  width: 18px;
  color: #0034bb;
}
.credits .plan-body .available {
  flex: 1;
}
.credits .plan-body .available h2 {
  font-size: 44px;
  font-weight: 400;
  color: #212121;
}
.credits .plan-body .available h2 span {
  font-size: 22px;
  color: rgba(33, 33, 33, 0.6);
}
.credits .plan-body .credits {
  flex: 1;
}
.credits .plan-body .credits .sub {
  font-size: 14px;
  color: #212121;
  margin-top: 4px;
}
.credits .plan-body .credits .features-btn {
  text-decoration: underline;
}
.credits .plan-body .pay {
  flex: 1.2;
}
.credits .plan-body .pay .sub {
  font-size: 14px;
  color: #212121;
  margin-top: 4px;
}
.credits .plan-body .pay .sub span {
  font-weight: 600;
}
.credits .body {
  display: flex;
  column-gap: 38px;
  margin-top: 31px;
}
.credits .body .activities {
  flex-grow: 1;
}
.credits .body .activities .list-head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.credits .body .activities .list-head .text h4 {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
}
.credits .body .activities .list-head .text p {
  color: #212121;
}
.credits .body .activities .list-head .outline-btn {
  padding: 9px 19px;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  font-size: 14px;
  font-weight: 500;
}
.credits .body .activities .list-body {
  margin-top: 23px;
}
.credits .body .activities .list-body .card {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px dashed rgba(33, 33, 33, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.credits .body .activities .list-body .card .container {
  display: flex;
  align-items: center;
  column-gap: 21px;
}
.credits .body .activities .list-body .card .container .activity-data .row {
  display: flex;
  column-gap: 18px;
}
.credits .body .activities .list-body .card .container .activity-data .row .heading {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
}
.credits .body .activities .list-body .card .container .activity-data .row .time {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.credits .body .activities .list-body .card .container .activity-data .sub {
  font-size: 14px;
  color: #212121;
  margin-top: 6px;
}
.credits .body .activities .list-body .card .activity-chip {
  font-size: 11px;
  font-style: italic;
  font-weight: 500;
  color: #212121;
  background: rgba(33, 33, 33, 0.05);
}
.credits .body .activities .activity-fallback {
  text-align: center;
  margin: 30px 0;
}
.credits .body .activities .activity-fallback img {
  max-width: 320px;
}
.credits .body .activities .activity-fallback p {
  margin-top: 10px;
  font-weight: 500;
}
.credits .body .activities .btn-wrapper {
  text-align: center;
  margin-bottom: 20px;
}
.credits .body .activities .btn-wrapper .outline-btn {
  padding: 9px 19px;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  font-size: 14px;
  font-weight: 500;
}
.credits .body .credits-usage {
  max-width: 265px;
  position: sticky;
  top: 40px;
}
.credits .body .credits-usage .credit-head {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 15px;
}
.credits .body .credits-usage .credit-head h4 {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
}
.credits .body .credits-usage .credit-head .divider {
  width: 1px;
  height: 25px;
  background-color: rgba(33, 33, 33, 0.2);
}
.credits .body .credits-usage .credit-cards {
  border-radius: 13px;
  border: 1px solid rgba(33, 33, 33, 0.15);
  padding: 21px 20px;
}
.credits .body .credits-usage .credit-cards .usage-card {
  margin-bottom: 21px;
}
.credits .body .credits-usage .credit-cards .usage-card:last-child {
  margin-bottom: 0;
}
.credits .body .credits-usage .credit-cards .usage-card .top {
  border-radius: 11px 11px 0 0;
  background: rgba(0, 52, 187, 0.1);
  padding: 18px;
}
.credits .body .credits-usage .credit-cards .usage-card .top .num {
  font-size: 14px;
  color: #212121;
  margin-left: 12px;
  position: relative;
  margin-top: 10px;
}
.credits .body .credits-usage .credit-cards .usage-card .top .num::before {
  content: "";
  position: absolute;
  left: -12px;
  width: 2px;
  height: 18px;
  background: #0034bb;
  border-radius: 16px;
}
.credits .body .credits-usage .credit-cards .usage-card .bottom {
  border-radius: 0 0 11px 11px;
  background: #f0f3f9;
  padding: 13px 18px;
}
.credits .body .credits-usage .credit-cards .usage-card .bottom h3 {
  font-size: 32px;
  font-weight: normal;
  color: #212121;
}
.credits .body .credits-usage .credit-cards .usage-card .bottom h3 span {
  font-size: 12px;
  color: rgba(33, 33, 33, 0.6);
}
.credits .body .functionalities {
  flex: 0.6;
}
.credits .body .functionalities .ai_functionality {
  padding: 23px 19px;
  border-radius: 13px;
  border: 1px solid rgba(33, 33, 33, 0.15);
}
.credits .body .functionalities .ai_functionality .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.credits .body .functionalities .ai_functionality .row .name {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
}
.credits .body .functionalities .ai_functionality .desc {
  margin-top: 3px;
  font-size: 14px;
  line-height: 140%;
  color: #212121;
}
.credits .body .plan {
  flex: 0.4;
}
.credits .body .plan .plan-heading {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
}
.credits .body .plan .plan-text {
  font-size: 14px;
  color: #212121;
}
.credits .body .plan .details {
  border-radius: 13px;
  background: rgba(0, 52, 187, 0.05);
  padding: 27px 28px;
  margin-top: 5px;
}
.credits .body .plan .details .row {
  display: flex;
  align-items: flex-start;
  column-gap: 4px;
  margin-bottom: 34px;
}
.credits .body .plan .details .row:last-child {
  margin-bottom: 12px;
}
.credits .body .plan .details .row .heading {
  flex: 0.45;
  font-weight: 600;
  color: #212121;
}
.credits .body .plan .details .row .data {
  flex: 0.55;
}
.credits .body .plan .details .row .data .credits-available {
  font-size: 34px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 0;
}
.credits .body .plan .details .row .data .credits-available .total {
  font-size: 17px;
  color: #212121;
}
.credits .body .plan .details .row .data .credits-available .text {
  font-size: 12px;
  color: rgba(33, 33, 33, 0.6);
}
.credits .body .plan .details .row .data .desc {
  font-size: 14px;
  color: #212121;
}
.credits .body .plan .details .row .data .sub-desc {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.credits .fallback {
  margin-top: 20px;
  position: relative;
}
.credits .fallback .background {
  position: absolute;
  opacity: 0.4;
  z-index: 0;
}
.credits .fallback .top {
  position: absolute;
  z-index: 1;
  left: 37%;
  right: 50%;
  margin-top: 8%;
}
.credits .fallback .para {
  position: absolute;
  z-index: 1;
  left: 30%;
  right: 35%;
  margin-top: 28%;
  font-size: 16px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  text-align: center;
}
.credits .fallback .fallback-button {
  position: absolute;
  z-index: 1;
  left: 39%;
  right: 35%;
  margin-top: 34%;
  border-radius: 9px;
  font-size: 14px;
  font-weight: 500;
  width: 180px;
  padding: 9px 24px;
}

.credit-drawer .MuiPaper-root {
  border-radius: 0;
  padding: 25px 27px 40px 40px;
  width: 760px;
  background-color: #fff;
  box-shadow: -7px 7px 40px 0px rgba(33, 33, 33, 0.2);
  border: none;
}
.credit-drawer .MuiPaper-root .container .credit-drawer-heading {
  text-align: right;
  margin-bottom: 20px;
}
.credit-drawer .MuiPaper-root .container .credit-drawer-heading button .icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  width: 24px;
  height: 24px;
}
.credit-drawer .MuiPaper-root .container .header {
  color: #212121;
}
.credit-drawer .MuiPaper-root .container .header h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
}
.credit-drawer .MuiPaper-root .container .header p {
  font-size: 18px;
  font-weight: 400;
}
.credit-drawer .MuiPaper-root .container .quantity {
  margin-top: 21px;
  color: #212121;
  padding-right: 61px;
}
.credit-drawer .MuiPaper-root .container .quantity h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.credit-drawer .MuiPaper-root .container .quantity .row {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.credit-drawer .MuiPaper-root .container .quantity .row .field {
  width: 50%;
}
.credit-drawer .MuiPaper-root .container .quantity .row p {
  font-size: 14px;
  font-weight: 400;
}
.credit-drawer .MuiPaper-root .container .description {
  margin-top: 33px;
  font-size: 12px;
  font-weight: 400;
}
.credit-drawer .MuiPaper-root .container .description a {
  color: #0034bb;
  text-decoration: underline;
  cursor: pointer;
}
.credit-drawer .MuiPaper-root .btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.credit-drawer .MuiPaper-root .btn-group button {
  min-width: 110px;
  border-radius: 9px;
  padding: 5px 19px;
  font-size: 18px;
  min-height: 46px;
}

.features-drawer .MuiPaper-root {
  border-radius: 0;
  padding: 25px 27px 40px 40px;
  width: 760px;
  background-color: #fff;
  box-shadow: -7px 7px 40px 0px rgba(33, 33, 33, 0.2);
  border: none;
}
.features-drawer .MuiPaper-root .container .features-drawer-heading {
  text-align: right;
  margin-bottom: 20px;
}
.features-drawer .MuiPaper-root .container .features-drawer-heading button .icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  width: 24px;
  height: 24px;
}
.features-drawer .MuiPaper-root .container .header {
  color: #212121;
}
.features-drawer .MuiPaper-root .container .header h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
}
.features-drawer .MuiPaper-root .container .functionalities {
  margin-top: 30px;
}
.features-drawer .MuiPaper-root .container .functionalities .ai_functionality {
  padding: 23px 19px;
  border-radius: 13px;
  border: 1px solid rgba(33, 33, 33, 0.15);
}
.features-drawer .MuiPaper-root .container .functionalities .ai_functionality .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.features-drawer .MuiPaper-root .container .functionalities .ai_functionality .row .name {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
}
.features-drawer .MuiPaper-root .container .functionalities .ai_functionality .desc {
  margin-top: 3px;
  font-size: 14px;
  line-height: 140%;
  color: #212121;
}

.date-popover .MuiPaper-root {
  padding: 13px 18px 26px 18px;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  box-shadow: 4px 4px 4px 3px rgba(0, 0, 0, 0.02);
}
.date-popover .MuiPaper-root .time-row {
  display: flex;
}
.date-popover .MuiPaper-root .time-row > p {
  flex: 1;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.activities {
  padding: 0px 5px 0px 5px;
  position: relative;
  height: 100%;
}
.activities .activities-navigation {
  display: flex;
  align-items: center;
  gap: 15px;
}
.activities .activities-navigation .icons {
  color: rgba(33, 33, 33, 0.6);
  width: 19px;
  height: 19px;
}
.activities .activities-navigation .dots {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: rgba(33, 33, 33, 0.6);
}
.activities .activities-navigation p {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
}
.activities .title {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin-top: 20px;
}
.activities .para {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
}
.activities .filter-chip {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.activities .filter-chip .chip {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 80%;
  flex-wrap: wrap;
}
.activities .filter-chip .chip .p {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  border-radius: 16px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 6px 12px;
  cursor: pointer;
}
.activities .filter-chip .chip .active {
  border-radius: 16px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #0034bb;
  color: #fff;
}
.activities .filter-chip Button {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 16px;
  font-weight: 500;
  padding: 4px 20px;
  color: rgb(33, 33, 33);
}
.activities .filter-chip Button span {
  text-transform: capitalize;
}
.activities .activity-grid {
  margin-top: 30px;
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  background: #fff;
  padding: 20px 25px;
}
.activities .activity-grid .grid-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
  height: 60px;
}
.activities .activity-grid .grid-top p {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  color: rgba(33, 33, 33, 0.6);
}
.activities .activity-grid .grid-body {
  padding-left: 20px;
  padding-right: 30px;
}
.activities .activity-grid .grid-body .fallback-message {
  font-size: 16px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.5);
  text-align: center;
}
.activities .activity-grid .grid-body .activity .card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  margin-left: 50px;
  border-right: none;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 33%, rgba(255, 255, 255, 0) 0%);
  background-position: left;
  background-size: 1.4px 10px;
  background-repeat: repeat-y;
  padding-bottom: 35px;
  padding-top: 0px;
  margin-bottom: -4px;
  padding-right: 20px;
}
.activities .activity-grid .grid-body .activity .card .card-left {
  display: flex;
  align-items: center;
  gap: 55px;
}
.activities .activity-grid .grid-body .activity .card .card-left .time-icon {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-left: -30px;
  justify-content: center;
  width: 90px;
}
.activities .activity-grid .grid-body .activity .card .card-left .time-icon .time {
  font-size: 16px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.5);
}
.activities .activity-grid .grid-body .activity .card .card-left .time-icon .active {
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
}
.activities .activity-grid .grid-body .activity .card .card-left .time-icon .svg {
  margin-left: 24.3px;
}
.activities .activity-grid .grid-body .activity .card .card-left .icon-name {
  display: flex;
  align-items: center;
  gap: 10px;
}
.activities .activity-grid .grid-body .activity .card .card-left .icon-name .avatar {
  width: 32px;
  height: 32px;
}
.activities .activity-grid .grid-body .activity .card .card-left .icon-name .name h2 {
  font-size: 14px;
  font-weight: 700;
  color: rgb(33, 33, 33);
}
.activities .activity-grid .grid-body .activity .card .card-left .icon-name .name p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.activities .activity-grid .grid-body .activity .card .type {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  padding: 7px 16px;
  border-radius: 16px;
  background: #f5f7f9;
}
.activities .activity-grid .grid-body .activity:last-child .card {
  border-right: none;
  background-image: unset;
  background-position: unset;
  background-size: unset;
  background-repeat: unset;
}
.activities .activity-grid .pagination {
  text-align: center;
}
.activities .activity-grid .pagination Button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 52, 187, 0.2);
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
  padding: 7px 30px;
  width: 170px;
  margin: auto;
  cursor: pointer;
}

.help-and-support {
  position: relative;
  overflow: visible;
}
.help-and-support .banner {
  padding: 30px 35px;
  position: relative;
  z-index: 0;
  height: 190px;
  overflow: hidden;
}
.help-and-support .banner .bg_circle-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  position: absolute;
  z-index: -1;
  opacity: 0.2;
  -moz-opacity: 0.07;
  right: 7.75rem;
  left: 7.75rem;
}
.help-and-support .banner .bg_circle-wrapper > .circle {
  width: 320px;
  height: 325px;
  border-radius: 325px;
  filter: blur(200px);
  flex: 1 1 30%;
  overflow: visible;
}
.help-and-support .banner .bg_circle-wrapper .circle-one {
  background: #0034bb;
}
.help-and-support .banner .bg_circle-wrapper .circle-two {
  background: #0034bb;
}
.help-and-support .banner .bg_circle-wrapper .circle-three {
  background: #027A48;
}
.help-and-support .banner .bg_circle-wrapper .circle-four {
  background: #2bba1d;
}
.help-and-support .banner .bg_circle-wrapper .circle-five {
  background: #ba0000;
}
.help-and-support .banner .bg_circle-wrapper .circle-six {
  background: #fe9800;
}
.help-and-support .banner .banner-container h1 {
  font-size: 28px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  margin-bottom: 3px;
}
.help-and-support .banner .banner-container p {
  font-size: 18px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.7);
  margin-bottom: 14px;
}
.help-and-support .banner .banner-container .search {
  border-radius: 7px;
  border: 1.5px solid rgba(33, 33, 33, 0.1);
  background: #fff;
  width: 50%;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 12px 18px;
}
.help-and-support .banner .banner-container .search:hover {
  border: 1.5px solid #0034bb;
}
.help-and-support .banner .banner-container .search input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 400;
}
.help-and-support .help-body {
  padding-left: 35px;
  padding-top: 20px;
  display: flex;
  gap: 20px;
  padding-right: 35px;
  padding-bottom: 50px;
}
.help-and-support .help-body .help-body-left {
  width: 68%;
}
.help-and-support .help-body .help-body-left .topic {
  font-size: 18px;
  font-weight: 600;
  color: rgb(33, 33, 33);
}
.help-and-support .help-body .help-body-left .cards {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}
.help-and-support .help-body .help-body-left .cards .card {
  border-radius: 5.889px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  background: #fff;
  width: 31.5%;
  padding: 14px;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.help-and-support .help-body .help-body-left .cards .card:hover {
  border: 1px solid #0034bb;
  box-shadow: 0px 3px 5.4px 0px rgba(33, 33, 33, 0.2);
}
.help-and-support .help-body .help-body-left .cards .card:hover .image img {
  transform: scale(1.12);
  transition: transform 0.3s ease;
}
.help-and-support .help-body .help-body-left .cards .card h3 {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
}
.help-and-support .help-body .help-body-left .cards .card p {
  font-size: 12px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  margin-top: 5px;
}
.help-and-support .help-body .help-body-left .cards .card .image {
  width: 100%;
  height: 170px;
  margin-top: 12px;
  border-radius: 2.944px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  background: rgba(33, 33, 33, 0.03);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.help-and-support .help-body .help-body-right {
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  background: #fff;
  width: 32%;
}
.help-and-support .help-body .help-body-right .right-card {
  padding: 25px 30px;
}
.help-and-support .help-body .help-body-right .right-card .right-card-top {
  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
  padding-bottom: 35px;
}
.help-and-support .help-body .help-body-right .right-card .right-card-top h2 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 18px;
}
.help-and-support .help-body .help-body-right .right-card .right-card-top p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.help-and-support .help-body .help-body-right .right-card .right-card-top button {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  width: 100%;
  margin-top: 20px;
  padding: 8px 20px;
}
.help-and-support .help-body .help-body-right .right-card .activity {
  margin-top: 25px;
  min-height: 250px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
}
.help-and-support .help-body .help-body-right .right-card .activity h2 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 12px;
}
.help-and-support .help-body .help-body-right .right-card .activity .back-support {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  font-weight: 600;
  margin-top: -25px;
  margin-bottom: 20px;
  cursor: pointer;
}
.help-and-support .help-body .help-body-right .right-card .activity .card {
  border-radius: 5.665px;
  border: 0.566px solid rgba(33, 33, 33, 0.2);
  padding: 13px 18px;
  margin-bottom: 20px;
}
.help-and-support .help-body .help-body-right .right-card .activity .card h3 {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.help-and-support .help-body .help-body-right .right-card .activity .card p {
  font-size: 12px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.help-and-support .help-body .help-body-right .right-card .activity .card .card-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}
.help-and-support .help-body .help-body-right .right-card .activity .card .card-bottom p {
  color: rgb(33, 33, 33);
}
.help-and-support .help-body .help-body-right .right-card .activity .card .card-bottom .active {
  color: rgb(2, 122, 72);
  background-color: rgba(2, 122, 72, 0.1);
  padding: 3px 8px;
  border-radius: 4px;
}
.help-and-support .help-body .help-body-right .right-card .activity-border {
  border-bottom: none;
}
.help-and-support .help-body .help-body-right .right-card .view-all {
  margin-top: 15px;
  border-radius: 6px;
  position: relative;
  padding: 5px 20px;
}
.help-and-support .help-body .help-body-right .right-card .view-all p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.help-and-support .help-body .help-body-right .right-card .view-all .icon {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateX(0);
}
.help-and-support .help-body .help-body-right .right-card .view-all:hover .icon {
  display: block;
  opacity: 1;
  transform: translateX(5px);
  animation: bounce 0.5s ease forwards;
}
.help-and-support .help-page {
  padding-left: 35px;
  padding-top: 20px;
  gap: 30px;
  padding-right: 35px;
  padding-bottom: 50px;
}
.help-and-support .help-page .help-page-title {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin-top: 20px;
  margin-bottom: 15px;
}
.help-and-support .help-page .back {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  border-radius: 8px;
}
.help-and-support .help-page .wrapper {
  display: flex;
}
.help-and-support .help-page .wrapper .help-page-left {
  width: 68%;
}
.help-and-support .help-page .wrapper .help-page-left .card-body {
  width: 96%;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .video {
  width: 100%;
  height: 400px;
  border-radius: 6px;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .video .frame {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.help-and-support .help-page .wrapper .help-page-left .card-body h2 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin: 15px 0px;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .my-product {
  text-align: center;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .my-product h2 {
  text-align: left;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .my-product p {
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.8);
  margin-top: 8px;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .my-product button {
  border-radius: 7px;
  border: 1px solid #0034bb;
  background: #0034bb;
  color: #fff;
  padding: 7px 30px;
  font-size: 18px;
  font-weight: 500;
  margin-top: 12px;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .my-product .product-card {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .my-product .product-card .p-card {
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  width: 150px;
  padding: 15px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .my-product .product-card .p-card .p-top h1 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  text-align: left;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .my-product .product-card .p-card .p-top p {
  font-size: 18px;
  font-weight: 400;
  color: rgb(2, 122, 72);
  text-align: left;
  margin-top: 5px;
  text-transform: capitalize;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .my-product .product-card .p-card p {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 52, 187);
}
.help-and-support .help-page .wrapper .help-page-left .card-body .legal-card {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 37px;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .legal-card .card {
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: rgba(255, 255, 255, 0.05);
  height: 150px;
  width: 160px;
  padding: 20px 18px;
  cursor: pointer;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .legal-card .card p {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .faqs-card .Mui-expanded {
  border-bottom: none !important;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .faqs-card .MuiCollapse-root {
  border-radius: 8px;
  background: rgba(33, 33, 33, 0.05);
}
.help-and-support .help-page .wrapper .help-page-left .card-body .faqs-card .question {
  border: none;
  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
  padding: 8px 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.help-and-support .help-page .wrapper .help-page-left .card-body .faqs-card .question .ques {
  font-size: 18px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}
.help-and-support .help-page .wrapper .help-page-left .card-body .faqs-card .question .answer {
  font-size: 18px;
  font-weight: 400;
  color: rgba(60, 60, 67, 0.85);
  margin-bottom: 15px;
  margin: 18px 18px;
}
.help-and-support .help-page .wrapper .help-page-right {
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  background: #fff;
  width: 32%;
  height: fit-content;
}
.help-and-support .help-page .wrapper .help-page-right .right-card {
  padding: 25px 30px;
}
.help-and-support .help-page .wrapper .help-page-right .right-card .right-card-top {
  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
  padding-bottom: 35px;
}
.help-and-support .help-page .wrapper .help-page-right .right-card .right-card-top h2 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 18px;
}
.help-and-support .help-page .wrapper .help-page-right .right-card .right-card-top p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.help-and-support .help-page .wrapper .help-page-right .right-card .right-card-top button {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  width: 100%;
  margin-top: 20px;
  padding: 8px 20px;
}
.help-and-support .help-page .wrapper .help-page-right .right-card .activity {
  margin-top: 25px;
  min-height: 250px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
}
.help-and-support .help-page .wrapper .help-page-right .right-card .activity h2 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 12px;
}
.help-and-support .help-page .wrapper .help-page-right .right-card .activity .back-support {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  font-weight: 600;
  margin-top: -25px;
  margin-bottom: 20px;
  cursor: pointer;
}
.help-and-support .help-page .wrapper .help-page-right .right-card .activity .card {
  border-radius: 5.665px;
  border: 0.566px solid rgba(33, 33, 33, 0.2);
  padding: 13px 18px;
  margin-bottom: 20px;
}
.help-and-support .help-page .wrapper .help-page-right .right-card .activity .card h3 {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.help-and-support .help-page .wrapper .help-page-right .right-card .activity .card p {
  font-size: 12px;
  font-weight: 400;
  color: rgba(33, 33, 33, 0.6);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.help-and-support .help-page .wrapper .help-page-right .right-card .activity .card .card-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}
.help-and-support .help-page .wrapper .help-page-right .right-card .activity .card .card-bottom p {
  color: rgb(33, 33, 33);
}
.help-and-support .help-page .wrapper .help-page-right .right-card .activity .card .card-bottom .active {
  color: rgb(2, 122, 72);
  background-color: rgba(2, 122, 72, 0.1);
  padding: 3px 8px;
  border-radius: 4px;
}
.help-and-support .help-page .wrapper .help-page-right .right-card .activity-border {
  border-bottom: none;
}
.help-and-support .help-page .wrapper .help-page-right .right-card .view-all {
  margin-top: 15px;
  border-radius: 6px;
  position: relative;
  padding: 5px 20px;
}
.help-and-support .help-page .wrapper .help-page-right .right-card .view-all p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
}
.help-and-support .help-page .wrapper .help-page-right .right-card .view-all .icon {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateX(0);
}
.help-and-support .help-page .wrapper .help-page-right .right-card .view-all:hover .icon {
  display: block;
  opacity: 1;
  transform: translateX(5px);
  animation: bounce 0.5s ease forwards;
}

.support-page {
  gap: 0px !important;
}
.support-page .right {
  padding-right: 0px !important;
}

@keyframes bounce {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(12px);
  }
}
.open-attachment .MuiDrawer-paper {
  border-radius: 0px !important;
  max-width: 65% !important;
  min-width: 65% !important;
  justify-content: unset;
  padding-bottom: 0px !important;
  margin-bottom: 0px;
  border: none !important;
  padding: 20px 30px;
}
.open-attachment .top {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.open-attachment .title {
  font-size: 24px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  padding-left: 14px;
  margin-top: 15px;
  margin-bottom: 5px;
}
.open-attachment .sub-title {
  font-size: 18px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  padding-left: 14px;
  margin-bottom: 15px;
}
.open-attachment .drawer-body {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #fff;
  padding: 17px 28px;
  margin-left: 12px;
}
.open-attachment .drawer-body .row {
  display: flex;
  align-items: center;
  gap: 20px;
}
.open-attachment .drawer-body .row .left-side {
  width: 50%;
  height: 90px;
}
.open-attachment .drawer-body .row .left-side .mobile-error {
  font-size: 14px;
  font-weight: 500;
  color: #ba0000;
  padding-left: 12px;
}
.open-attachment .drawer-body .row .left-side .MuiFormControl-root .MuiSelect-standard {
  padding-right: 40px;
  background: transparent;
}
.open-attachment .drawer-body .row .left-side .MuiFormControl-root .MuiSvgIcon-root {
  right: 12px !important;
}
.open-attachment .drawer-body .row .left-side p {
  font-size: 18px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 5px;
}
.open-attachment .drawer-body .row .left-side .input {
  width: 100%;
}
.open-attachment .drawer-body .row .left-side .input .MuiInputBase-root {
  border-radius: 8px !important;
}
.open-attachment .drawer-body .row .left-side .small-list-item {
  width: 100%;
}
.open-attachment .drawer-body .row .left-side .mobile-input {
  height: auto;
  border-radius: 8px;
  border: 1.4px solid rgba(33, 33, 33, 0.2);
  padding-right: 2px;
}
.open-attachment .drawer-body .row .left-side .mobile-input:hover, .open-attachment .drawer-body .row .left-side .mobile-input:focus, .open-attachment .drawer-body .row .left-side .mobile-input:focus-within {
  border: 1.4px solid rgba(0, 52, 187, 0.7);
}
.open-attachment .drawer-body .row .left-side .mobile-input input {
  font-size: 18px;
  font-weight: 400;
  font-family: Poppins;
  color: #212121;
}
.open-attachment .drawer-body .row .left-side .mobile-input .form-control {
  width: 91%;
  border-radius: 8px;
  margin-left: 26px;
  border: unset;
  border-left: none;
  height: 38px;
}
.open-attachment .drawer-body .row .left-side .mobile-input .flag-dropdown {
  border-radius: 8px 0 0 8px;
  border-right: none;
  border: unset;
  background: rgba(255, 255, 255, 0.8);
}
.open-attachment .drawer-body .row .left-side .mobile-input .flag-dropdown .selected-flag {
  padding: 0 0 0 19px;
  background-color: unset;
}
.open-attachment .drawer-body .row .left-side .mobile-input .flag-dropdown .selected-flag .flag .arrow {
  border-top: unset;
  border-left: unset;
  border-right: unset;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 6L8 10L12 6" stroke="%23212121" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 18px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  top: unset;
  left: 22px;
}
.open-attachment .drawer-body .row-full {
  width: 100%;
}
.open-attachment .drawer-body .row-full p {
  font-size: 18px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 5px;
}
.open-attachment .drawer-body .row-full .input {
  width: 100%;
  margin-bottom: 20px;
}
.open-attachment .drawer-body .row-full .input .MuiInputBase-root {
  border-radius: 8px !important;
}
.open-attachment .row-button {
  text-align: right;
  margin-top: 15px;
}
.open-attachment .row-button .contained {
  background: #0034bb;
  color: #ffffff;
  box-shadow: none;
  min-width: 150px;
  padding: 9px 20px;
  border-radius: 8px;
}
.open-attachment .row-button .disable {
  opacity: 0.4;
}

.signup-flow .signup-card {
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 600px;
  margin: auto;
}
.signup-flow .signup-card .title {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
  text-align: center;
  margin-bottom: 15px;
  margin-top: -10px;
}
.signup-flow .signup-card .row {
  width: 450px;
  margin: auto;
  position: relative;
}
.signup-flow .signup-card .row .field {
  width: 100%;
}
.signup-flow .signup-card .row .field .error-state .MuiOutlinedInput-notchedOutline {
  border-color: red;
  border-width: 1.5px;
}
.signup-flow .signup-card .row .field .email-verify-button {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.signup-flow .signup-card .row .field .email-verify-button .button {
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  width: 140px;
  padding: 9px 20px !important;
}
.signup-flow .signup-card .row .field .email-verified {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 13px;
  font-weight: 400;
  color: #229651;
  margin-top: 3px;
  line-height: 24px;
  margin-bottom: 14px;
}
.signup-flow .signup-card .row .field .error-password {
  display: flex;
  align-items: center;
  gap: 2px;
  color: #ba0000;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 3px;
}
.signup-flow .signup-card .row .field .match-password {
  font-size: 14px;
  font-weight: 400;
  color: #229651;
  margin-top: 3px;
  line-height: 24px;
}
.signup-flow .signup-card .row .name-field {
  display: flex !important;
  align-items: center !important;
  gap: 20px;
}
.signup-flow .signup-card .row .name-field .left-row {
  width: 50%;
}
.signup-flow .signup-card .row .name-field .right-row {
  width: 50%;
}
.signup-flow .signup-card .row .ecn-select .MuiSelect-select {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #212121;
}
.signup-flow .signup-card .row .otp .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.signup-flow .signup-card .row .otp .top .icon-text {
  display: flex;
  align-items: center;
  gap: 5px;
}
.signup-flow .signup-card .row .otp .top .icon-text p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.signup-flow .signup-card .row .otp .top span {
  font-size: 14px;
  font-weight: 400;
  color: #0034BB;
}
.signup-flow .signup-card .row .otp .otp-input {
  display: flex;
  align-items: center;
}
.signup-flow .signup-card .row .otp .otp-input .otp-container {
  column-gap: 21.4px;
  margin-bottom: 8px;
}
.signup-flow .signup-card .row .otp .otp-input .otp-container .otp-input {
  height: 48px;
  width: 56.8px !important;
  border-radius: 4px;
  border: 1.5px solid rgba(33, 33, 33, 0.2);
  background: #ffffff;
  font-size: 24px;
}
.signup-flow .signup-card .row .otp .otp-input .otp-container .otp-input:focus, .signup-flow .signup-card .row .otp .otp-input .otp-container .otp-input:focus-within, .signup-flow .signup-card .row .otp .otp-input .otp-container .otp-input:focus-visible {
  border: 1.5px solid rgba(33, 33, 33, 0.2);
  outline: none;
}
.signup-flow .signup-card .row .icon {
  position: absolute;
  right: -45px;
  top: 40px;
  width: 26px;
  height: 26px;
}
.signup-flow .signup-card .ecn-button {
  width: 286px;
  padding: 10px 30px;
  border-radius: 100px;
  margin: auto;
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
}
.signup-flow .signup-card label {
  font-size: 14px;
  color: #212121;
  margin-bottom: 2px;
  line-height: 24px;
  font-weight: 400;
}
.signup-flow .signup-card .ecn-input {
  background: #ffffff;
  border-radius: 7px;
  height: 44px;
  margin-bottom: 10px;
}
.signup-flow .signup-card .already {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
  text-align: center;
  margin-top: 15px;
}
.signup-flow .signup-card .already span {
  font-size: 16px;
  font-weight: 700;
  color: #0034BB;
  cursor: pointer;
}
.signup-flow .signup-card .signup-with-google-and-microsoft {
  width: 450px;
  margin: auto;
  margin-top: 20px;
}
.signup-flow .signup-card .signup-with-google-and-microsoft .con {
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  margin-bottom: 20px;
}
.signup-flow .signup-card .signup-with-google-and-microsoft .con p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  background: #fff;
  margin: auto;
  margin-top: -11px;
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
}
.signup-flow .signup-card .signup-with-google-and-microsoft .google-micro-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 25px;
}
.signup-flow .signup-card .signup-with-google-and-microsoft .google-micro-button .google button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px 10px;
  min-width: 196px;
  background: transparent;
  border: none;
  outline: none;
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: rgb(33, 33, 33);
}
.signup-flow .signup-card .signup-with-google-and-microsoft .google-micro-button .google button:hover {
  background: rgba(0, 52, 187, 0.05);
}
.signup-flow .signup-card .switch {
  text-align: center;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  padding-top: 20px;
}
.signup-flow .signup-card .switch .not {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
}
.signup-flow .signup-card .switch .net {
  font-size: 16px;
  font-weight: 500;
  color: #0034BB;
}

.dialog-success .img {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-top: 20px;
}
.dialog-success .img img {
  width: 100%;
  height: 100%;
}
.dialog-success .text {
  text-align: center;
  width: 90%;
  margin: auto;
  padding-bottom: 50px;
  padding-top: 20px;
}
.dialog-success .text h4 {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 20px;
}
.dialog-success .text p {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
}
.dialog-success .text p span {
  font-weight: 600;
  color: #0034BB;
}

.license-card {
  margin-bottom: 40px;
}
.license-card .head {
  background: #0034bb;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 32px 32px 0 0;
  padding: 17px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.license-card .head .ai_img {
  max-width: 180px;
  height: auto;
}
.license-card .head .license_buy-btn {
  background: #ffffff;
  color: #212121;
  border-radius: 6px;
  box-shadow: none;
  padding: 8px 63px;
  font-weight: 500;
}
.license-card .body {
  border: 0.45px solid rgba(0, 0, 0, 0.2);
  border-radius: 0 0 10px 10px;
  padding: 33px 65px 40px 38px;
}
.license-card .body .wrapper {
  display: flex;
  gap: 100px;
}
.license-card .body .wrapper .left {
  flex: 0.45;
}
.license-card .body .wrapper .left h3 {
  font-size: 38px;
  font-weight: 700;
  color: #212121;
  margin-bottom: 7px;
}
.license-card .body .wrapper .left .original-price {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.4);
  text-decoration: line-through;
  margin-left: 6px;
  line-height: 1;
}
.license-card .body .wrapper .left h2 {
  font-size: 44px;
  font-weight: 700;
  color: #212121;
  line-height: 1;
}
.license-card .body .wrapper .left h2 span {
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.7);
}
.license-card .body .wrapper .left .desc {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  margin-top: 20px;
  width: 92%;
}
.license-card .body .wrapper .right {
  flex: 0.55;
}
.license-card .body .wrapper .right h5 {
  font-size: 20px;
  font-weight: 700;
  color: #212121;
  margin-bottom: 22px;
}
.license-card .body .wrapper .right .features-wrapper {
  display: flex;
  gap: 30px;
}
.license-card .body .wrapper .right .features-wrapper ul {
  list-style: none;
}
.license-card .body .wrapper .right .features-wrapper ul li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 11px;
  font-size: 14px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 25px;
}
.license-card .body .wrapper .right .features-wrapper ul li:last-child {
  margin-bottom: 0;
}
.license-card .body .wrapper .right .features-wrapper ul li .tick {
  color: #027A48;
  width: 22px;
  height: 22px;
  flex-shrink: 0;
}
.license-card .body .alert-msg {
  width: max-content;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 14px;
  background: rgba(0, 52, 187, 0.05);
  color: #0034bb;
  border-radius: 6px;
  margin-top: 25px;
}

.add-file {
  min-height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-file .wrapper .error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 124px;
  margin-bottom: 124px;
}
.add-file .wrapper .error img {
  width: 325px;
  height: 325px;
  border-radius: 167px;
  border: 8px solid rgba(33, 33, 33, 0.6);
  margin-bottom: 40px;
}
.add-file .wrapper .error h3 {
  color: #ba0000;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}
.add-file .wrapper .error .btn-oulined {
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  min-width: 190px;
  padding: 6px 19px;
  font-size: 18px;
  font-weight: 500;
}
.add-file .wrapper .progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add-file .wrapper .progress .image-loader {
  width: 325px;
  height: 325px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-file .wrapper .progress .image-loader img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 8px solid rgba(33, 33, 33, 0.6);
}
.add-file .wrapper .progress .image-loader .circular-progress {
  color: #0034bb;
  position: absolute;
}
.add-file .wrapper .progress h3 {
  color: #212121;
  font-size: 24px;
  font-weight: 600;
}
.add-file .wrapper .progress .in-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.add-file .wrapper .progress .in-progress p {
  color: #212121;
  font-size: 18px;
  font-weight: 400;
}
.add-file .wrapper .progress .in-progress .icon {
  width: 34px;
  height: 34px;
  color: #0034bb;
}
.add-file .wrapper .filename {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
.add-file .wrapper .filename .container {
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 40px 97px 41px 98px;
}
.add-file .wrapper .filename .container .cropper {
  position: relative;
  height: 310px;
  width: 310px;
  background: linear-gradient(to right, #a6a6a6 5px, transparent 5px) 0 0, linear-gradient(to right, #a6a6a6 5px, transparent 5px) 0 100%, linear-gradient(to left, #a6a6a6 5px, transparent 5px) 100% 0, linear-gradient(to left, #a6a6a6 5px, transparent 5px) 100% 100%, linear-gradient(to bottom, #a6a6a6 5px, transparent 5px) 0 0, linear-gradient(to bottom, #a6a6a6 5px, transparent 5px) 100% 0, linear-gradient(to top, #a6a6a6 5px, transparent 5px) 0 100%, linear-gradient(to top, #a6a6a6 5px, transparent 5px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 50px 50px;
}
.add-file .wrapper .filename .container .zoom-buttons {
  gap: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 43px;
}
.add-file .wrapper .filename .container .zoom-buttons .btn-text {
  color: #212121;
  font-size: 18px;
  font-weight: 500;
}
.add-file .wrapper .filename .container .zoom-buttons .btn-text .icon {
  width: 24px;
  height: 24px;
}
.add-file .wrapper .filename .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
  gap: 35px;
}
.add-file .wrapper .filename .buttons .btn-oulined {
  gap: 10px;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  min-width: 190px;
  padding: 5px 1px;
  font-size: 18px;
  font-weight: 500;
}
.add-file .wrapper .filename .buttons .btn-oulined .icon {
  width: 20px;
  height: 20px;
}
.add-file .wrapper .filename .buttons .btn-contained {
  border-radius: 9px;
  padding: 8px 19px;
  border: 1px solid #0034bb;
  background: #0034bb;
  color: #ffffff;
  min-width: 363px;
}
.add-file .wrapper .filename .buttons .save-changes {
  font-size: 18px;
  font-weight: 500;
  padding: 5px 1px;
  min-width: 190px;
  border-radius: 9px;
  background: #0034bb;
  border: 1px solid #0034bb;
  color: #ffffff;
}

.wrapper-upload {
  width: 100%;
  background-image: repeating-linear-gradient(4deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px), repeating-linear-gradient(94deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px), repeating-linear-gradient(184deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px), repeating-linear-gradient(274deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}
.wrapper-upload .centeral-part {
  text-align: center;
  margin: auto;
  padding-top: 43px;
  padding-bottom: 48px;
}
.wrapper-upload .centeral-part .button button {
  background: transparent;
  outline: none;
  padding: 8px 25px;
  color: rgba(33, 33, 33, 0.6);
  border-radius: 5px;
  font-weight: 600;
  border: none;
}
.wrapper-upload .centeral-part .button button .icon {
  min-height: 65px;
  min-width: 65px;
}
.wrapper-upload .centeral-part .text .sub-text {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  margin-top: 23px;
  margin-bottom: 14px;
}
.wrapper-upload .centeral-part .text .sub-text-2 {
  color: rgba(33, 33, 33, 0.4);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 13px;
}
.wrapper-upload .centeral-part .btn-outlined {
  gap: 10px;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 7px 1px;
  color: #212121;
  min-width: 246px;
  font-size: 18px;
  font-weight: 500;
}
.wrapper-upload .centeral-part .btn-outlined icon {
  color: #212121;
  min-width: 20px;
}
.wrapper-upload .centeral-part .btn-outlined:hover {
  background: rgba(0, 52, 187, 0.1);
}

@media only screen and (min-width: 1000px) and (max-width: 1330px) {
  .upload .MuiPaper-root .heading {
    padding-top: 27px;
    padding-bottom: 30px;
  }
  .upload .MuiPaper-root .container h1 {
    margin-bottom: 27px;
  }
  .upload .filename .container {
    padding: 33px 81px 34px 82px !important;
  }
  .upload .filename .container .zoom-buttons {
    margin-top: 36px !important;
  }
}
.mobile-access-screen {
  display: block;
  align-items: center;
  margin-inline: auto;
  min-height: 89vh;
  height: 100%;
  width: 400px;
  margin-top: 7rem;
}
.mobile-access-screen .bg_circle-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  position: absolute;
  z-index: -1;
  opacity: 0.2;
  -moz-opacity: 0.07;
  right: 7.75rem;
  left: 7.75rem;
}
.mobile-access-screen .bg_circle-wrapper > .circle {
  width: 320px;
  height: 325px;
  border-radius: 325px;
  filter: blur(200px);
  flex: 1 1 30%;
  overflow: visible;
}
.mobile-access-screen .bg_circle-wrapper .circle-one {
  background: #0034bb;
}
.mobile-access-screen .bg_circle-wrapper .circle-two {
  background: #0034bb;
}
.mobile-access-screen .bg_circle-wrapper .circle-three {
  background: #027A48;
}
.mobile-access-screen .bg_circle-wrapper .circle-four {
  background: #2bba1d;
}
.mobile-access-screen .bg_circle-wrapper .circle-five {
  background: #ba0000;
}
.mobile-access-screen .bg_circle-wrapper .circle-six {
  background: #fe9800;
}
.mobile-access-screen .mobile-body {
  padding: 20px;
}
.mobile-access-screen .mobile-body .image {
  width: 80%;
  height: auto;
  margin: auto;
}
.mobile-access-screen .mobile-body .image img {
  max-width: 100%;
}
.mobile-access-screen .mobile-body h1 {
  font-size: 26px;
  font-weight: 600;
  color: rgb(33, 33, 33);
  text-align: center;
  line-height: 30px;
  margin: 10px 0px;
}
.mobile-access-screen .mobile-body .sub-title {
  font-size: 14px;
  font-weight: 400;
  color: rgb(33, 33, 33);
  text-align: center;
}
.mobile-access-screen .mobile-body .redirect {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  margin-top: 40px;
}
.mobile-access-screen .mobile-body .redirect span {
  font-weight: 600;
  color: rgb(33, 33, 33);
  text-decoration: underline;
}